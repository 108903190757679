<template>
  <div class="communities" v-scroll="onScroll">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar
      app
      :hide-on-scroll="hideOnScroll"
      class="router-contents-bar"
      elevation="1"
    >
      <template v-if="!searchBox">
        <v-avatar size="36" color="grey lighten-4" @click="openProfileDrawer">
          <v-img v-if="userData.icon_link || ''" :src="userData.icon_link || ''"></v-img>
          <v-icon v-else small>fa-user</v-icon>
        </v-avatar>
        <v-spacer></v-spacer>
        <v-toolbar-title>コミュニティ</v-toolbar-title>
        <v-spacer></v-spacer>
      </template>
      <v-btn
        icon
        :disabled="!Boolean(userData.id)"
        @click="searchBox = !searchBox"
      >
        <v-icon>{{ !searchBox ? 'fa-search' : 'fa-times' }}</v-icon>
      </v-btn>
      <v-text-field
        v-if="searchBox"
        v-model="searchWord"
        placeholder="検索"
        append-icon="fa-search"
        hide-details
        class="ml-2"
        @focus="focus = true"
        @blur="focus = false"
        @click:append="doSearch()"
        @keyup.ctrl.enter.exact="doSearch()"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs
          v-model="selectedTab"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :href="`#${tab.id}`"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item
        v-for="(tab, i) in tabs"
        :key="i"
        :value="tab.id"
      >
        <template v-if="selectedTab === tab.id">
          <VirtualScroll
            ref="virtualScroll"
            key-field="group_id"
            :items="results[selectedTab]"
            :min-item-height="92"
            :heights="heights[selectedTab]"
            :minScrollerHeight="minScrollerHeight"
            :buffer="minScrollerHeight*2"
            :offset="beforeScrollY[selectedTab]"
            :paddingBottom="$_domHeights.bottomNavigation"
            :onRefresh="onRefresh"
            :loadMore="loadMore"
            :noMoreItems="noMoreResults[selectedTab]"
          >
            <template v-slot:top>
              <v-card
                v-if="(results[selectedTab].length === 0 && noMoreResults[selectedTab]) || isMobile"
                max-width="750" flat class="mx-auto py-5"
              >
                <v-row
                  v-if="isMobile"
                  align="center" justify="center" class="wrapper ma-0"
                >
                  <v-col style="max-width: fit-content;">
                    <v-btn
                      text color="secondary" class="px-5"
                      @click="onRefresh"
                    >
                      更新
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  align="center" justify="center" class="wrapper ma-0"
                >
                  <v-col style="max-width: fit-content;">
                    下げて更新
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <template v-slot="{ item }">
              <v-card
                class="community-box mx-auto"
                max-width="750"
                flat
                @click="openCommunity(item.group_id)"
              >
                <v-row class="ma-0">
                  <v-spacer></v-spacer>
                  <v-col class="avatar-col pr-0 text-center col-2">
                    <v-avatar color="grey lighten-4">
                      <v-img v-if="item.icon_url" :src="item.icon_url" v-on:error="$_notImage(item, 'icon_url')"></v-img>
                      <v-icon v-else>fa-users</v-icon>
                      <v-icon v-if="item.is_admin" size="20" class="is-admin-icon">fa-cog</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="px-0 py-2 col-10">
                    <v-card-title class="py-0 pr-1">
                      <span class="subtitle-2 font-weight-bold ma-0">
                        <v-icon
                          v-if="item.kind >= 1"
                          x-small class="mr-1"
                          :color="item.kind === 1 ? '' : 'primary'"
                        >fa-lock</v-icon>{{ item.group }}
                      </span>
                    </v-card-title>
                    <v-card-text class="pb-0">{{ item.description }}</v-card-text>
                    <v-card-actions class="ml-4 pa-0">
                      <v-row class="ma-0">
                        <v-col v-if="item.tag.length" class="pa-0 col-12">
                          <v-chip
                            v-for="tag in item.tag"
                            small outlined class="mr-1 my-1"
                            @click.stop="doSearch(tag.tag)"
                          >
                            <span>{{ tag.tag }}</span>
                          </v-chip>
                        </v-col>
                        <v-col class="pa-0 col-12">
                          <v-chip small label outlined class="my-1">
                            <span class="caption px-1 grey--text">
                              <v-icon x-small class="mr-1">fa-user</v-icon>{{ changeNumFormat(item.member_sum) }}人
                            </span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card>
            </template>
            <template v-slot:bottom>
              <div style="padding-bottom: 88px;"></div>
            </template>
          </VirtualScroll>
        </template>
      </v-tab-item>
    </v-tabs-items>

    <v-btn
      fab fixed right dark color="primary"
      class="bottom-fab-button"
      :style="{ bottom: `${$_bottomFabSpace}px` }"
      @click="communityAddDialog = true"
    >
      <v-icon>fas fa-user-plus</v-icon>
    </v-btn>

    <BottomSheet
      :sheet="bottomSheet" :tiles="selectedSheetTiles" :ids="selectedIds"
      :action="userAction"
    ></BottomSheet>

    <v-dialog
      v-model="communityAddDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="communityAddDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :disabled="!communityName || !description || !kind || tags.length === 0"
            :loading="createLoading"
            @click="createCommunity"
          >
            作成
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="community-settings-box">
            <v-card flat>
              <div>
                <clipper-upload
                  ref="uploadTop"
                  v-model="selectedSettingsImages.top.originalBlob"
                  style="position: absolute;z-index: 1;width: 100%;height: 120px;"
                  accept="image/png, image/jpeg, image/gif, image/heic"
                ></clipper-upload>
                <div style="width: 100%;">
                  <v-img
                    :src="compressing.top ? '' : (selectedSettingsImages.top.blob || '')"
                    :aspect-ratio="2.5/1"
                    class="mx-auto grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-icon v-if="!compressing.top" large style="position: absolute; width: 100%;height: 100%;">fa-image</v-icon>
                    </template>
                  </v-img>
                </div>
              </div>
              <v-row class="avatar-row ma-0">
                <v-avatar size="80" color="grey lighten-4">
                  <clipper-upload
                    ref="uploadIcon"
                    v-model="selectedSettingsImages.icon.originalBlob"
                    style="position: absolute;z-index: 1;width: 100px;height: 100px;"
                    accept="image/png, image/jpeg, image/gif, image/heic"
                  ></clipper-upload>
                  <v-progress-circular
                    v-if="compressing.icon"
                    indeterminate
                    color="grey darken-1"
                    size="28" width="2"
                    style="position: absolute;"
                  ></v-progress-circular>
                  <v-img
                    v-else-if="selectedSettingsImages.icon.blob"
                    :src="selectedSettingsImages.icon.blob || ''"
                  ></v-img>
                  <v-icon v-else large style="position: absolute;">fa-image</v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
              </v-row>
              <v-card-text class="px-5 pb-0">
                <v-text-field
                  class="mb-2"
                  type="text"
                  prepend-icon="fa-users"
                  v-model="communityName"
                  :rules="[rules.max50]"
                  label="コミュニティ名"
                  clear-icon="fa-times"
                  clearable
                  required
                ></v-text-field>

                <v-textarea
                  v-model="description"
                  label="コミュニティ紹介"
                  :rules="[rules.max150]"
                  prepend-icon="fa-comment-dots"
                  auto-grow
                  counter
                ></v-textarea>

                <v-select
                  v-model="kind"
                  required
                  :items="kindList"
                  label="種類"
                  prepend-icon="fa-lock"
                  single-line
                ></v-select>

                <v-combobox
                  v-model="tags"
                  small-chips
                  label="タグ"
                  multiple
                  prepend-icon="fa-tags"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      :input-value="selected"
                      small close
                      @click:close="removeTag(item)"
                    >
                      <span>{{ item }}</span>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="clipImageDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="clipImageDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :loading="clipLoading"
            @click="clipImage(clipMode)"
          >
            切り取り
          </v-btn>
        </v-card-title>
        <v-card-text>
          <clipper-fixed
            v-if="clipMode === 'icon'"
            :src="selectedSettingsImages.icon.originalBlob"
            round ref="imageClipper"
            :area="70"
          >
            <div slot="placeholder">画像がありません</div>
          </clipper-fixed>
          <clipper-fixed
            v-if="clipMode === 'top'"
            :src="selectedSettingsImages.top.originalBlob"
            :ratio='2.5/1' ref="imageClipper"
            :area="70"
          >
            <div slot="placeholder">画像がありません</div>
          </clipper-fixed>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.communities {
  .v-tabs-items {
    .community-box {
      .v-ripple__container {
        display: none;
      }
      .is-admin-icon {
        position: absolute;
        margin-top: 20px;
        margin-left: 20px;
      }
      .v-chip {
        max-width: 50vw;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .community-box::before, .community-box::after {
      content: '';
      display: block;
      height: 1px;
      background: rgba(0, 0, 0, 0.12);
    }
  }
  .v-input:not(.v-textarea, .v-select) {
    height: 70px;
  }
}
.community-settings-box {
  .v-card {
    .avatar-row {
      min-height: 60px;

      .v-avatar {
        top: -30px;
        left: 12px;

        .v-image {
          border: solid 3px #fff;
        }
      }
    }
    .v-input:not(.v-textarea, .v-select) {
      height: 70px;
    }
    .v-chip {
      height: auto;

      .v-chip__content {
        padding: 2px 4px;

        span {
          overflow: hidden;
          white-space: pre-wrap;
        }
      }
    }
  }
}
</style>

<script>
import imageAxios from '../axios/image';
import axios from '../axios/index';
import router from '../router';

export default {
  name: 'communities',
  data: () => ({
    hideOnScroll: false,
    rules: {
      max50: (v) => !v || v.length <= 50 || '50文字以内で入力してください',
      max150: (v) => !v || v.length <= 150 || '150文字以内で入力してください',
    },
    focus: false,
    isMobile: window.innerWidth >= 600 ? true : false,
    fullscreen: window.innerWidth >= 1264 ? false : true,
    clipImageDialog: false,
    clipMode: '',
    clipLoading: false,
    minScrollerHeight: 0,
    searchBox: false,
    searchWord: '',
    tabs: [
      {
        id: 'joined',
        title: '参加中',
      }, {
        id: 'all',
        title: 'すべて',
      },
    ],
    selectedTab: 'joined',
    results: {
      joined: [],
      all: [],
    },
    noMoreResults: {
      joined: false,
      all: false
    },
    heights: {
      joined: {},
      all: {},
    },
    beforeScrollY: {
      joined: 0,
      all: 0,
    },
    communityAddDialog: false,
    createLoading: false,
    communityName: '',
    description: '',
    kind: '',
    kindList: [
      { text: '公開', value: 1 },
      { text: '申請許可制', value: 2 },
      { text: '招待制', value: 3 }
    ],
    tags: []
  }),
  watch: {
    async 'selectedSettingsImages.icon.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.icon = true;
      let icon = this.selectedSettingsImages.icon;
      let file = await this.compressFile(this.$refs.uploadIcon.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'icon';
        this.clipImageDialog = true;
      }
      icon.blob = val;
      icon.binary = file;
      this.compressing.icon = false;
    },
    async 'selectedSettingsImages.top.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.top = true;
      let top = this.selectedSettingsImages.top;
      let file = await this.compressFile(this.$refs.uploadTop.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'top';
        this.clipImageDialog = true;
      }
      top.blob = val;
      top.binary = file;
      this.compressing.top = false;
    },
    selectedTab(newVal, oldVal) {
      this.searchBox = false;
      this.searchWord = '';
      this.heights[oldVal] = this.$refs.virtualScroll[0].itemHeights;
      this.beforeScrollY[oldVal] = window.scrollY;
    },
    communityAddDialog() {
      this.communityName = '';
      this.description = '';
      this.kind = '';
      this.tags = [];
      console.log(this.selectedSettingsImages);
      this.selectedSettingsImages = {
        icon: {
          originalBlob: null,
          blob: null,
          binary: null
        },
        top: {
          originalBlob: null,
          blob: null,
          binary: null
        }
      };
      this.createLoading = false;
    },
    tags(val) {
      let lastTag = val.slice(-1)[0];
      if (val.length > 10 || lastTag.length > 50) {
        this.snackbarColor = 'error'; this.snackbarText = 'タグは50文字以下、10個以内で入力してください。'; this.snackbar = true;
        this.tags.pop();
      } else if (!!lastTag.match(/[ ,^]/)) {
        this.tags.pop();
        !lastTag.replace(/[ ,^]/g, '') || this.tags.push(lastTag.replace(/[ ,^]/g, ''));
      }
    },
  },
  methods: {
    removeTag (item) {
      this.tags.splice(this.tags.indexOf(item), 1)
      this.tags = [...this.tags]
    },
    onScroll(e) {
      let scrollTop = e.target.scrollingElement.scrollTop;
      if (scrollTop <= 500) {
        this.hideOnScroll = false;
      } else {
        this.hideOnScroll = true;
      }
    },
    doSearch(tag) {
      this.$store.dispatch('doUpdateCommunityState', { tab: this.selectedTab });
      if (tag) {
        router.push({
          name: 'communities-search',
          query: { word: tag, tag: true }
        });
        return;
      }
      if (this.searchWord) {
        router.push({ name: 'communities-search', query: { word: this.searchWord } });
      }
    },
    async onRefresh() {
      let url = this.selectedTab === 'joined' ? 'mygroup/' : '';
      await axios
        .get('/group/' + url)
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          let results = this.results[this.selectedTab];
          results = [];
          results.push(...res.data);
        })
        .catch(async e => {
          console.log(e);
          await this.sleep(500);
        });
    },
    async loadMore() {
      let url = this.selectedTab === 'joined' ? 'mygroup/' : '';
      await axios
        .get('/group/' + url)
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          let results = this.results[this.selectedTab];
          results.push(...res.data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.noMoreResults[this.selectedTab] = true;
        });
    },
    clipImage(mode) {
      this.clipLoading = true;
      let image = mode === 'icon' ? this.selectedSettingsImages.icon : this.selectedSettingsImages.top;
      let canvas = this.$refs.imageClipper.clip();
      canvas.toBlob(async blob => {
        image.blob = URL.createObjectURL(blob);
        let file = new File([blob], new Date() + '.png', { type: 'image/png' });
        image.binary = await this.compressFile(file);
        console.log(image.binary);
        this.clipLoading = false;
        this.clipImageDialog = false;
      });
    },
    async createCommunity() {
      this.createLoading = true;
      let group_id;
      try {
        await axios
          .post('/group/create', {
            group: this.communityName,
            description: this.description.replace(/\n{2,}/g, '\n\n'),
            kind: this.kind - 1
          })
          .then(res => {
            if (res.status !== 200) {
              throw new Error();
            }
            group_id = res.data.group_id;
          })
        await axios
          .post('/group/manage/tag/add', {
            group_id,
            tag: String(this.tags)
          })
          .then(res => {
            if (res.status !== 200) {
              throw new Error();
            }
          })
        console.log(this.selectedSettingsImages);
        let params = new FormData();
        params.append('group_id', group_id);
        params.append('icon_image', this.selectedSettingsImages.icon.binary);
        params.append('top_image', this.selectedSettingsImages.top.binary);
        await imageAxios
          .post('/group/manage/image', params)
          .then(res => {
            console.log(res);
            if (res.status !== 200) {
              throw new Error();
            }
          })
        this.createLoading = false;
        this.communityAddDialog = false;
        this.snackbarColor = 'success'; this.snackbarText = 'コミュニティを作成しました。'; this.snackbar = true;
      } catch(e) {
        console.log(e);
        this.snackbarColor = 'error'; this.snackbarText = 'エラーが発生しました。'; this.snackbar = true;
      }
    }
  },
  mounted() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
};
</script>
