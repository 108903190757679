// @ts-ignore
import imageCompression from 'browser-image-compression';

export default {
  async getCompressImageFileAsync(file: File) {
    let sizeMB: number = file.size / 10 / (1024 * 1024);
    if (sizeMB > 1) {
      sizeMB = 1;
    } else if (sizeMB < 0.2) {
      sizeMB = 0.2;
    }
    const options = {
      maxSizeMB: sizeMB,
      maxWidthOrHeight: 1920,
    };
    try {
      const blob = await imageCompression(file, options);
      return new File([blob], new Date() + '.png', { type: 'image/png' });
    } catch (e) {
      console.error('getCompressImageFileAsync is error', e);
      throw e;
    }
  },
  async getDataUrlFromFile(file: File) {
    try {
      return await imageCompression.getDataUrlFromFile(file);
    } catch (e) {
      console.error('getDataUrlFromFile is error', e);
      throw e;
    }
  },
};
