<template>
  <div class="open-user-list">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app elevation="1">
      <v-btn icon @click="pageBack">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ listType }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="['member', 'invite'].includes($route.params.type) && $route.query.is_admin == true"
        icon
        @click="editMember"
      >
        <v-icon :color="edit ? 'primary' : ''">{{ $route.params.type === 'member' ? 'fa-ban' : 'fa-envelope' }}</v-icon>
      </v-btn>
    </v-app-bar>

    <VirtualScroll
      key-field="user_id"
      :items="results"
      :min-item-height="92"
      :minScrollerHeight="minScrollerHeight"
      :buffer="minScrollerHeight*2"
      :paddingBottom="$_domHeights.bottomNavigation"
    >
      <template v-slot="{ item }">
        <v-card
          class="user-box mx-auto"
          max-width="750"
          flat
          @click="openProfile(item.user_id)"
        >
          <v-row class="ma-0">
            <v-spacer></v-spacer>
            <v-col class="avatar-col pr-0 text-center col-2">
              <v-avatar color="grey lighten-4">
                <v-img v-if="item.icon_link" :src="item.icon_link" v-on:error="$_notImage(item, 'icon_link')"></v-img>
                <v-icon v-else>fa-user</v-icon>
              </v-avatar>
            </v-col>
            <v-col class="px-0 py-2 col-10">
              <v-card-title class="py-0">
                <div class="name-id-box">
                  <v-row class="subtitle-2 font-weight-bold ma-0">{{ item.name }}</v-row>
                  <v-row class="subtitle-2 grey--text ma-0">@{{ item.user_id }}</v-row>
                </div>
                <v-spacer></v-spacer>
                <template v-if="item.user_id !== userData.id">
                  <v-btn
                    v-if="$route.params.type === 'change-owner'"
                    small outlined
                    @click.stop="adminAction('change-owner', { account_id: item.account_id, user_id: item.user_id })"
                  >
                    <span class="follow-button">変更</span>
                  </v-btn>
                  <v-btn
                    v-else-if="edit && $route.params.type === 'member'"
                    small
                    :outlined="!item.is_ban" :depressed="item.is_ban" color="secondary"
                    @click.stop="adminAction('ban', { account_id: item.account_id, user_id: item.user_id, is_ban: (!!item.is_ban ? item.is_ban : false) })"
                  >
                    <span class="follow-button">{{ item.is_ban ? 'BAN中' : 'BAN' }}</span>
                  </v-btn>
                  <v-btn
                    v-else-if="!['request', 'invite', 'ban'].includes($route.params.type)"
                    small
                    :outlined="!item.is_follow && !item.is_block" :depressed="item.is_follow || item.is_block"
                    :color="item.is_block ? 'secondary' : 'primary'"
                    @click.stop="userAction((item.is_block ? 'block' : 'follow'), { user_id: item.user_id })"
                  >
                    <span class="follow-button">{{ item.is_block ? 'ブロック中' : item.is_follow ? 'フォロー中' : 'フォロー' }}</span>
                  </v-btn>
                  <v-btn
                    v-else-if="edit && $route.params.type === 'invite'"
                    small
                    :outlined="!!item.is_invite ? !item.is_invite : true"
                    :depressed="!!item.is_invite ? item.is_invite : false"
                    color="primary"
                    @click.stop="adminAction('invite', { account_id: item.account_id, user_id: item.user_id, is_invite: (!!item.is_invite ? item.is_invite : false) })"
                  >
                    <span class="follow-button">{{ (!!item.is_invite ? item.is_invite : false) ? '招待中' : '招待' }}</span>
                  </v-btn>
                  <v-btn
                    v-else-if="$route.params.type === 'invite'"
                    small
                    :outlined="typeof item.is_invite !== 'undefined' ? !item.is_invite : false"
                    :depressed="typeof item.is_invite !== 'undefined' ? item.is_invite : true"
                    color="primary"
                    @click.stop="adminAction('invite', { account_id: item.account_id, user_id: item.user_id, is_invite: (typeof item.is_invite !== 'undefined' ? item.is_invite : true) })"
                  >
                    <span class="follow-button">{{ (typeof item.is_invite !== 'undefined' ? item.is_invite : true) ? '招待中' : '招待' }}</span>
                  </v-btn>
                  <v-btn
                    v-else-if="$route.params.type === 'ban'"
                    small
                    :outlined="!!item.is_ban ? !item.is_ban : false"
                    :depressed="!!item.is_ban ? item.is_ban : true"
                    color="secondary"
                    @click.stop="adminAction('ban', { account_id: item.account_id, user_id: item.user_id, is_ban: (!!item.is_ban ? item.is_ban : true) })"
                  >
                    <span class="follow-button">{{ (!!item.is_ban ? item.is_ban : true) ? 'BAN中' : 'BAN' }}</span>
                  </v-btn>
                  <v-btn-toggle v-else-if="$route.params.type === 'request'">
                    <v-btn small @click.stop="adminAction('ban', { account_id: item.account_id, user_id: item.user_id })">
                      <v-icon small>fa-times</v-icon>
                    </v-btn>
                    <v-btn small @click.stop="adminAction('allow', { account_id: item.account_id, user_id: item.user_id })">
                      <v-icon small>far fa-circle</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-card-title>
              <v-card-text class="pb-0">{{ item.description }}</v-card-text>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </template>
      <template v-slot:bottom>
        <div style="padding-bottom: 88px;"></div>
      </template>
    </VirtualScroll>
  </div>
</template>

<style lang="scss">
.open-user-list {

}
</style>

<script>
import axios from '../axios/index';
import router from '../router';

export default {
  name: 'open-user-list',
  data: () => ({
    edit: false,
    listType: '',
    results: [],
    minScrollerHeight: 0
  }),
  watch: {
    $route() {
      this.setResults();
    },
  },
  methods: {
    pageBack() {
      if (this.$route.params.type !== 'invite') {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: 'open-community',
          params: { group_id: this.$route.params.id }
        });
      }
    },
    adminAction(actionId, ids) {
      if (actionId === 'change-owner') {
        if (!confirm('この操作は取り消せません。\nよろしいですか？')) {
          return;
        }
        let self = this;
        axios
          .post('/group/manage/admin', {
            group_id: self.$route.params.id,
            new_owner: ids.account_id
          })
          .then(res => {
            if (res.status !== 200) {
              throw new Error();
            }
            self.snackbarColor = 'success'; self.snackbarText = '@' + ids.user_id + 'さん管理者に変更しました。';
            self.snackbar = true;
          })
          .catch((e) => {
            console.log(e);
            self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
          });
      } else if (actionId === 'invite') {
        let self = this;
        axios
          .post('/group/manage/' + actionId + (ids.is_invite ? '/del' : ''), {
            group_id: self.$route.params.id,
            invite_account_id: ids.account_id
          })
          .then(res => {
            if (res.status !== 200) {
              throw new Error();
            }
            let index = self.results.map(obj => obj.user_id).indexOf(ids.user_id);
            self.results[index].is_invite = !ids.is_invite;
            self.snackbarColor = 'success'; self.snackbarText = '@' + ids.user_id + (ids.is_invite ? 'さんの招待を取り消しました。' : 'さんを招待しました。');
            self.snackbar = true;
          })
          .catch((e) => {
            console.log(e);
            self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
          });
      } else if (actionId === 'ban') {
        let self = this;
        axios
          .post('/group/manage/' + actionId + (ids.is_ban ? '/del' : ''), {
            group_id: self.$route.params.id,
            ban_account_id: ids.account_id
          })
          .then(res => {
            if (res.status !== 200) {
              throw new Error();
            }
            let index = self.results.map(obj => obj.user_id).indexOf(ids.user_id);
            self.results[index].is_ban = !ids.is_ban;
            if (ids.is_ban || self.$route.params.type === 'request') {
              self.results.splice(index, 1);
            }
            self.snackbarColor = 'success'; self.snackbarText = '@' + ids.user_id + (ids.is_ban ? 'さんのBANを取り消しました。' : 'さんをBANしました。');
            self.snackbar = true;
          })
          .catch((e) => {
            console.log(e);
            self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
          });
      } else if (actionId === 'allow') {
        let self = this;
        axios
          .post('/group/manage/' + actionId, {
            group_id: self.$route.params.id,
            allow_account_id: ids.account_id
          })
          .then(res => {
            if (res.status !== 200) {
              throw new Error();
            }
            let index = self.results.map(obj => obj.user_id).indexOf(ids.user_id);
            self.results.splice(index, 1);
            self.snackbarColor = 'success'; self.snackbarText = '@' + ids.user_id + 'さんを承認しました。'; self.snackbar = true;
          })
          .catch((e) => {
            console.log(e);
            self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
          });
      }
    },
    editMember() {
      if (this.$route.params.type === 'invite') {
        this.results.splice(0, this.results.length);
        let query = { is_admin: true };
        if (!this.edit) {
          query.select_mode = true;
        }
        let params = this.$route.params;
        this.$router.push({
          name: 'open-user-list',
          params: {
            mode: params.mode,
            id: params.id,
            type: params.type,
          },
          query,
        });
        this.isLoading.results = true;
      }
      this.edit = !this.edit;
    },
    setResults() {
      let uri = null;
      let params = {};
      if (this.$route.params.mode === 'user') {
        if (['follow', 'follower', 'block'].includes(this.$route.params.type)) {
          uri = '/users/list_' + this.$route.params.type;
          params.user_id = this.$route.params.id;
        }
      } else if (this.$route.params.mode === 'community') {
        if (!!this.$route.query.select_mode) {
          uri = '/group/manage/invite/list';
        } else if (['member', 'change-owner'].includes(this.$route.params.type)) {
          uri = '/group/member';
        } else if (['request', 'invite', 'ban'].includes(this.$route.params.type)) {
          uri = '/group/member/' + this.$route.params.type;
        }
        params.group_id = this.$route.params.id;
      }
      if (!uri) {
        router.push({name: 'home'});
      }
      switch (this.$route.params.type) {
        case 'block':
          this.listType = 'ブロック';
          break;
        case 'follow':
          this.listType = 'フォロー';
          break;
        case 'follower':
          this.listType = 'フォロワー';
          break;
        case 'member':
          this.listType = 'メンバー';
          break;
        case 'request':
          this.listType = '申請リスト';
          break;
        case 'invite':
          this.listType = !!this.$route.query.select_mode ? 'フォローから招待' : '招待リスト';
          break;
        case 'ban':
          this.listType = 'BANリスト';
          break;
        case 'change-owner':
          this.listType = '管理者変更';
          break;
      }
      let self = this;
      axios
        .get(uri, { params })
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          self.results.push(...(res.data[!!self.$route.query.select_mode ? 'follow' : self.$route.params.type] || res.data));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  beforeMount() {
    this.setResults();
  },
  mounted() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
};
</script>
