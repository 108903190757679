<template>
  <v-card
    flat class="notification-box mx-auto"
    @click="item.kind === 0 ? openProfile(item.user_id) : openPost(item.post_id)"
  >
    <v-row class="ma-0">
      <v-spacer></v-spacer>
      <v-col class="avatar-col pr-0 text-center col-2">
        <v-icon :size="20" :color="kind.color">{{ kind.icon }}</v-icon>
      </v-col>
      <v-col class="py-2 pl-0 pr-1 col-10">
        <v-card-title class="py-0" @click.stop="openProfile(item.user_id)">
          <v-avatar size="24" color="grey lighten-4 mr-2">
            <v-img v-if="item.icon_link" :src="item.icon_link" v-on:error="$_notImage(item, 'icon_link')"></v-img>
            <v-icon v-else small>fa-user</v-icon>
          </v-avatar>
          <div class="name-id-box">
            <span class="subtitle-2 font-weight-bold">{{ item.name }}</span>
            <span v-if="item.is_official" class="name-id-box__badge d-inline-block ml-1">
              <v-img
                src="/img/official_logo_256.png"
                width="16px" height="16px"
              ></v-img>
            </span>
            <span class="subtitle-2 grey--text ml-1">@{{ item.user_id }}</span>
          </div>
          <v-spacer></v-spacer>
          <span class="subtitle-2">{{ getFromNow(item.time) }}</span>
        </v-card-title>
        <v-card-text class="pb-0 grey--text">{{ item.message }}</v-card-text>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-card>
</template>

<style lang="scss">
.notification-box {
  max-width: 750px;

  .name-id-box {
    line-height: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60%;

    .name-id-box__badge {
      position: relative;
      width: 16px;

      div {
        position: absolute;
        bottom: -1.5px;
      }
    }
  }
  p {
    white-space: pre-wrap;
  }
  .v-ripple__container {
    display: none;
  }
}
.notification-box::before, .notification-box::after {
  content: '';
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}
</style>

<script>
export default {
  name: 'notification-box',
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  computed: {
    kind() {
      const kind = this.item.kind;
      let icon;
      let color;
      if (kind === 0) {
        icon = 'fas fa-user-plus';
        color = 'secondary';
      } else if (kind === 2) {
        icon = 'fas fa-heart';
        color = 'primary';
      } else if (kind === 3) {
        icon = 'fa-share';
        color = 'success';
      } else if (kind === 12) {
        icon = 'far fa-comment-dots';
        color = 'secondary';
      }
      return {
        icon,
        color
      };
    }
  }
}
</script>
