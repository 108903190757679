<template>
  <div class="term-of-use">
    <v-card
      class="mx-auto pa-4"
      max-width="800"
    >
      <v-card-title class="pa-0">利用規約</v-card-title>
      <v-card-text class="pa-0">

<p>
    この利用規約（以下，「本規約」といいます。）は，学生専用SNS「ピペリア」運営（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
</p>
<span class="subtitle-1">
    <a name="_no8frib3azyw"></a>
    第1条（適用）
</span>
<ol start="1" type="1">
    <li>
        本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
    </li>
</ol>
<ol start="2" type="1">
    <li>
        当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
    </li>
</ol>
<ol start="3" type="1">
    <li>
        本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
    </li>
</ol>
<span class="subtitle-1">
    <a name="_m682lmqddy9o"></a>
    第2条（利用登録）
</span>
<ol start="1" type="1">
    <li>
        本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこの承認を登録希望者に通知することによって，利用登録が完了するものとします。
    </li>
</ol>
<ol start="2" type="1">
    <li>
        当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
    </li>
</ol>
<p>
    1. 利用登録の申請者が生徒または学生の身分を有していない場合
</p>
<p>
    2. 申請者が未成年者、成年被後見人、被保佐人、被補助人のいずれに当てはまるり、後見人、補佐人又、補助人又は法定代理人の同意を得ていない場合
</p>
<p>
    3. 利用登録の申請に際して虚偽の事項を届け出た場合
</p>
<p>
    4. 本規約に違反したことがある者からの申請である場合
</p>
<p>
    5. その他，当社が利用登録を相当でないと判断した場合
</p>
<span class="subtitle-1">
    <a name="_ze4ge11bomtm"></a>
    第3条（ユーザーIDおよびパスワードの管理）
</span>
<ol start="1" type="1">
    <li>
        ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
    </li>
    <li>
        ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
    </li>
    <li>
        ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社は一切の責任を負わないものとします。
    </li>
</ol>
<span class="subtitle-1">
    <a name="_48dbt2tzqyv2"></a>
    第4条（利用料金および支払方法）
</span>
<ol start="1" type="1">
    <li>
        ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。
    </li>
    <li>
        ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
    </li>
</ol>
<span class="subtitle-1">
    <a name="_7ik49s5rwvmd"></a>
    第5条（禁止事項）
</span>
<p>
    ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
</p>
<ol start="1" type="1">
    <li>
        法令または公序良俗に違反する行為
    </li>
    <li>
        犯罪行為に関連する行為
    </li>
    <li>
        当社，本サービスの他のユーザー，または第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
    </li>
    <li>
        当社のサービスの運営を妨害するおそれのある行為
    </li>
    <li>
        他のユーザーに関する個人情報等を収集または蓄積する行為
    </li>
    <li>
        不正アクセスをし，またはこれを試みる行為
    </li>
    <li>
        他のユーザーに成りすます行為
    </li>
    <li>
        当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
    </li>
    <li>
        当社システムへの不正侵入, クラッキング, リバース・エンジニアリング, 逆コンパイル, 逆アセンブル,
        本サービスを妨害するまたは利益を侵害する行為。
    </li>
</ol>
<ol start="10" type="1">
    <li>
        以下の表現を含み，または含むと当社が判断する内容を本サービス上に投稿し，または送信する行為
    </li>
</ol>
<p>
    1. 過度に暴力的な表現
</p>
<p>
    2. 露骨な性的表現
</p>
<p>
    3. 人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現
</p>
<p>
    4. 自殺，自傷行為，薬物乱用を誘引または助長する表現
</p>
<p>
    5. その他反社会的な内容を含み他人に不快感を与える表現
</p>
<ol start="11" type="1">
    <li>
        以下を目的とし，または目的とすると当社が判断する行為
    </li>
</ol>
<p>
    1. 営業，宣伝，広告，勧誘，その他営利を目的とする行為（当社の認めたものを除きます。）
</p>
<p>
    2. 性行為やわいせつな行為を目的とする行為
</p>
<p>
    3. 出会いや交際を目的とする行為
</p>
<p>
    4. 他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為
</p>
<p>
    5. 当社，本サービスの他のユーザー，または第三者に不利益，損害または不快感を与えることを目的とする行為
</p>
<p>
    6. 意図的に虚偽の情報を流布する行為
</p>
<p>
    7. その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
</p>
<p>
    8. 宗教活動または宗教団体への勧誘行為
</p>
<p>
    9. 自殺又は自傷行為を助長する行為
</p>
<p>
    10. 異性交際に関する情報を流布又は送信する行為
</p>
<p>
    11. 公序良俗に反する行為
</p>
<p>
    12. その他，当社が不適切と判断する行為
</p>
<span class="subtitle-1">
    <a name="_2hi2wnsrwvsj"></a>
    第6条（本サービスの提供の停止等）
</span>
<ol start="1" type="1">
    <li>
        当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
    </li>
</ol>
<p>
    1. 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
</p>
<p>
    2. 地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
</p>
<p>
    3. コンピュータまたは通信回線等が事故により停止した場合
</p>
<p>
    4. その他，当社が本サービスの提供が困難と判断した場合
</p>
<ol start="2" type="1">
    <li>
        当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
    </li>
</ol>
<span class="subtitle-1">
    <a name="_37gjoxgatr7m"></a>
    第7条（著作権）
</span>
<ol start="1" type="1">
    <li>
        ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報に関してのみ，本サービスを利用し，投稿ないしアップロードすることができるものとします。
    </li>
</ol>
<ol start="2" type="1">
    <li>
        ユーザーが本サービスを利用して投稿ないしアップロードした文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。ただし，当社は，本サービスを利用して投稿ないしアップロードされた文章，画像，映像等について，本サービスの改良，品質の向上，または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。
    </li>
</ol>
<ol start="3" type="1">
    <li>
        前項本文の定めるものを除き，本サービスおよび本サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて当社または当社にその利用を許諾した権利者に帰属し，ユーザーは無断で複製，譲渡，貸与，翻訳，改変，転載，公衆送信（送信可能化を含みます。），伝送，配布，出版，営業使用等をしてはならないものとします。
    </li>
</ol>
<br clear="all"/>
<span class="subtitle-1">
    <a name="_spztaifnhi9f"></a>
</span>
<span class="subtitle-1">
    <a name="_4v41xi2l7bcl"></a>
    第8条（利用制限および登録抹消）
</span>
<ol start="1" type="1">
    <li>
        当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
    </li>
</ol>
<p>
    1. 本規約のいずれかの条項に違反した場合
</p>
<p>
    2. 登録事項に虚偽の事実があることが判明した場合
</p>
<p>
    3. 決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合
</p>
<p>
    4. 料金等の支払債務の不履行があった場合
</p>
<p>
    5. 当社からの連絡に対し，一定期間返答がない場合
</p>
<p>
    6. 本サービスについて，最終の利用から一定期間利用がない場合
</p>
<p>
    7. その他，当社が本サービスの利用を適当でないと判断した場合
</p>
<ol start="2" type="1">
    <li>
        前項各号のいずれかに該当した場合，ユーザーは，当然に当社に対する一切の債務について期限の利益を失い，その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
    </li>
    <li>
        当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
    </li>
</ol>
<span class="subtitle-1">
    <a name="_5sxah4wp317a"></a>
    第9条（退会）
</span>
<ol start="1" type="1">
    <li>
        ユーザーは、当社の定める退会手続により，本サービスから退会できるものとします。
    </li>
    <li>
        当社はユーザーの大会後もユーザーに関する全てのデータ並びに、ログデータを最大1年間保存するものとします。
    </li>
</ol>
<span class="subtitle-1">
    <a name="_57gidzm65jno"></a>
    第10条（保証の否認および免責事項）
</span>
<ol start="1" type="1">
    <li>
        当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
    </li>
    <li>
        当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
    </li>
    <li>
        前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
    </li>
    <li>
        当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
    </li>
</ol>
<span class="subtitle-1">
    <a name="_kv5p1ggvjthk"></a>
    第11条（サービス内容の変更等）
</span>
<p>
    当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
</p>
<span class="subtitle-1">
    <a name="_nlrzggi2s74l"></a>
    第12条（利用規約の変更）
</span>
<p>
    当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
</p>
<span class="subtitle-1">
    <a name="_brsqp8pagtfe"></a>
    第13条（個人情報の取扱い）
</span>
<p>
    当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
</p>
<span class="subtitle-1">
    <a name="_u8sm7c4lc41b"></a>
    第14条（通知または連絡）
</span>
<p>
    ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
</p>
<span class="subtitle-1">
    <a name="_jbp0rkca37ip"></a>
    第15条（権利義務の譲渡の禁止）
</span>
<p>
    ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
</p>
<span class="subtitle-1">
    <a name="_jweryk2f0t7c"></a>
    第16条（準拠法・裁判管轄）
</span>
<ol start="1" type="1">
    <li>
        本規約の解釈にあたっては，日本法を準拠法とします。
    </li>
    <li>
        本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
    </li>
</ol>
      </v-card-text>
    </v-card>
  </div>
</template>

<style>
section > div:not(.subtitle) {
  text-align: justify;
}
</style>

<script>
export default {
  name: 'term-of-use'
};
</script>
