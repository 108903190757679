import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import InfiniteLoading from "vue-infinite-loading";
// @ts-ignore
import VueGallery from "vue-gallery";
// @ts-ignore
import VuejsClipper from "vuejs-clipper";
// @ts-ignore
import store from "./store";

// components
import VirtualScroll from "./components/VirtualScroll.vue";
import BottomSheet from "./components/BottomSheet.vue";
import PostBox from "./components/PostBox.vue";
import OriginBox from "./components/OriginBox.vue";
import ImagesBox from "./components/ImagesBox.vue";
import NotificationBox from "./components/NotificationBox.vue";
import ChatBox from "./components/ChatBox.vue";
import CommunityPostBox from "./components/CommunityPostBox.vue";

// static
import "./registerServiceWorker";
import "./assets/sass/fonts.scss";
import { refreshAndSetUser } from "./mixins/token";

Vue.use(InfiniteLoading);
Vue.component("VGallery", VueGallery);
// @ts-ignore
Vue.use(VuejsClipper);
Vue.component("clipperFixed", VuejsClipper);
Vue.component("clipperUpload", VuejsClipper);
Vue.component("VirtualScroll", VirtualScroll);
// @ts-ignore
Vue.component("BottomSheet", BottomSheet);
// @ts-ignore
Vue.component("PostBox", PostBox);
Vue.component("OriginBox", OriginBox);
// @ts-ignore
Vue.component("ImagesBox", ImagesBox);
Vue.component("NotificationBox", NotificationBox);
Vue.component("ChatBox", ChatBox);
Vue.component("CommunityPostBox", CommunityPostBox);

Vue.config.productionTip = false;

const createApp = async () => {
  await refreshAndSetUser();

  new Vue({
    router,
    vuetify,
    // @ts-ignore
    store,
    render: (h) => h(App),
  }).$mount("#app");
};

createApp();
