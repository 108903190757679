<template>
  <v-card
    flat class="post-box mx-auto"
    @click="!largeMode ? openPost(postData.post_id) : null"
  >
    <v-row
      v-if="notPost"
      align="center"
      justify="center"
      class="wrapper ma-0"
    >
      <v-col class="body-2" style="max-width: fit-content;">
        指定した投稿が存在しません。
      </v-col>
    </v-row>
    <template v-else>
      <v-row v-if="showMessage && (isShare || postData.parent)" class="ma-0 pl-3 pt-2">
        <v-spacer></v-spacer>
        <v-col class="col-10 pa-0">
          <span v-if="postData.parent" class="subtitle-2"><v-icon small class="mr-1">fa-comment-dots</v-icon>返信</span>
          <span v-else class="subtitle-2" @click.stop="openProfile(post.user_id)"><v-icon small class="mr-1">fa-share</v-icon>{{ shareTitle }}</span>
        </v-col>
      </v-row>
      <template v-if="largeMode">
        <v-row class="ma-0">
          <v-spacer></v-spacer>
          <v-col class="avatar-col pr-0 text-center col-2">
            <v-avatar color="grey lighten-4" @click.stop="openProfile(postData.user_id)">
              <v-img v-if="postData.icon_link" :src="postData.icon_link" v-on:error="$_notImage(postData, 'icon_link')"></v-img>
              <v-icon v-else>fa-user</v-icon>
            </v-avatar>
          </v-col>
          <v-col class="pt-2 pb-1 pl-0 pr-1 col-10">
            <v-card-title class="py-0">
              <div class="post-name-box">
                <v-row no-gutters>
                  <v-col class="post-name-box__overflow subtitle-2 font-weight-bold">{{ postData.name }}</v-col>
                  <v-col class="post-name-box__badge">
                    <v-img
                      v-if="postData.is_official"
                      src="/img/official_logo_256.png"
                      width="1rem" height="1rem"
                      class="ml-1"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="post-name-box__overflow subtitle-2 grey--text">@{{ postData.user_id }}</v-col>
                </v-row>
              </div>
              <v-spacer></v-spacer>
              <span class="subtitle-2">{{ getFromNow(postData.time) }}</span>
            </v-card-title>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0">
          <v-spacer></v-spacer>
          <v-col :class="($_isMobile ? 'col-12 px-1' : 'col-11 px-0') + ' pt-0 pb-1'">
            <v-card-text class="py-0">{{ postData.message }}
              <ImagesBox v-if="postData.images ? postData.images.length : false" :images="postData.images" :show="_setImages"></ImagesBox>
              <OriginBox v-if="postData.origin" :key="postData.origin" :origin="postData.origin" :share_id="postData.post_id"></OriginBox>
            </v-card-text>
            <v-card-actions class="pa-0 subtitle-2">
              <v-btn class="pa-0 ml-2" text icon>
                <v-icon small>far fa-comment-dots</v-icon>
              </v-btn><span>{{ changeNumFormat(postData.comments) }}</span>
              <v-spacer></v-spacer>

              <v-btn
                class="pa-0 ml-2" @click.stop="sharePost(postData.post_id)"
                text icon :color="postData.share_flag ? 'success': ''"
                :disabled="Boolean(postData.origin)"
              >
                <v-icon small>fa-share</v-icon>
              </v-btn><span v-on:click.stop="!Boolean(postData.origin) && sharePost(postData.post_id)">{{ changeNumFormat(postData.shares) }}</span>
              <v-spacer></v-spacer>

              <v-btn
                class="pa-0 ml-2" @click.stop="likePost(postData.post_id)"
                text icon :color="postData.favorite_flag ? 'primary': ''"
                :disabled="postData.user_id === userData.id"
              >
                <v-icon small>{{ postData.favorite_flag ? 'fas' : 'far' }} fa-heart</v-icon>
              </v-btn><span v-on:click.stop="likePost(postData.post_id)">{{ changeNumFormat(postData.favorites) }}</span>
              <v-spacer></v-spacer>

              <v-btn
                v-if="userData.id"
                class="pa-0 ml-2"
                text icon
                @click.stop="showSheet(postData.post_id, postData.user_id)"
              >
                <v-icon small>fa-ellipsis-v</v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </template>
      <v-row v-else class="ma-0">
        <v-spacer></v-spacer>
        <v-col class="avatar-col pr-0 text-center col-2">
          <v-avatar color="grey lighten-4" @click.stop="openProfile(postData.user_id)">
            <v-img v-if="postData.icon_link" :src="postData.icon_link" v-on:error="$_notImage(postData, 'icon_link')"></v-img>
            <v-icon v-else>fa-user</v-icon>
          </v-avatar>
        </v-col>
        <v-col :class="(postData.message || !postData.origin ? 'pt-2' : 'pt-0') + ' pb-1 pl-0 pr-1 col-10'">
          <v-card-title class="py-0">
            <div class="tl-name-box">
              <span class="subtitle-2 font-weight-bold">{{ postData.name }}</span>
              <span v-if="postData.is_official" class="tl-name-box__badge d-inline-block ml-1">
                <v-img
                  src="/img/official_logo_256.png"
                  width="16px" height="16px"
                ></v-img>
              </span>
              <span class="subtitle-2 grey--text ml-1">@{{ postData.user_id }}</span>
            </div>
            <v-spacer></v-spacer>
            <span class="subtitle-2">{{ getFromNow(postData.time) }}</span>
          </v-card-title>
          <v-card-text class="pb-0">{{ postData.message }}
            <ImagesBox v-if="postData.images ? postData.images.length : false" :images="postData.images" :show="_setImages"></ImagesBox>
            <OriginBox v-if="postData.origin" :key="postData.origin" :origin="postData.origin" :share_id="postData.post_id"></OriginBox>
          </v-card-text>
          <v-card-actions class="pa-0 subtitle-2">
            <v-btn class="pa-0 ml-2" text icon>
              <v-icon small>far fa-comment-dots</v-icon>
            </v-btn><span>{{ changeNumFormat(postData.comments) }}</span>
            <v-spacer></v-spacer>

            <v-btn
              class="pa-0 ml-2" @click.stop="_sharePost(postData.post_id)"
              text icon :color="postData.share_flag ? 'success': ''"
              :disabled="Boolean(postData.origin)"
            >
              <v-icon small>fa-share</v-icon>
            </v-btn><span v-on:click.stop="!Boolean(postData.origin) && _sharePost(postData.post_id)">{{ changeNumFormat(postData.shares) }}</span>
            <v-spacer></v-spacer>

            <v-btn
              class="pa-0 ml-2" @click.stop="likePost(postData.post_id)"
              text icon :color="postData.favorite_flag ? 'primary': ''"
              :disabled="postData.user_id === userData.id"
            >
              <v-icon small>{{ postData.favorite_flag ? 'fas' : 'far' }} fa-heart</v-icon>
            </v-btn><span v-on:click.stop="likePost(postData.post_id)">{{ changeNumFormat(postData.favorites) }}</span>
            <v-spacer></v-spacer>

            <v-btn
              v-if="userData.id"
              class="pa-0 ml-2"
              text icon
              @click.stop="_showSheet(postData.post_id, postData.user_id)"
            >
              <v-icon small>fa-ellipsis-v</v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </template>
  </v-card>
</template>

<style lang="scss">
@media (max-width: 370px) {
  .post-box {
    zoom: 0.85;
  }
}
@media (min-width: 640px) {
  .post-box .avatar-col {
    max-width: 10% !important;
  }
}
.post-box {
  max-width: 750px;

  .avatar-col {
    max-width: 17%;
  }
  .post-name-box {
    line-height: 1rem;
    white-space: nowrap;
    width: 70%;

    .post-name-box__overflow {
      max-width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .post-name-box__badge {
      max-width: 1.5rem;
    }
  }
  .tl-name-box {
    line-height: 1rem;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .tl-name-box__badge {
      position: relative;
      width: 16px;

      div {
        position: absolute;
        bottom: -1.5px;
      }
    }
  }
  .v-card__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 20;
    overflow: hidden;
    /* text-align: justify; */
    white-space: pre-wrap;
  }
  .v-ripple__container {
    display: none;
  }
}
.post-box::before, .post-box::after {
  content: '';
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}
</style>

<script>
import axios from '../axios/index';

export default {
  name: 'post-box',
  props: {
    post: {
      type: Object,
      required: true
    },
    _sharePost: {
      type: Function,
      required: true
    },
    _showSheet: {
      type: Function,
      required: true
    },
    _setImages: {
      type: Function,
      required: true
    },
    largeMode: {
      type: Boolean,
      default: false
    },
    notPost: {
      type: Boolean,
      default: false
    },
    showMessage: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    postData: {}
  }),
  watch: {
    post(val) {
      this.postData = val;
    }
  },
  computed: {
    isShare() {
      return !this.post.message && this.post.origin;
    },
    shareTitle() {
      return this.post.user_id === this.userData.id ? 'シェア済' : '@' + this.post.user_id + 'がシェアしました';
    }
  },
  beforeMount() {
    if (!this.isShare) {
      this.postData = this.post;
      return;
    }
    axios
      .get('/post/post', {
        params: {
          post_id: this.post.origin
        }
      })
      .then(res => {
        if (res.status !== 200) {
          throw new Error();
        }
        this.postData = res.data;
        this.postData.post_id = this.post.origin;
      })
      .catch(e => {
        console.log(e);
        this.notPost = true;
      });
  }
}
</script>
