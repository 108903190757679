<template>
  <div class="login">
    <v-snackbar color="error" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-card class="mx-auto pa-4">
      <v-form ref="form" v-model="valid" @submit.prevent="login">
        <v-card-title class="display-1 primary--text pa-2">
          <span>ログイン</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-text-field
            type="text"
            single-line
            prepend-icon="fa-envelope"
            v-model="mail"
            :rules="rules.mail"
            label="メールアドレス"
            clear-icon="fa-times"
            clearable
            required
            :error="loginError"
            style="height: 70px"
          ></v-text-field>

          <v-text-field
            type="password"
            single-line
            prepend-icon="fa-lock"
            v-model="password"
            :append-icon="showPass ? 'fa-eye-slash' : 'fa-eye'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :rules="rules.password"
            label="パスワード"
            clear-icon="fa-times"
            clearable
            required
            :error="loginError"
            style="height: 70px"
          ></v-text-field>

          <v-btn :to="{ name: 'password-reset' }" text small color="primary">
            <span>パスワードを再発行</span>
          </v-btn>
        </v-card-text>

        <v-card-actions>
          <v-layout justify-end>
            <v-btn text @click="register">
              <span style="width: 4rem">新規登録</span>
            </v-btn>
            <v-btn type="submit" depressed color="primary">
              <span style="width: 4rem">ログイン</span>
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-form>
    </v-card>
    <a
      href="https://apps.apple.com/jp/app/piperia-%E5%AD%A6%E7%94%9F%E5%B0%82%E7%94%A8sns-%E3%83%94%E3%83%9A%E3%83%AA%E3%82%A2/id1558340187?itsct=apps_box&amp;itscg=30200"
      class="d-block text-center mx-auto mt-5"
      style="
        display: inline-block;
        overflow: hidden;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px;
        border-bottom-left-radius: 13px;
        width: 250px;
        height: 83px;
      "
      ><img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&amp;releaseDate=1615939200&h=a58d6eacda30a0dc5c78da87de405502"
        alt="Download on the App Store"
        style="
          border-top-left-radius: 13px;
          border-top-right-radius: 13px;
          border-bottom-right-radius: 13px;
          border-bottom-left-radius: 13px;
          width: 175px;
          height: 58.1px;
        "
    /></a>
  </div>
</template>

<style></style>

<script>
import axios from "../axios/index";
import router from "../router/index";

export default {
  name: "login",
  data: function () {
    return {
      valid: false,
      mail: "",
      password: "",
      loginError: false,
      showPass: false,
      rules: {
        mail: [(v) => !!v || "メールアドレスを入力してください"],
        password: [(v) => !!v || "パスワードを入力してください"],
      },
    };
  },
  methods: {
    login: function (event) {
      this.snackbar = false;
      this.loginError = false;
      if (!this.$refs.form.validate()) {
        return;
      }
      axios
        .post(
          "/accounts/login",
          {
            mail: this.mail,
            password: this.password,
          },
          {
            validateStatus: function (status) {
              return status < 500;
            },
          }
        )
        .then((res) => {
          if (res.status === 400) {
            this.snackbarText = "ユーザIDまたはパスワードが違います";
            this.snackbar = true;
            this.loginError = true;
          } else if (res.data.token) {
            localStorage.setItem("access_token", res.data.access);
            localStorage.setItem("refresh_token", res.data.refresh);
            let userData = JSON.parse(
              decodeURIComponent(
                escape(atob(localStorage.getItem("access_token").split(".")[1]))
              )
            );
            axios
              .get("/accounts/user", {
                params: {
                  // @ts-ignore
                  id: userData.user_id,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  // @ts-ignore
                  this.$store.dispatch("doUpdateUser", {
                    ...res.data,
                    // @ts-ignore
                    id: userData.user_id,
                  });
                  router.push(this.$route.query.redirect || { name: "home" });
                } else {
                  throw new Error();
                }
              });
          } else {
            throw new Error();
          }
        })
        .catch((e) => {
          console.log(e);
          this.snackbarText = "エラーが発生しました";
          this.snackbar = true;
        });
    },
    register: function (event) {
      router.push({ name: "register" });
    },
  },
  beforeMount: function () {
    if (localStorage.getItem("access_token")) {
      router.push({ name: "home" });
    }
  },
};
</script>
