<template>
  <div v-if="notCommunity" class="open-community">
    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="pa-0">@{{ $route.params.group_id }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-card
      class="mx-auto py-5"
      max-width="750"
      flat
    >
      <v-row
        align="center"
        justify="center"
        class="wrapper ma-0"
      >
        <v-col style="max-width: fit-content;">
          <span class="body-2">コミュニティが存在しません</span>
        </v-col>
      </v-row>
    </v-card>
  </div>
  <div v-else class="open-community">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-icon
          v-if="selectedCommunity.kind >= 1"
          size="20" class="mr-1"
          :color="selectedCommunity.kind === 1 ? '' : 'primary'"
        >fa-lock</v-icon>{{ selectedCommunity.group }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectedCommunity.group_id && userData.id"
        text icon
        @click.stop="showSheet(null, null, selectedCommunity.group_id)"
      >
        <v-icon>fa-ellipsis-v</v-icon>
      </v-btn>
    </v-app-bar>

    <VirtualScroll
      key-field="post_id"
      :items="posts"
      :min-item-height="92"
      :minScrollerHeight="minScrollerHeight"
      :buffer="minScrollerHeight*2"
      :paddingBottom="$_domHeights.bottomNavigation"
      :onRefresh="onRefresh"
      :loadMore="loadMore"
      :noMoreItems="noMorePosts"
    >
      <template v-slot:top>
        <div v-if="selectedCommunity.group_id" class="community-box">
          <v-img
            :aspect-ratio="2.5/1"
            :src="selectedCommunity.top_url || ''"
            class="grey lighten-2"
            @click.stop="!selectedCommunity.top_url || setImages([selectedCommunity.top_url], 0)"
          ></v-img>
          <v-card
            class="mx-auto"
            max-width="750"
            flat
          >
            <v-row class="avatar-row ma-0">
              <v-avatar size="80" color="grey lighten-4">
                <v-img
                  v-if="selectedCommunity.icon_url"
                  :src="selectedCommunity.icon_url"
                  @click.stop="!selectedCommunity.icon_url || setImages([selectedCommunity.icon_url], 0)"
                  v-on:error="$_notImage(selectedCommunity, 'icon_url')"
                ></v-img>
                <v-icon v-else x-large>fa-users</v-icon>
                <v-icon v-if="selectedCommunity.admin.user_id === userData.id" size="30" class="is-admin-icon">fa-cog</v-icon>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-col v-if="!!action.id" class="text-right">
                <v-btn
                  v-if="selectedCommunity.admin.user_id !== userData.id"
                  :outlined="[null, 1].includes(selectedCommunity.status)"
                  :depressed="[0, 2].includes(selectedCommunity.status)"
                  color="primary"
                  @click.stop="userAction(action.id, { group_id: selectedCommunity.group_id, name: selectedCommunity.group })"
                >
                  <span class="join-button">{{ action.title }}</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0">
                <v-card-title class="py-0">
                  <div class="name-id-box">
                    <v-row class="headline font-weight-bold ma-0">
                      <v-icon
                        v-if="selectedCommunity.kind >= 1"
                        class="mr-2"
                        :color="selectedCommunity.kind === 1 ? '' : 'primary'"
                      >fa-lock</v-icon>{{ selectedCommunity.group }}
                    </v-row>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-row class="mx-0 my-1">
                    <v-col class="pa-0" @click="openUserList('community', selectedCommunity.group_id, 'member')">
                      <span class="font-weight-bold mr-1">{{ changeNumFormat(selectedCommunity.member_sum) }}</span>メンバー
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-chip
                      v-for="tag in selectedCommunity.tag"
                      small outlined class="mr-1 my-1"
                      @click.stop="doSearch(tag.tag)"
                    >
                      <p class="ma-0">{{ tag.tag }}</p>
                    </v-chip>
                  </v-row>
                  <v-row class="ma-0">
                    <p>{{ selectedCommunity.description }}</p>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <v-divider></v-divider>
        <v-card
          v-if="(posts.length === 0 && noMorePosts) || isMobile"
          max-width="750" flat class="mx-auto py-5"
        >
          <v-row
            v-if="isMobile"
            align="center" justify="center" class="wrapper ma-0"
          >
            <v-col style="max-width: fit-content;">
              <v-btn
                text color="secondary" class="px-5"
                @click="onRefresh"
              >
                更新
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-else
            align="center" justify="center" class="wrapper ma-0"
          >
            <v-col style="max-width: fit-content;">
              下げて更新
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template v-slot="{ item }">
        <CommunityPostBox
          :key="item.post_id"
          :post="item"
          :_sharePost="sharePost"
          :_showSheet="showSheet"
          :_setImages="setImages"
        ></CommunityPostBox>
      </template>
      <template v-slot:bottom>
        <div style="padding-bottom: 88px;"></div>
      </template>
    </VirtualScroll>

    <v-btn
      fab fixed right dark color="primary"
      class="bottom-fab-button"
      :style="{ bottom: `${$_bottomFabSpace}px` }"
      @click="postDialog = true"
    >
      <v-icon>fas fa-plus</v-icon>
    </v-btn>

    <BottomSheet
      :sheet="bottomSheet" :tiles="selectedSheetTiles" :ids="{ ...selectedIds, group_id: selectedCommunity.group_id }"
      :action="userAction"
    ></BottomSheet>

    <v-dialog
      v-model="postDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="postDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :disabled="!canSendPost"
            :loading="postLoading"
            @click="sendPost"
          >
            投稿
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5 pb-5">
          <v-textarea
            placeholder="コミュニティに投稿しよう！"
            v-model="newPost"
            :rules="[rules.max150]"
            auto-grow
            counter
            @keyup.ctrl.enter.exact="sendPost"
          ></v-textarea>

          <v-file-input
            ref="getImages"
            prepend-icon="fa-images"
            label="画像を選択"
            type="file"
            multiple
            :clearable="false"
            accept="image/png, image/jpeg, image/gif, image/heic"
            @change="onFileChange($event, 'post')"
          ></v-file-input>

          <v-row>
            <v-col v-for="img in selectedPostImages.base64" class="pa-1">
              <v-card flat>
                <v-img
                  :src="img"
                  aspect-ratio="1"
                  class="ml-0 grey lighten-2"
                  max-width="100"
                  max-height="100"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="settingsDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="settingsDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :loading="saveLoading"
            :disabled="!canSaveSettings"
            @click="saveSettings"
          >
            保存
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="community-settings-box">
            <v-card flat>
              <div>
                <clipper-upload
                  ref="uploadTop"
                  v-model="selectedSettingsImages.top.originalBlob"
                  style="position: absolute;z-index: 1;width: 100%;height: 120px;"
                  accept="image/png, image/jpeg, image/gif, image/heic"
                ></clipper-upload>
                <div style="width: 100%;">
                  <v-img
                    :src="compressing.top ? '' : (selectedSettingsImages.top.blob || selectedCommunity.top_url || '')"
                    :aspect-ratio="2.5/1"
                    class="mx-auto grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-icon v-if="!compressing.top" large style="position: absolute; width: 100%;height: 100%;">fa-image</v-icon>
                    </template>
                  </v-img>
                </div>
              </div>
              <v-row class="avatar-row ma-0">
                <v-avatar size="80" color="grey lighten-4">
                  <clipper-upload
                    ref="uploadIcon"
                    v-model="selectedSettingsImages.icon.originalBlob"
                    style="position: absolute;z-index: 1;width: 100px;height: 100px;"
                    accept="image/png, image/jpeg, image/gif, image/heic"
                  ></clipper-upload>
                  <v-progress-circular
                    v-if="compressing.icon"
                    indeterminate
                    color="grey darken-1"
                    size="28" width="2"
                    style="position: absolute;"
                  ></v-progress-circular>
                  <v-img
                    v-else-if="selectedSettingsImages.icon.blob || selectedCommunity.icon_url"
                    :src="selectedSettingsImages.icon.blob || selectedCommunity.icon_url || ''"
                  ></v-img>
                  <v-icon v-else large style="position: absolute;">fa-image</v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
              </v-row>
              <v-card-text class="px-5 pb-0">
                <v-text-field
                  class="mb-2"
                  type="text"
                  prepend-icon="fa-users"
                  v-model="group"
                  :rules="[rules.max50]"
                  label="コミュニティ名"
                  clear-icon="fa-times"
                  clearable
                  required
                ></v-text-field>

                <v-textarea
                  v-model="description"
                  label="コミュニティ紹介"
                  :rules="[rules.max150]"
                  prepend-icon="fa-comment-dots"
                  auto-grow
                  counter
                ></v-textarea>

                <v-select
                  v-model="kind"
                  required
                  :items="kindList"
                  label="種類"
                  prepend-icon="fa-lock"
                  single-line
                ></v-select>

                <v-combobox
                  v-model="tags"
                  small-chips
                  label="タグ"
                  multiple
                  prepend-icon="fa-tags"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      :input-value="selected"
                      small close
                      @click:close="removeTag(item)"
                    >
                      <span>{{ item }}</span>
                    </v-chip>
                  </template>
                </v-combobox>

                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="changeOwner">管理者を変更する</v-btn>
                  <v-spacer></v-spacer>
                </v-row>

                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="deleteCommunity">コミュニティを削除する</v-btn>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="clipImageDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="clipImageDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :loading="clipLoading"
            @click="clipImage(clipMode)"
          >
            切り取り
          </v-btn>
        </v-card-title>
        <v-card-text>
          <clipper-fixed
            v-if="clipMode === 'icon'"
            :src="selectedSettingsImages.icon.originalBlob"
            round ref="imageClipper"
            :area="70"
          >
            <div slot="placeholder">画像がありません</div>
          </clipper-fixed>
          <clipper-fixed
            v-if="clipMode === 'top'"
            :src="selectedSettingsImages.top.originalBlob"
            :ratio='2.5/1' ref="imageClipper"
            :area="70"
          >
            <div slot="placeholder">画像がありません</div>
          </clipper-fixed>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-gallery :images="selectedImages"
      :index="imageIndex"
      :options="{ continuous: false }"
      @close="imageIndex = null" />
  </div>
</template>

<style lang="scss">
.open-community {
  .community-box {
    .v-card {
      .avatar-row {
        min-height: 60px;

        .v-avatar {
          position: absolute;
          top: -30px;
          left: 12px;

          .v-image {
            border: solid 3px #fff;
          }
          .is-admin-icon {
            position: absolute;
            margin-top: 30px;
            margin-left: 30px;
          }
        }
        .join-button {
          width: 5rem;
        }
      }
      p {
        overflow: hidden;
        white-space: pre-wrap;
      }
      .v-chip {
        height: auto;
      }
    }
  }
}
</style>

<script>
import imageAxios from '../axios/image';
import axios from '../axios/index';
import router from '../router';

export default {
  name: 'open-community',
  data: () => ({
    rules: {
      max50: (v) => !v || v.length <= 50 || '50文字以内で入力してください',
      max150: (v) => !v || v.length <= 150 || '150文字以内で入力してください',
    },
    postDialog: false,
    postLoading: false,
    newPost: '',
    clipImageDialog: false,
    clipMode: '',
    clipLoading: false,
    notCommunity: false,
    isMobile: window.innerWidth >= 600 ? true : false,
    fullscreen: window.innerWidth >= 1264 ? false : true,
    settingsDialog: false,
    saveLoading: false,
    group: '',
    description: '',
    tags: [],
    kind: '',
    kindList: [
      { text: '公開', value: 1 },
      { text: '申請許可制', value: 2 },
      { text: '招待制', value: 3 }
    ],
    minScrollerHeight: 0,
    selectedCommunity: {},
    posts: [],
    noMorePosts: false,
  }),
  computed: {
    canSendPost: function() {
      if (this.newPost && this.newPost.length <= 150) {
        return true;
      }
      return false;
    },
    canSaveSettings() {
      let oldTag = (this.selectedCommunity.tag || []).map(obj => obj.tag);
      let newTag = this.tags;
      let diff = [...oldTag.filter(item => !newTag.includes(item)), ...newTag.filter(item => !oldTag.includes(item))]
      if (this.group !== this.selectedCommunity.group ||
          this.description !== this.selectedCommunity.description ||
          this.kind - 1 !== this.selectedCommunity.kind ||
          this.selectedSettingsImages.icon.binary ||
          this.selectedSettingsImages.top.binary || diff.length) {
        return true;
      }
      return false;
    },
    action() {
      let id, title;
      let status = this.selectedCommunity.status;
      if (this.selectedCommunity.kind === 0) {
        id = status === 0 ? 'leave' : 'join';
        title = status === 0 ? '参加中' : '参加';
      } else if (this.selectedCommunity.kind === 1) {
        id = [0, 2].includes(status) ? 'leave' : 'join';
        if (status === null) {
          title = '参加申請';
        } else {
          if (status === 0) {
            title = '参加中';
          } else if (status === 2) {
            title = '申請取消';
          }
        }
      } else if ([0, 1].includes(status)) {
        id = status === 0 ? 'leave' : 'join';
        title = status === 0 ? '参加中' : '参加';
      }

      return {
        id,
        title
      };
    }
  },
  watch: {
    tags(val) {
      let lastTag = val.slice(-1)[0];
      if (val.length > 10 || lastTag.length > 50) {
        this.snackbarColor = 'error'; this.snackbarText = 'タグは50文字以下、10個以内で入力してください。'; this.snackbar = true;
        this.tags.pop();
      } else if (!!lastTag.match(/[ ,^]/)) {
        this.tags.pop();
        !lastTag.replace(/[ ,^]/g, '') || this.tags.push(lastTag.replace(/[ ,^]/g, ''));
      }
    },
    async 'selectedSettingsImages.icon.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.icon = true;
      let icon = this.selectedSettingsImages.icon;
      let file = await this.compressFile(this.$refs.uploadIcon.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'icon';
        this.clipImageDialog = true;
      }
      icon.blob = val;
      icon.binary = file;
      this.compressing.icon = false;
    },
    async 'selectedSettingsImages.top.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.top = true;
      let top = this.selectedSettingsImages.top;
      let file = await this.compressFile(this.$refs.uploadTop.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'top';
        this.clipImageDialog = true;
      }
      top.blob = val;
      top.binary = file;
      this.compressing.top = false;
    },
    postDialog() {
      this.newPost = '';
      this.postLoading = false;
    },
    settingsDialog() {
      this.group = this.selectedCommunity.group;
      this.description = this.selectedCommunity.description;
      this.kind = this.selectedCommunity.kind + 1;
      this.tags = this.selectedCommunity.tag.map(obj => obj.tag);
      this.selectedSettingsImages = {
        icon: {
          originalBlob: null,
          blob: null,
          binary: null
        },
        top: {
          originalBlob: null,
          blob: null,
          binary: null
        }
      };
      this.saveLoading = false;
    }
  },
  methods: {
    changeOwner() {
      this.openUserList('community', this.selectedCommunity.group_id, 'change-owner');
    },
    deleteCommunity() {
      if (confirm('この操作は取り消せません。\nよろしいですか？')) {
        let self = this;
        axios
          .delete('/group/delete', {
            data: {
              group_id: self.selectedCommunity.group_id
            }
          })
          .then(res => {
            if (res.status !== 200) {
              throw new Error();
            }
            self.notCommunity = true;
          })
          .catch(e => {
            console.log(e);
            self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
          })
      }
    },
    removeTag(item) {
      this.tags.splice(this.tags.indexOf(item), 1)
      this.tags = [...this.tags]
    },
    doSearch(tag) {
      router.push({
        name: 'communities-search',
        query: { word: tag, tag: true }
      });
    },
    setCommunity() {
      let self = this;
      axios
        .get('/group/detail/', {
          params: {
            group_id: self.$route.params.group_id
          }
        })
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          self.selectedCommunity = res.data;
          console.log(self.selectedCommunity);
        })
        .catch(e => {
          self.notCommunity = true;
          console.log(e);
        })
    },
    async onRefresh() {
      let posts = this.posts;
      let params = {
        group_id: this.$route.params.group_id,
      };
      if (posts.length) {
        params.top_id = posts[0].post_id;
      }
      await axios
        .get('/group/tl', { params })
        .then(res => {
          posts.unshift(...res.data.posts);
        })
        .catch(async e => {
          console.log(e);
          await this.sleep(500);
        });
    },
    async loadMore() {
      let posts = this.posts;
      let params = {
        group_id: this.$route.params.group_id,
      };
      if (posts.length) {
        params.bottom_id = posts.slice(-1)[0].post_id;
      }
      await axios
        .get('/group/tl', {
          params,
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          if (res.status !== 200) {
            this.noMorePosts = true;
            throw new Error();
          }
          posts.push(...res.data.posts);
        })
        .catch(e => {
          console.log(e);
        });
    },
    clipImage(mode) {
      this.clipLoading = true;
      let self = this;
      let image = mode === 'icon' ? this.selectedSettingsImages.icon : this.selectedSettingsImages.top;
      let canvas = this.$refs.imageClipper.clip();
      canvas.toBlob(async blob => {
        image.blob = URL.createObjectURL(blob);
        let file = new File([blob], new Date() + '.png', { type: 'image/png' });
        image.binary = await self.compressFile(file);
        console.log(image.binary);
        this.clipLoading = false;
        self.clipImageDialog = false;
      });
    },
    async saveSettings() {
      this.saveLoading = true;
      let self = this;
      let result = 0;
      try {
        if (this.selectedSettingsImages.icon.binary || this.selectedSettingsImages.top.binary) {
          let params = new FormData();
          params.append('group_id', this.selectedCommunity.group_id);
          params.append('icon_image', this.selectedSettingsImages.icon.binary);
          params.append('top_image', this.selectedSettingsImages.top.binary);
          await imageAxios
            .post('/group/manage/image', params)
            .then(res => {
              console.log(res);
              if (res.status !== 200) {
                throw new Error();
              }
              if (self.selectedSettingsImages.icon.binary) {
                self.selectedCommunity.icon_url = self.selectedSettingsImages.icon.blob;
              }
              if (self.selectedSettingsImages.top.binary) {
                self.selectedCommunity.top_url = self.selectedSettingsImages.top.blob;
              }
              result++;
            })
        }
        let oldTag = this.selectedCommunity.tag.map(obj => obj.tag);
        let newTag = this.tags;
        let diff;
        if (diff = oldTag.filter(item => !newTag.includes(item))) {
          await axios
            .post('/group/manage/tag/del', {
              group_id: this.selectedCommunity.group_id,
              tag: String(diff)
            })
            .then(res => {
              if (res.status !== 200) {
                throw new Error();
              }
              self.selectedCommunity.tag = newTag.map(item => {
                return {tag: item}
              });
            })
        }
        if (diff = newTag.filter(item => !oldTag.includes(item))) {
          await axios
            .post('/group/manage/tag/add', {
              group_id: this.selectedCommunity.group_id,
              tag: String(diff)
            })
            .then(res => {
              if (res.status !== 200) {
                throw new Error();
              }
              self.selectedCommunity.tag = newTag.map(item => {
                return {tag: item}
              });
            })
        }
        await axios
          .patch('/group/manage', {
            group_id: self.selectedCommunity.group_id,
            group: self.group,
            description: self.description.replace(/\n{2,}/g, '\n\n'),
            kind: self.kind - 1
          })
          .then(res => {
            console.log(res);
            if (res.status !== 200) {
              throw new Error();
            }
            self.selectedCommunity.group = self.group;
            self.selectedCommunity.description = self.description;
            self.selectedCommunity.kind = self.kind - 1;
            result++;
          });
      } catch (e) {
        console.log(e);
        this.snackbarColor = 'error'; this.snackbarText = 'エラーが発生しました。'; this.snackbar = true;
      } finally {
        this.saveLoading = false;
        if (result) {
          this.settingsDialog = false;
        } else {
          this.snackbarColor = 'error'; this.snackbarText = 'エラーが発生しました。'; this.snackbar = true;
        }
      }
    },
    sendPost() {
      this.postLoading = true;
      let self = this;
      let params = new FormData();
      params.append('message', this.newPost.replace(/\n{2,}/g, '\n\n'));
      params.append('group_id', this.selectedCommunity.group_id);
      if (this.selectedPostImages.binary.length) {
        for (let i = 0; i < self.selectedPostImages.binary.length; i++) {
          params.append('file'+(i+1), self.selectedPostImages.binary[i]);
        }
      }
      imageAxios
        .post('/group/post', params)
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          self.postDialog = false;
        })
        .catch(e => {
          console.log(e);
          self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
        })
        .finally(() => {
          self.postLoading = false;
        });
    }
  },
  beforeMount: function() {
    this.setCommunity();
  },
  mounted: function() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
};
</script>
