<template>
  <div v-if="notUser" class="profile">
    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="pa-0">@{{ $route.params.user_id }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-card
      class="mx-auto py-5"
      max-width="750"
      flat
    >
      <v-row
        align="center"
        justify="center"
        class="wrapper ma-0"
      >
        <v-col style="max-width: fit-content;">
          <span class="body-2">ユーザが存在しません</span>
        </v-col>
      </v-row>
    </v-card>
  </div>
  <div v-else class="profile">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-flex align-center">
        <div>{{ selectedUser.name }}</div>
        <v-img
          v-if="selectedUser.name && selectedUser.is_official"
          src="/img/official_logo_256.png"
          width="22px" height="22px"
          class="ml-1"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectedUser.id && userData.id"
        text icon
        @click.stop="false && selectedUser.id === userData.id ? $router.push({ name: 'settings' }) : showSheet(null, selectedUser.id)"
      >
        <v-icon>{{ selectedUser.id === userData.id ? 'fa-cog' : 'fa-ellipsis-v' }}</v-icon>
      </v-btn>
    </v-app-bar>

    <VirtualScroll
      key-field="post_id"
      :items="posts"
      :min-item-height="92"
      :minScrollerHeight="minScrollerHeight"
      :buffer="minScrollerHeight*2"
      :paddingBottom="$_domHeights.bottomNavigation"
      :onRefresh="onRefresh"
      :loadMore="loadMore"
      :noMoreItems="noMorePosts"
    >
      <template v-slot:top>
        <div v-if="selectedUser.id" class="profile-box">
          <v-img
            :aspect-ratio="2.5/1"
            :src="selectedUser.top_link"
            class="grey lighten-2"
            @click.stop="!selectedUser.top_link || setImages([selectedUser.top_link], 0)"
          ></v-img>
          <v-card
            class="mx-auto"
            max-width="750"
            flat
          >
            <v-row class="avatar-row ma-0">
              <v-avatar size="80" color="grey lighten-4">
                <v-img
                  v-if="selectedUser.icon_link"
                  :src="selectedUser.icon_link"
                  @click.stop="!selectedUser.icon_link || setImages([selectedUser.icon_link], 0)"
                  v-on:error="$_notImage(selectedUser, 'icon_link')"
                ></v-img>
                <v-icon v-else x-large>fa-user</v-icon>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <v-btn
                  v-if="selectedUser.id !== userData.id"
                  :outlined="!selectedUser.is_follow && !selectedUser.is_block" :depressed="selectedUser.is_follow || selectedUser.is_block"
                  :color="selectedUser.is_block ? 'secondary' : 'primary'"
                  @click.stop="userAction((selectedUser.is_block ? 'block' : 'follow'), { user_id: selectedUser.id })"
                >
                  <span class="follow-button">{{ selectedUser.is_block ? 'ブロック中' : selectedUser.is_follow ? 'フォロー中' : 'フォロー' }}</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0">
                <v-card-title class="py-0">
                  <div class="name-id-box">
                    <v-row class="headline font-weight-bold ma-0">
                      <span class="profile-id-box__name">
                        {{ selectedUser.name }}
                        <v-img
                          v-if="selectedUser.name && selectedUser.is_official"
                          src="/img/official_logo_256.png"
                          width="28px" height="28px"
                          class="d-inline-block ml-2"
                        ></v-img>
                      </span>
                    </v-row>
                    <v-row class="subtitle-2 grey--text ma-0">@{{ selectedUser.id }}</v-row>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-row class="mx-0 my-1">
                    <v-col class="pa-0" @click="openUserList('user', selectedUser.id, 'follow')">
                      <span class="font-weight-bold mr-1">{{ changeNumFormat(selectedUser.follow) }}</span>フォロー
                    </v-col>
                    <v-col class="pa-0" @click="openUserList('user', selectedUser.id, 'follower')">
                      <span class="font-weight-bold mr-1">{{ changeNumFormat(selectedUser.follower) }}</span>フォロワー
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-chip
                      v-for="(id, i) in [selectedUser.belong, selectedUser.grade, selectedUser.prefecture]"
                      v-if="id" small outlined class="mr-1 my-1"
                    >
                      <p class="ma-0">{{ [belongs, grades, prefectures][i][id].text }}</p>
                    </v-chip>
                  </v-row>
                  <v-row class="ma-0">
                    <p>{{ selectedUser.description }}</p>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <v-divider></v-divider>
        <template v-if="selectedUser.is_block && !showPost">
          <v-card
            class="mx-auto py-5"
            max-width="750"
            flat
          >
            <v-row
              align="center"
              justify="center"
              class="wrapper ma-0"
            >
              <v-col style="max-width: fit-content;">
                <div class="text-center">ブロック中</div>
                <v-btn text color="primary" @click="showPost = true;noMorePosts = false;">投稿を表示</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
        <v-card
          v-else-if="(posts.length === 0 && noMorePosts) || isMobile"
          max-width="750" flat class="mx-auto py-5"
        >
          <v-row
            v-if="isMobile"
            align="center" justify="center" class="wrapper ma-0"
          >
            <v-col style="max-width: fit-content;">
              <v-btn
                text color="secondary" class="px-5"
                @click="onRefresh"
              >
                更新
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-else
            align="center" justify="center" class="wrapper ma-0"
          >
            <v-col style="max-width: fit-content;">
              下げて更新
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template v-slot="{ item }">
        <PostBox
          :key="item.post_id"
          :post="item"
          :_sharePost="sharePost"
          :_showSheet="showSheet"
          :_setImages="setImages"
        ></PostBox>
      </template>
      <template v-slot:bottom>
        <div style="padding-bottom: 88px;"></div>
      </template>
    </VirtualScroll>

    <BottomSheet
      :sheet="bottomSheet" :tiles="selectedSheetTiles" :ids="selectedIds"
      :action="userAction"
    ></BottomSheet>

    <v-dialog
      v-model="settingsDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-stepper v-model="step" class="mx-auto pa-0 elevation-0" style="height: 100%">
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-card>
              <v-card-title class="px-5">
                <v-btn icon @click="settingsDialog = false">
                  <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  class="primary--text"
                  :loading="saveLoading"
                  :disabled="!canSaveSettings"
                  @click="saveSettings"
                >
                  保存
                </v-btn>
              </v-card-title>
              <v-card-text class="pa-0 pb-5">
                <div class="profile-settings-box">
                  <v-card flat>
                    <div>
                      <clipper-upload
                        ref="uploadTop"
                        v-model="selectedSettingsImages.top.originalBlob"
                        style="position: absolute;z-index: 1;width: 100%;height: 120px;"
                        accept="image/png, image/jpeg, image/gif, image/heic"
                      ></clipper-upload>
                      <div style="width: 100%;">
                        <v-img
                          :src="compressing.top ? '' : (selectedSettingsImages.top.blob || selectedUser.top_link)"
                          :aspect-ratio="2.5/1"
                          class="mx-auto grey lighten-2"
                        >
                          <template v-slot:placeholder>
                            <v-icon v-if="!compressing.top" large style="position: absolute; width: 100%;height: 100%;">fa-image</v-icon>
                          </template>
                        </v-img>
                      </div>
                    </div>
                    <v-row class="avatar-row ma-0">
                      <v-avatar size="80" color="grey lighten-4">
                        <clipper-upload
                          ref="uploadIcon"
                          v-model="selectedSettingsImages.icon.originalBlob"
                          style="position: absolute;z-index: 1;width: 100px;height: 100px;"
                          accept="image/png, image/jpeg, image/gif, image/heic"
                        ></clipper-upload>
                        <v-progress-circular
                          v-if="compressing.icon"
                          indeterminate
                          color="grey darken-1"
                          size="28" width="2"
                          style="position: absolute;"
                        ></v-progress-circular>
                        <v-img
                          v-else-if="selectedSettingsImages.icon.blob || selectedUser.icon_link"
                          :src="selectedSettingsImages.icon.blob || selectedUser.icon_link"
                        ></v-img>
                        <v-icon v-else large style="position: absolute;">fa-image</v-icon>
                      </v-avatar>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <v-card-text class="pt-0">
                          <v-text-field
                            label="ユーザ名"
                            type="text"
                            :rules="[rules.max15]"
                            prepend-icon="fa-user"
                            v-model="username"
                            required
                          ></v-text-field>
                          <v-textarea
                            prepend-icon="fa-comment-dots"
                            v-model="description"
                            label="自己紹介"
                            :rules="[rules.max150]"
                            auto-grow
                            required
                            counter
                          ></v-textarea>
                          <v-select
                            prepend-icon="fa-school"
                            v-model="belong"
                            :items="belongs"
                            label="所属"
                          ></v-select>
                          <v-select
                            prepend-icon="fa-graduation-cap"
                            v-if="belong - 1"
                            v-model="grade"
                            :items="grades"
                            label="学年"
                          ></v-select>
                          <v-select
                            prepend-icon="fa-map-marker-alt"
                            v-model="prefecture"
                            :items="prefectures"
                            label="都道府県"
                          ></v-select>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <v-card>
              <v-card-title class="px-5">
                <v-btn icon @click="step = 1">
                  <v-icon>fa-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  class="primary--text"
                  :loading="clipLoading"
                  @click="clipImage(clipMode)"
                >
                  切り取り
                </v-btn>
              </v-card-title>
              <v-card-text class="pa-0 pb-5">
                <clipper-fixed
                  v-if="clipMode === 'icon'"
                  :src="selectedSettingsImages.icon.originalBlob"
                  round ref="imageClipper"
                  :area="70"
                >
                  <div slot="placeholder">画像がありません</div>
                </clipper-fixed>
                <clipper-fixed
                  v-if="clipMode === 'top'"
                  :src="selectedSettingsImages.top.originalBlob"
                  :ratio='2.5/1' ref="imageClipper"
                  :area="70"
                >
                  <div slot="placeholder">画像がありません</div>
                </clipper-fixed>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>

    <v-dialog
      v-model="quoteDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="quoteDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :disabled="!newPost"
            :loading="postLoading"
            @click="doShare(selectedIds.post_id, newPost)"
          >
            引用
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5 pb-5">
          <v-textarea
            placeholder="タイムラインで引用しよう！"
            v-model="newPost"
            :rules="[rules.max150]"
            auto-grow
            counter
            @keyup.ctrl.enter.exact="doShare(selectedIds.post_id, newPost)"
          ></v-textarea>

          <v-file-input
            prepend-icon="fa-images"
            label="画像を選択"
            type="file"
            multiple
            :clearable="false"
            accept="image/png, image/jpeg, image/gif"
            @change="onFileChange($event, 'post')"
          ></v-file-input>

          <v-row>
            <v-col v-for="img in selectedPostImages.base64" class="pa-1">
              <v-card flat>
                <v-img
                  :src="img"
                  aspect-ratio="1"
                  class="ml-0 grey lighten-2"
                  max-width="100"
                  max-height="100"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>

          <OriginBox :key="selectedIds.post_id" :origin="selectedIds.post_id"></OriginBox>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-gallery :images="selectedImages"
      :index="imageIndex"
      :options="{ continuous: false }"
      @close="imageIndex = null" />
  </div>
</template>

<style lang="scss">
.profile {
  .profile-id-box__name {
    position: relative;

    div {
      position: absolute;
    }
  }
  .profile-box {
    .v-card {
      .avatar-row {
        min-height: 60px;

        .v-avatar {
          position: absolute;
          top: -30px;
          left: 12px;

          .v-image {
            border: solid 3px #fff;
          }
        }
        .follow-button {
          width: 5rem;
        }
      }
      p {
        overflow: hidden;
        white-space: pre-wrap;
      }
    }
  }

  .end-post-bottom-spacer {
    margin-bottom: 92px;
  }
}
.profile-settings-box {
  .v-card {
    .avatar-row {
      min-height: 60px;

      .v-avatar {
        top: -30px;
        left: 12px;

        .v-image {
          border: solid 3px #fff;
        }
      }
    }
    .v-input:not(.v-textarea) {
      height: 70px;
    }
  }
}
</style>

<script>
import imageAxios from '../axios/image';
import axios from '../axios/index';
import router from '../router';
import OriginBox from '../components/OriginBox.vue';

export default {
  name: 'profile',
  components: {
    OriginBox
  },
  data: () => ({
    rules: {
      max15: (v) => !v || v.length <= 15 || '15文字以内で入力してください',
      max150: (v) => !v || v.length <= 150 || '150文字以内で入力してください',
    },
    step: 1,
    clipMode: '',
    clipLoading: false,
    notUser: false,
    showPost: true,
    isMobile: window.innerWidth >= 600 ? true : false,
    fullscreen: window.innerWidth >= 1264 ? false : true,
    settingsDialog: false,
    saveLoading: false,
    username: '',
    description: '',
    belong: null,
    grade: null,
    prefecture: null,
    minScrollerHeight: 0,
    selectedUser: {},
    posts: [],
    noMorePosts: false
  }),
  computed: {
    canSaveSettings() {
      if (this.username !== this.selectedUser.name ||
          this.description !== this.selectedUser.description ||
          this.belong !== this.selectedUser.belong + 1 ||
          this.grade !== this.selectedUser.grade + 1 ||
          this.prefecture !== this.selectedUser.prefecture + 1 ||
          this.selectedSettingsImages.icon.binary ||
          this.selectedSettingsImages.top.binary) {
        return true;
      }
      return false;
    }
  },
  watch: {
    'selectedUser.is_block'(val) {
      if (val) {
        this.showPost = false;
      }
    },
    async 'selectedSettingsImages.icon.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.icon = true;
      let icon = this.selectedSettingsImages.icon;
      let file = await this.compressFile(this.$refs.uploadIcon.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'icon';
        this.step = 2;
      }
      icon.blob = val;
      icon.binary = file;
      this.compressing.icon = false;
    },
    async 'selectedSettingsImages.top.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.top = true;
      let top = this.selectedSettingsImages.top;
      let file = await this.compressFile(this.$refs.uploadTop.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'top';
        this.step = 2;
      }
      top.blob = val;
      top.binary = file;
      this.compressing.top = false;
    },
    $route() {
      this.posts = [];
      this.setProfile();
      this.loadMore();
    },
    settingsDialog() {
      this.username = this.selectedUser.name;
      this.description = this.selectedUser.description;
      this.belong = this.selectedUser.belong + 1;
      this.grade = this.selectedUser.grade + 1;
      this.prefecture = this.selectedUser.prefecture + 1;
      this.selectedSettingsImages = {
        icon: {
          originalBlob: null,
          blob: null,
          binary: null
        },
        top: {
          originalBlob: null,
          blob: null,
          binary: null
        }
      };
      this.saveLoading = false;
    }
  },
  methods: {
    setProfile() {
      let self = this;
      axios
        .get('/accounts/user', {
          params: {
            id: self.$route.params.user_id
          }
        })
        .then(res => {
          console.log(res);
          if (res.status === 200) {
            self.selectedUser = res.data;
            self.selectedUser.id = self.$route.params.user_id;
          } else {
            throw new Error();
          }
        })
        .catch(e => {
          self.notUser = true;
          console.log(e);
        })
    },
    async onRefresh() {
      if (this.selectedUser.is_block === undefined) {
        return;
      }
      if (!this.showPost) {
        this.noMorePosts = true;
        return;
      }
      let posts = this.posts;
      let params = {
        user_id: this.$route.params.user_id
      };
      if (posts.length) {
        params.top_id = posts[0].post_id;
      }
      await axios
        .get('/post/tl', { params })
        .then(res => {
          posts.unshift(...res.data.posts);
        })
        .catch(async e => {
          console.log(e);
          await this.sleep(500);
        });
    },
    async loadMore() {
      if (this.selectedUser.is_block === undefined) {
        return;
      }
      if (!this.showPost) {
        this.noMorePosts = true;
        return;
      }
      let beforeCount = this.posts.length;
      await this.minLoadMore();
      let addCount = this.posts.length - beforeCount;
      for (let i = 0; i < 4 && addCount <= 100 && !this.noMorePosts; i++) {
        await this.sleep(500);
        await this.minLoadMore();
        addCount = this.posts.length - beforeCount;
      }
    },
    async minLoadMore() {
      let posts = this.posts;
      let params = {
        user_id: this.$route.params.user_id
      };
      if (posts.length) {
        params.bottom_id = posts.slice(-1)[0].post_id;
      }
      await axios
        .get('/post/tl', {
          params,
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          if (res.status === 404) {
            this.noMorePosts = true;
            throw new Error();
          }
          posts.push(...res.data.posts);
        })
        .catch(e => {
          console.log(e);
        });
    },
    clipImage(mode) {
      this.clipLoading = true;
      let self = this;
      let image = mode === 'icon' ? this.selectedSettingsImages.icon : this.selectedSettingsImages.top;
      let canvas = this.$refs.imageClipper.clip();
      canvas.toBlob(async blob => {
        image.blob = URL.createObjectURL(blob);
        let file = new File([blob], new Date() + '.png', { type: 'image/png' });
        image.binary = await self.compressFile(file);
        console.log(image.binary);
        this.clipLoading = false;
        self.step = 1;
      });
    },
    async saveSettings() {
      this.saveLoading = true;
      let self = this;
      let param, binary;
      let result = 0;
      try {
        if (binary = this.selectedSettingsImages.icon.binary) {
          param = new FormData();
          param.append('file', binary);
          await imageAxios
            .post('/users/upload_icon', param)
            .then(res => {
              console.log(res);
              if (res.status !== 201) {
                throw new Error();
              }
              self.selectedUser.icon_link = res.data.link;
              result++;
            })
        }
        if (binary = this.selectedSettingsImages.top.binary) {
          param = new FormData();
          param.append('file', binary);
          await imageAxios
            .post('/users/upload_top', param)
            .then(res => {
              if (res.status !== 201) {
                throw new Error();
              }
              self.selectedUser.top_link = res.data.link;
              result++;
            })
        }
        await axios
          .patch('/users/summary', {
            name: self.username,
            description: self.description.replace(/\n{2,}/g, '\n\n'),
            belong: self.belong - 1 || 0,
            grade: (self.belong - 1) ? self.grade - 1 : 0,
            prefecture: self.prefecture - 1 || 0
          })
          .then(res => {
            console.log(res);
            if (res.status !== 201) {
              throw new Error();
            }
            self.selectedUser.name = self.username;
            self.selectedUser.description = self.description;
            self.selectedUser.belong = self.belong - 1;
            self.selectedUser.grade = self.grade - 1;
            self.selectedUser.prefecture = self.prefecture - 1;
            result++;
          })
      } catch (e) {
        console.log(e);
        this.snackbarColor = 'error'; this.snackbarText = 'エラーが発生しました。'; this.snackbar = true;
      } finally {
        this.saveLoading = false;
        if (result) {
          this.settingsDialog = false;
        }
      }
    },
  },
  beforeMount: function() {
    this.setProfile();
  },
  mounted: function() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
};
</script>
