const defaultState = {
  ws: null,
  isConnected: false,
};

const state = Object.assign({}, defaultState);

const mutations = {
  RESET_WEBSOCKET(state) {
    if (state.ws) {
      state.ws.close();
    }
    Object.assign(state, defaultState);
    console.log("WEBSOCKET RESET");
  },
  DISCONNECT_WEBSOCKET(state) {
    try {
      state.ws.close();
      Object.assign(state, defaultState);
      console.log("WEBSOCKET DISCONNECT");
    } catch (e) {
      console.log("WEBSOCKET NOT OPEN");
    }
  },
  ON_OPEN_WEBSOCKET(state, ws) {
    state.ws = ws;
    state.isConnected = true;
    console.log("WEBSOCKET OPEN");
  },
  ON_CLOSE_WEBSOCKET(state) {
    state.isConnected = false;
    console.log("WEBSOCKET CLOSE");
  },
};

const actions = {
  resetWebSocket: ({ commit }) => {
    commit("RESET_WEBSOCKET");
  },
  connectWebSocket: ({ commit }, userId) => {
    const ws = new WebSocket(
      `${
        process.env.VUE_APP_WEBSOCKET_BASE_URL
      }/ws/chat/${userId}/?token=${localStorage.getItem("access_token")}`
    );
    ws.onopen = () => {
      commit("ON_OPEN_WEBSOCKET", ws);
    };
    ws.onclose = () => {
      commit("ON_CLOSE_WEBSOCKET");
    };
    return ws;
  },
  disconnectWebSocket: ({ commit }) => {
    commit("DISCONNECT_WEBSOCKET");
  },
};

export default {
  state,
  mutations,
  actions,
};
