<template>
  <v-card
    outlined
    class="origin-box mt-1 mx-auto"
    @click.stop="openPost(origin, share_id)"
  >
    <v-skeleton-loader
      v-if="!post.user_id && !notPost"
      class="mx-auto"
      type="list-item-avatar-two-line"
      transition='fade-transition'
    ></v-skeleton-loader>
    <v-row
      v-else-if="notPost"
      align="center"
      justify="center"
      class="wrapper ma-0"
    >
      <v-col class="body-2" style="max-width: fit-content;">
        指定した投稿が存在しません。
      </v-col>
    </v-row>
    <v-row v-else class="ma-0">
      <v-spacer></v-spacer>
      <v-col class="avatar-col pa-2 pr-0 text-center col-2">
        <v-avatar size="36" color="grey lighten-4" @click.stop="openProfile(post.user_id)">
          <img v-if="post.icon_link" :src="post.icon_link">
          <v-icon v-else small>fa-user</v-icon>
        </v-avatar>
      </v-col>
      <v-col class="py-2 px-0 col-10">
        <v-card-title class="py-0 px-2">
          <div class="name-id-box">
            <span class="subtitle-2 font-weight-bold">{{ post.name }}</span>
          </div>
          <v-spacer></v-spacer>
          <span class="subtitle-2">{{ getFromNow(post.time) }}</span>
        </v-card-title>
        <v-card-text class="pb-0 px-2">{{ post.message }}</v-card-text>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-card>
</template>

<style lang="scss">

</style>

<script>
import '../mixins/utility';
import axios from '../axios/index';

export default {
  name: 'origin-box',
  data: () => ({
    post: {},
    notPost: false
  }),
  props: ['origin', 'share_id'],
  beforeMount: async function() {
    await axios
      .get('/post/post', {
        params: {
          post_id: this.origin
        }
      })
      .then(res => {
        if (res.status === 200) {
          this.post = res.data;
        } else {
          throw new Error();
        }
      })
      .catch(e => {
        console.log(e);
        this.notPost = true;
      });
  }
}
</script>
