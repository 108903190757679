<template>
  <div class="open-post">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>投稿</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <VirtualScroll
      key-field="post_id"
      :items="comments"
      :min-item-height="92"
      :minScrollerHeight="minScrollerHeight"
      :buffer="minScrollerHeight*2"
      :paddingBottom="$_domHeights.bottomNavigation"
      :loadMore="loadMoreComments"
      :noMoreItems="noMoreComments"
    >
      <template v-slot:top>
        <template v-if="!notParent">
          <v-card
            v-if="!parent.post_id"
            class="mx-auto"
            max-width="750"
            flat
          >
            <v-row
              align="center"
              justify="center"
              class="wrapper ma-0"
            >
              <v-col style="max-width: fit-content;">
                <v-progress-circular
                  indeterminate
                  color="grey darken-1"
                  size="28" width="2"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
          <PostBox
            v-else
            :post="parent"
            :_sharePost="sharePost"
            :_showSheet="showSheet"
            :_setImages="setImages"
            :notPost="!parent.post_id"
            :showMessage="false"
          ></PostBox>
        </template>
        <template>
          <v-card
            v-if="!post.post_id"
            class="mx-auto"
            max-width="750"
            flat
          >
            <v-row
              align="center"
              justify="center"
              class="wrapper ma-0"
            >
              <v-col style="max-width: fit-content;">
                <v-progress-circular
                  indeterminate
                  color="grey darken-1"
                  size="28" width="2"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
          <PostBox
            v-else
            :post="post"
            :_sharePost="sharePost"
            :_showSheet="showSheet"
            :_setImages="setImages"
            :notPost="notPost"
            :largeMode="true"
          ></PostBox>
        </template>
      </template>
      <template v-slot="{ item }">
        <PostBox
          :key="item.post_id"
          :post="item"
          :_sharePost="sharePost"
          :_showSheet="showSheet"
          :_setImages="setImages"
          :showMessage="false"
        ></PostBox>
      </template>
      <template v-slot:bottom>
        <div style="padding-bottom: 88px;"></div>
      </template>
    </VirtualScroll>

    <v-card
      v-if="userData.id"
      class="comment-input"
      flat max-width="750"
      :style="{ bottom: `${$_domHeights.bottomNavigation}px` }"
    >
      <v-divider></v-divider>
      <v-card-text class="py-0">
        <v-row align="center">
          <v-col class="col-10 pr-0">
            <v-textarea
              placeholder="コメントを入力"
              v-model="newPost"
              auto-grow
              rows="1"
              hide-details
              class="pt-0"
              :disabled="!post.post_id || !post.message && post.origin"
              @keyup.ctrl.enter.exact="sendComment"
            ></v-textarea>
          </v-col>
          <v-col class="send-btn col-2 text-center">
            <v-btn
              icon large @click="sendComment"
              :disabled="!newPost || postLoading"
            >
              <v-icon small>fas fa-paper-plane</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <BottomSheet
      :sheet="bottomSheet" :tiles="selectedSheetTiles" :ids="selectedIds"
      :action="userAction"
    ></BottomSheet>

    <v-dialog
      v-model="quoteDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="quoteDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :disabled="!newPost"
            :loading="postLoading"
            @click="doShare(selectedIds.post_id, newPost)"
          >
            引用
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5 pb-0">
          <v-textarea
            placeholder="タイムラインで引用しよう！"
            v-model="newPost"
            :rules="[rules.max150]"
            auto-grow
            counter
            @keyup.ctrl.enter.exact="doShare(selectedIds.post_id, newPost)"
          ></v-textarea>

          <v-file-input
            prepend-icon="fa-images"
            label="画像を選択"
            type="file"
            multiple
            :clearable="false"
            accept="image/png, image/jpeg, image/gif"
            @change="onFileChange($event, 'post')"
          ></v-file-input>

          <v-row>
            <v-col v-for="img in selectedPostImages.base64" class="pa-1">
              <v-card flat>
                <v-img
                  :src="img"
                  aspect-ratio="1"
                  class="ml-0 grey lighten-2"
                  max-width="100"
                  max-height="100"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>

          <OriginBox :key="selectedIds.post_id" :origin="selectedIds.post_id"></OriginBox>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-gallery :images="selectedImages"
      :index="imageIndex"
      :options="{ continuous: false }"
      @close="imageIndex = null" />
  </div>
</template>

<style lang="scss">
.open-post {
  .comment-input {
    position: fixed;
    width: 100%;

    .send-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 60px;
    }
  }
}
</style>

<script>
import axios from '../axios/index';
import OriginBox from '../components/OriginBox.vue';

export default {
  name: 'open-post',
  data: () => ({
    rules: {
      max150: (v) => !v || v.length <= 150 || '150文字以内で入力してください',
    },
    fullscreen: window.innerWidth >= 1264 ? false : true,
    minScrollerHeight: 0,
    notPost: false,
    notParent: true,
    noMoreComments: true,
    parent: {},
    post: {},
    comments: [],
  }),
  components: {
    OriginBox
  },
  watch: {
    $route() {
      this.notPost = false;
      this.notParent = true;
      this.noMoreComments = true;
      this.comments = [];
      this.setPost();
    }
  },
  methods: {
    sendComment() {
      this.postLoading = true;
      axios
        .post('/post/post', {
          message: this.newPost,
          parent: this.post.post_id,
        })
        .then(res => {
          if (res.status !== 201) {
            throw new Error();
          }
          this.newPost = '';
          this.postLoading = false;
          this.notParent = true;
          this.setPost();
          this.refreshComments();
        })
        .catch(e => {
          console.log(e);
          this.postLoading = false;
          this.snackbarColor = 'error'; this.snackbarText = 'エラーが発生しました。'; this.snackbar = true;
        })
    },
    async setPost() {
      this.parent = {};
      await axios
        .get('/post/post', {
          params: {
            post_id: this.$route.params.post_id
          },
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 404) {
              this.notPost = true;
              this.notParent = true;
            }
            throw new Error();
          }
          this.post = res.data;
          this.post.post_id = this.$route.params.post_id;
          if (res.data.comments) {
            this.noMoreComments = false;
          }
        })
        .catch(e => {
          console.log(e);
        });
      if (this.post.parent) {
        this.notParent = false;
        await this.setParent();
      }
    },
    async setParent() {
      await axios
        .get('/post/post', {
          params: {
            post_id: this.post.parent
          },
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 404) {
              this.notParent = true;
            }
            throw new Error();
          }
          this.parent = res.data;
          this.parent.post_id = this.post.parent;
        })
        .catch(e => {
          console.log(e);
        });
    },
    async refreshComments() {
      let params = {
        post_id: this.$route.params.post_id
      };
      if (this.comments.length) {
        params.top_id = this.comments[0].post_id;
      }
      await axios
        .get('/post/comment', { params })
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          this.comments.unshift(...res.data.posts);
        })
        .catch(e => {
          console.log(e);
        })
    },
    async loadMoreComments() {
      let params = {
        post_id: this.$route.params.post_id
      };
      if (this.comments.length) {
        params.bottom_id = this.comments.slice(-1)[0].post_id;
      }
      await axios
        .get('/post/comment', {
          params,
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 404) {
              this.noMoreComments = true;
            }
            throw new Error();
          }
          this.comments.push(...res.data.posts);
        })
        .catch(e => {
          console.log(e);
        })
    }
  },
  beforeMount() {
    this.setPost();
  },
  mounted() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
}
</script>
