<template>
  <div class="notifications" v-scroll="onScroll">
    <v-snackbar
      :color="snackbarColor"
      v-model="snackbar"
      bottom
      :timeout="3000"
    >
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar
      app
      :hide-on-scroll="hideOnScroll"
      extended
      class="router-contents-bar"
      elevation="1"
    >
      <template>
        <v-avatar size="36" color="grey lighten-4" @click="openProfileDrawer">
          <v-img
            v-if="userData.icon_link"
            :src="userData.icon_link"
            v-on:error="$_notImage(userData, 'icon_url')"
          ></v-img>
          <v-icon v-else small>fa-user</v-icon>
        </v-avatar>
        <v-spacer></v-spacer>
        <v-toolbar-title>通知</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :to="{ name: 'settings' }">
          <v-icon>fa-cog</v-icon>
        </v-btn>
      </template>
      <template v-slot:extension>
        <v-tabs v-model="selectedTab" fixed-tabs>
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :disabled="tab.id === 'footprints'"
            :href="`#${tab.id}`"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item
        v-for="(tab, i) in tabs"
        v-if="tab.id !== 'footprints'"
        :key="i"
        :value="tab.id"
      >
        <template v-if="tab.id === 'notification'">
          <VirtualScroll
            :items="results[selectedTab]"
            :min-item-height="45"
            key-field="info_id"
            :minScrollerHeight="minScrollerHeight"
            :buffer="300"
            :paddingBottom="$_domHeights.bottomNavigation"
            :onRefresh="onRefresh"
            :loadMore="loadMore"
            :noMoreItems="noMoreResults"
          >
            <template v-slot:top>
              <v-card
                v-if="!results[selectedTab].length && noMoreResults"
                max-width="750"
                flat
                class="mx-auto py-5"
              >
                <v-row align="center" justify="center" class="wrapper ma-0">
                  <v-col style="max-width: fit-content"> 下げて更新 </v-col>
                </v-row>
              </v-card>
            </template>
            <template v-slot="{ item }">
              <NotificationBox :item="item"></NotificationBox>
            </template>
            <template v-slot:bottom>
              <div style="padding-bottom: 88px"></div>
            </template>
          </VirtualScroll>
        </template>
        <template v-else-if="tab.id === 'versions'">
          <template v-for="result in results.versions">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-2 font-weight-bold">
                  <v-row class="ma-0">
                    <span>{{ result.title }}</span>
                    <v-spacer></v-spacer>
                    <span>{{ getFromNow(result.time) }}</span>
                  </v-row>
                </v-list-item-title>
                <p class="ma-0 body-2">{{ result.body }}</p>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss">
.notifications {
}
</style>

<script>
import imageAxios from "../axios/image";
import axios from "../axios/index";
import router from "../router";

export default {
  name: "notifications",
  data: () => ({
    noMoreResults: false,
    hideOnScroll: false,
    minScrollerHeight: 0,
    tabs: [
      {
        id: "notification",
        title: "通知",
      },
      {
        id: "footprints",
        title: "足跡",
      },
      {
        id: "versions",
        title: "バージョン",
      },
    ],
    selectedTab: "notification",
    results: {
      notification: [],
      footprints: [],
      versions: [
        {
          title: "v1.2.6 β 更新情報",
          body: "DMでの画像の送信と表示に対応しました。",
          time: "2024/03/07 02:19",
        },
        {
          title: "v1.2.5 β 更新情報",
          body: "ウェブ版で公式マークの表示に対応しました。",
          time: "2021/06/24 20:01",
        },
        {
          title: "v1.2.4 β 更新情報",
          body: "DMのバグを修正しました。",
          time: "2021/04/12 00:12",
        },
        {
          title: "v1.2.3 β 更新情報",
          body: "ウェブ版でDMが動作しない問題を修正しました。",
          time: "2021/03/31 04:16",
        },
        {
          title: "v1.2.2 β 更新情報",
          body: "レイアウトを修正しました。",
          time: "2020/12/18 00:35",
        },
        {
          title: "v1.2.1 β 更新情報",
          body: "アプリ版のために一部機能を追加しました。",
          time: "2020/09/22 00:36",
        },
        {
          title: "v1.2.0 β 更新情報",
          body: "キャッシュに対応しオフラインでも使用可能になりました。",
          time: "2020/07/01 14:01",
        },
        {
          title: "v1.1.5 β 更新情報",
          body: "ログイン情報が消える問題の修正",
          time: "2020/05/21 20:43",
        },
        {
          title: "v1.1.4 β 更新情報",
          body: "画像関係の問題を修正しました。",
          time: "2020/04/30 0:11",
        },
        {
          title: "v1.1.3 β 更新情報",
          body: "コミュニティの投稿が存在しないときに無限ロードする問題を修正しました。",
          time: "2020/04/29 23:11",
        },
        {
          title: "v1.1.2 β 更新情報",
          body: "コミュニティの投稿内で画像が表示されない問題を修正しました。\n一部端末での表示を修正しました。",
          time: "2020/04/17 5:13",
        },
        {
          title: "v1.1.1 β 更新情報",
          body: "各ページのパフォーマンスを向上しました。\nその他バグの修正を行いました。",
          time: "2020/04/16 22:15",
        },
        {
          title: "v1.1.0 β 更新情報",
          body: "スクロールのパフォーマンスを向上しました。\n通知一覧を追加しました。\nコミュニティの画像関係のバグを修正しました。",
          time: "2020/03/18 0:01",
        },
        {
          title: "v1.0.1 β 更新情報",
          body: "ホームでいいねの更新が表示されないバグの修正。\niOSでリンクのコピーができないバグの修正。",
          time: "2020/02/04 3:05",
        },
        {
          title: "v1.0.0 β 更新情報",
          body: "ベータ版をリリースしました。一部機能を除いて使用することができます。",
          time: "2020/02/03 23:00",
        },
      ],
    },
  }),
  methods: {
    onScroll(e) {
      let scrollTop = e.target.scrollingElement.scrollTop;
      if (scrollTop <= 500) {
        this.hideOnScroll = false;
      } else {
        this.hideOnScroll = true;
      }
    },
    async onRefresh() {
      let results = this.results[this.selectedTab];
      let url = "/info/list_notification";
      let params = {};
      if (results.length) {
        params.top_id = results[0].info_id;
      }
      await axios
        .get(url, {
          params,
          validateStatus: (status) => {
            return status <= 500;
          },
        })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }
          results.unshift(...res.data[this.selectedTab]);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async loadMore() {
      let results = this.results[this.selectedTab];
      let url = "/info/list_notification";
      let params = {};
      if (results.length) {
        params.bottom_id = results.slice(-1)[0].info_id;
      }
      await axios
        .get(url, {
          params,
          validateStatus: (status) => {
            return status <= 500;
          },
        })
        .then((res) => {
          if (res.status !== 200) {
            this.noMoreResults = true;
            throw new Error();
          }
          results.push(...res.data[this.selectedTab]);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted: function () {
    this.minScrollerHeight =
      window.innerHeight -
      this.$_domHeights.appBar -
      this.$_domHeights.bottomNavigation;
  },
};
</script>
