
import axios from './axios/index';
import Vue from 'vue';
// @ts-ignore
import platform from 'platform';
// @ts-ignore
import fpsCalc from './plugins/FpsCalc';

export default Vue.extend({
  name: 'App',
  data: () => ({
    fpsCalculator: null,
    pageFps: 0,
    callFab: false,
    onKeyboard: false,
    topDrawer: false,
    profileDrawer: false,
    notAuthMenu: ['login', 'password-reset', 'register', 'help', 'term-of-use', 'privacy-policy'],
    notRootMenu: ['logout', 'search-results', 'open-post', 'open-message'],
    topDrawerTabs: [
      {
        title: 'ログイン',
        icon: 'fa-sign-in-alt',
        router: 'login',
      },
      {
        title: 'ホーム',
        icon: 'fa-home',
        router: 'home',
      },
      /*{
        title: 'ヘルプ',
        icon: 'fa-question-circle',
        router: 'help',
      },*/
      {
        title: '利用規約',
        icon: 'fa-file-alt',
        router: 'term-of-use',
      },
      {
        title: 'プライバシーポリシー',
        icon: 'fa-user-lock',
        router: 'privacy-policy',
      },
    ],
    profileDrawerTabs: [
      {
        title: 'プロフィール',
        icon: 'fa-user',
        router: 'profile',
        params: {},
      },
      {
        title: '設定',
        icon: 'fa-cog',
        router: 'settings',
      },
      /*{
        title: 'ヘルプ',
        icon: 'fa-question-circle',
        router: 'help',
      },*/
      {
        title: 'ログアウト',
        icon: 'fa-sign-out-alt',
        router: 'logout',
      },
    ],
    navigationTabs: [
      {
        path: 'home',
        icon: 'fa-home',
      }, {
        path: 'communities',
        icon: 'fa-users',
      }, {
        path: 'notifications',
        icon: 'fa-bell',
      }, {
        path: 'messages',
        icon: 'fa-envelope',
      },
    ],
  }),
  computed: {
    isAppMode() {
      // @ts-ignore
      return !(['term-of-use', 'privacy-policy'].includes(this.$route.name) && this.$route.query.mode === 'app');
    },
  },
  watch: {
    $route(to, from) {
      this.createPageTitle(to);
      this.topDrawer = false;
      this.profileDrawer = false;
      this.callFab = false;
    },
    profileDrawer(val) {
      this.profileDrawerTabs[0].params = {
        // @ts-ignore
        user_id: this.userData.id,
      };
      if (!val) {
        return;
      }
      const self = this;
      axios
        .get('/accounts/user', {
          params: {
            // @ts-ignore
            id: self.userData.id,
          },
        })
        .then(function(res) {
          if (res.status === 200) {
            // @ts-ignore
            self.$store.dispatch('doUpdateUser', {
              ...res.data,
              // @ts-ignore
              id: self.userData.id
            });
            // @ts-ignore
            self.myData.follow = res.data.follow; self.myData.follower = res.data.follower;
          } else {
            throw new Error();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  methods: {
    createPageTitle(to: any) {
      if (typeof to.meta.title !== 'undefined') {
        document.title = to.meta.title;
      } else {
        document.title = 'Piperia';
      }

      if (typeof to.meta.desc !== 'undefined') {
        document.querySelector('meta[property=\'description\']')!.setAttribute('content', to.meta.desc);
      } else if (document !== null) {
        document.querySelector('meta[property=\'description\']')!.setAttribute('content', '学生向けSNSのPiperiaです。');
      }
    },
  },
  beforeMount() {
    console.log(process.env.NODE_ENV);
    if (platform.name === 'Safari' && platform.version.split('.')[0] < 13) {
      document.addEventListener('touchstart', (event) => {
        console.log(event.touches.length);
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      }, { capture: true, passive: false });

      let lastTouch = 0;
      document.addEventListener('touchend', (event) => {
        const now = window.performance.now();
        if (now - lastTouch <= 500) {
          event.preventDefault();
        }
        lastTouch = now;
      }, { capture: true, passive: false });
    }

    if (false) {
      document.addEventListener('DOMFocusIn', (e) => {
        // @ts-ignore
        if (['text', 'textarea'].indexOf(e.target.type) !== -1) {
          this.onKeyboard = true;
        }
      }, false);
      document.addEventListener('DOMFocusOut', (e) => {
        // @ts-ignore
        if (['text', 'textarea'].indexOf(e.target.type) !== -1) {
          this.onKeyboard = false;
        }
      }, false);
    }
  },
  mounted() {
    this.createPageTitle(this.$route);
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // this.$vuetify.theme.dark = true;
      // this.$el.style.background = this.$vuetify.theme.themes.dark.background;
    } else {
      // this.$vuetify.theme.dark = false;
      // @ts-ignore
      this.$el.style.background = this.$vuetify.theme.themes.light.background;
    }
    // @ts-ignore
    this.fpsCalculator = setInterval(() => {
      this.pageFps = fpsCalc.get();
    }, 100);
  },
  destroyed() {
    // @ts-ignore
    clearInterval(this.fpsCalculator);
  },
});
