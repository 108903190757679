class FpsCalculator {
  constructor() {
    this._isRunning = false;
    this._beginTime = Date.now();
    this._prevTime = this._beginTime;
    this._frames = 0;
    this._fps = 0;
  }
  start() {
    if (this._isRunning) {
      return null;
    }

    this._beginTime = Date.now();
    this._prevTime = this._beginTime;
    this._frames = 0;

    this._isRunning = true;
    const loop = () => {
      if (!this._isRunning) {
        return null;
      }
      this._update();
      requestAnimationFrame(loop);
    }
    loop();
  }
  stop() {
    this._isRunning = false;
    this._frames = 0;
  }
  get() {
    return this._fps;
  }
  _update() {
    this._frames++;
    let prevTime = this._prevTime;
    let time = Date.now();

    if (time > prevTime + 100) {
      this._fps = Math.round((this._frames*1000/(time - prevTime))*10)/10;
      this._prevTime = time;
      this._frames = 0;
    }

    this._beginTime = time;
  }
}

const fpsCalc = new FpsCalculator();
fpsCalc.start();

export default {
  get() {
    return fpsCalc.get();
  }
}
