<template>
  <div class="open-message">
    <v-snackbar
      :color="snackbarColor"
      v-model="snackbar"
      bottom
      :timeout="3000"
    >
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-card v-if="target.username" flat>
          <v-card-title class="pa-0 ma-0">
            <v-row class="ma-0">
              <v-spacer></v-spacer>
              <div class="d-flex align-center">
                {{ target.username
                }}<v-img
                  v-if="target.name && target.is_official"
                  src="/img/official_logo_256.png"
                  width="22px"
                  height="22px"
                  class="d-inline-block ml-1"
                ></v-img>
              </div>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-title>
          <v-card-subtitle class="pa-0 ma-0">
            <v-row class="ma-0">
              <v-spacer></v-spacer>
              <span>@{{ $route.params.user_id }}</span>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon disabled>
        <v-icon>fa-phone</v-icon>
      </v-btn>
    </v-app-bar>

    <VirtualScroll
      key-field="post_id"
      :items="messages"
      :min-item-height="59"
      :minScrollerHeight="minScrollerHeight"
      :buffer="minScrollerHeight * 2"
      :paddingBottom="$_domHeights.bottomNavigation"
      :loadMore="loadMoreMessages"
      :noMoreItems="noMoreMessages"
      :loadTopMode="true"
    >
      <template v-slot="{ item }">
        <ChatBox
          :key="item.post_id"
          :message="item"
          :_setImages="setImages"
        ></ChatBox>
      </template>
      <template v-slot:bottom>
        <div style="padding-bottom: 60px"></div>
      </template>
    </VirtualScroll>

    <v-row v-if="selectedPostImages.base64.length" style="height: 200px" />

    <v-card
      class="container-message-manager"
      flat
      max-width="750"
      :style="{ bottom: `${$_domHeights.bottomNavigation}px` }"
    >
      <v-divider></v-divider>
      <v-card-text class="py-0">
        <v-row v-if="selectedPostImages.base64.length">
          <v-col v-for="img in selectedPostImages.base64" class="pa-1">
            <v-card flat>
              <v-img
                :src="img"
                aspect-ratio="1"
                class="ml-0 grey lighten-2"
                max-width="100"
                max-height="100"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="col-2">
            <v-file-input
              prepend-icon="fa-images"
              type="file"
              multiple
              hide-input
              :clearable="false"
              accept="image/png, image/jpeg, image/gif, image/heic"
              @change="onFileChange($event)"
            ></v-file-input>
          </v-col>
          <v-col class="col-8 pr-0">
            <v-textarea
              placeholder="メッセージを入力"
              v-model="newMessage"
              auto-grow
              rows="1"
              hide-details
              class="pt-0"
              @keyup.ctrl.enter.exact="sendMessage"
            ></v-textarea>
          </v-col>
          <v-col class="send-btn col-2 text-center">
            <v-btn
              icon
              large
              @click="sendMessage"
              :disabled="
                (!newMessage ||
                  newMessage
                    .replace(/ /g, '')
                    .replace(/　/g, '')
                    .replace(/\n/g, '') === '') &&
                !selectedPostImages.base64.length
              "
            >
              <v-icon small>fas fa-paper-plane</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-gallery
      :images="selectedImages"
      :index="imageIndex"
      :options="{ continuous: false }"
      @close="imageIndex = null"
    />
  </div>
</template>

<style lang="scss">
.open-message {
  .v-card {
    .v-card__title {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .container-message-manager {
    position: fixed;
    width: 100%;

    .send-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 60px;
    }
  }
}
</style>

<script>
import imageAxios from "../axios/image";
import axios from "../axios/index";
import router from "../router";

export default {
  name: "open-message",
  data: () => ({
    ws: null,
    target: {
      username: null,
      user_id: null,
    },
    room_id: null,
    noMoreMessages: false,
    newMessage: "",
    messages: [],
    minScrollerHeight: 0,
    isOpenLatestCall: false,
  }),
  watch: {
    async "ws.readyState"(state, beforeState) {
      if (beforeState === 1 && state !== 1) {
        for (const i = 0; i < 10; i++) {
          await this.$store.dispatch("resetWebSocket");
          await this.initWebSocket();
          if (this.ws.readyState === 1) {
            break;
          }
          await this.sleep(500);
        }
        console.log("RECONNECT WEBSOCKET");
      }
    },
  },
  methods: {
    resetImages() {
      this.selectedPostImages.base64.splice(
        0,
        this.selectedPostImages.base64.length
      );
      this.selectedPostImages.binary.splice(
        0,
        this.selectedPostImages.binary.length
      );
    },
    async uploadImages() {
      const params = new FormData();
      for (
        let i = 0;
        i < this.selectedPostImages.binary.slice(0, 4).length;
        i++
      ) {
        params.append("file" + (i + 1), this.selectedPostImages.binary[i]);
      }
      return imageAxios
        .post("/dm/upload_dm_image", params)
        .then((res) => {
          if (res.status !== 201) {
            throw new Error();
          }
          return res.data.ids;
        })
        .catch((e) => {
          console.log(e);
          this.snackbarColor = "error";
          this.snackbarText = "画像のアップロードに失敗しました。";
          this.snackbar = true;
          return null;
        });
    },
    async sendMessage() {
      if (this.ws.readyState !== 1) {
        await this.$store.dispatch("resetWebSocket");
        await this.initWebSocket();
        console.log("RECONNECT WEBSOCKET");
      }
      const time = Date.now();
      const date = this.changeDateFormat(time);
      const beforeDate = this.changeDateFormat(
        (this.messages.slice(-1)[0] || {}).time
      );
      const imageIds = this.selectedPostImages.binary.length
        ? await this.uploadImages()
        : [];
      if (imageIds === null) {
        return;
      }
      this.ws.send(
        JSON.stringify({
          message: this.newMessage,
          images: imageIds,
        })
      );
      this.messages.push({
        user_id: this.userData.id,
        myself: true,
        message: this.newMessage,
        images: [...this.selectedPostImages.base64.slice(0, 4)],
        time,
        is_call: false,
        showDate: date !== beforeDate,
      });
      this.newMessage = "";
      this.resetImages();
      this.toBottom();
    },
    async loadMoreMessages() {
      let params = {
        user_id: this.target.user_id,
      };
      if (!!this.messages[0]) {
        params.bottom_id = this.messages[0].post_id;
      }
      await axios
        .get("/dm/message", {
          params,
          validateStatus: (status) => {
            return status < 500;
          },
        })
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 404) {
              this.noMoreMessages = true;
            }
            throw new Error();
          }
          let getMessages = res.data.posts;
          let date = this.changeDateFormat(getMessages[0].time);
          let beforeDate;
          if (this.messages.length) {
            beforeDate = this.changeDateFormat(this.messages[0].time);
            if (date === beforeDate) {
              this.messages[0].showDate = false;
            }
          } else {
            beforeDate = this.changeDateFormat(getMessages[0].time);
          }
          let blankMessages = [];
          for (let i = 0; i <= getMessages.length; i++) {
            if (i === getMessages.length) {
              getMessages[i - 1].showDate = true;
              break;
            }
            if (
              !getMessages[i].is_call &&
              !getMessages[i].message &&
              !getMessages[i].images.length
            ) {
              blankMessages.push(i);
            }
            if (!!getMessages[i].is_call && this.isOpenLatestCall) {
              getMessages[i].is_finish_call = true;
            }
            if (!!getMessages[i].is_call) {
              this.isOpenLatestCall = true;
              if (!getMessages[i].is_finish_call) {
                this.room_id = getMessages[i].room_id;
              }
            }
            getMessages[i].myself =
              getMessages[i].user_id === this.userData.id ? true : false;
            date = this.changeDateFormat(getMessages[i].time);
            if (date !== beforeDate) {
              getMessages[i - 1].showDate = true;
              beforeDate = date;
            }
          }
          blankMessages.reverse();
          for (let i of blankMessages) {
            getMessages.splice(i, 1);
          }
          this.messages.unshift(...getMessages.reverse());
          // console.log(getMessages);
        })
        .catch(async (e) => {
          console.log(e);
          await this.sleep(500);
        });
    },
    toBottom() {
      this.$nextTick(() => {
        let element = document.documentElement;
        let bottom = element.scrollHeight - element.clientHeight;
        scrollTo({
          top: bottom,
          behavior: "smooth",
        });
      });
    },
    async initWebSocket() {
      this.ws = await this.$store.dispatch(
        "connectWebSocket",
        this.target.user_id
      );
      console.log(this.ws);
      this.ws.onmessage = (e) => {
        console.log(e);
        const data = JSON.parse(e.data);
        if (data.user_id !== this.target.user_id) {
          return;
        }
        this.ws.send(
          JSON.stringify({
            read: data.post_id,
          })
        );
        const date = this.changeDateFormat(data.time);
        const beforeDate = this.changeDateFormat(
          this.messages.slice(-1)[0].time
        );
        this.messages.push({
          user_id: data.user_id,
          myself: false,
          message: data.message,
          images: data.images,
          time: data.time,
          is_call: false,
          showDate: date !== beforeDate,
        });
      };
    },
  },
  async beforeMount() {
    this.target.user_id = this.$route.params.user_id;
    this.resetImages();
    axios
      .get("/accounts/user", {
        params: {
          id: this.target.user_id,
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }
        this.target = {
          ...res.data,
          username: res.data.name,
        };
      })
      .catch((e) => {
        console.log(e);
      });
  },
  async mounted() {
    this.minScrollerHeight =
      window.innerHeight -
      this.$_domHeights.appBar -
      this.$_domHeights.bottomNavigation;
    await this.initWebSocket();
  },
  beforeDestroy() {
    this.resetImages();
    this.$store.dispatch("resetWebSocket");
    axios
      .get("/dm/message", {
        params: {
          user_id: this.target.user_id,
        },
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
