import Vue from 'vue';

const state = {
  tab: '',
  postId: '',
};

const mutations = {
  setCommunityState: (state, payload) => {
    state.tab = payload.communityState.tab;
    state.postId = payload.communityState.postId;
  }
};

const actions = {
  doUpdateCommunityState: ({ commit }, communityState) => {
    commit('setCommunityState', { communityState });
  }
};

const getters = {
  communityState: state => {
    return {
      tab: state.tab,
      postId: state.postId
    };
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
