<template>
  <div class="open-community-post">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>投稿</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <VirtualScroll
      key-field="post_id"
      :items="comments"
      :min-item-height="92"
      :minScrollerHeight="minScrollerHeight"
      :buffer="minScrollerHeight*2"
      :paddingBottom="$_domHeights.bottomNavigation"
    >
      <template v-slot:top>
        <CommunityPostBox
          :key="post.post_id"
          :post="post"
          :_sharePost="sharePost"
          :_showSheet="showSheet"
          :_setImages="setImages"
          :notPost="notPost"
          :largeMode="true"
        ></CommunityPostBox>
      </template>
      <template v-slot="{ item }">
        <CommunityPostBox
          :key="item.post_id"
          :post="item"
          :_sharePost="sharePost"
          :_showSheet="showSheet"
          :_setImages="setImages"
        ></CommunityPostBox>
      </template>
      <template v-slot:bottom>
        <div style="padding-bottom: 88px;"></div>
      </template>
    </VirtualScroll>

    <v-card
      v-if="userData.id"
      class="comment-input"
      flat max-width="750"
      :style="{ bottom: `${$_domHeights.bottomNavigation}px` }"
    >
      <v-divider></v-divider>
      <v-card-text class="py-0">
        <v-row align="center">
          <v-col class="col-10 pr-0">
            <v-textarea
              placeholder="コメントを入力"
              v-model="newPost"
              auto-grow
              rows="1"
              hide-details
              class="pt-0"
              :disabled="notPost || !post.message && post.origin"
              @keyup.ctrl.enter.exact="sendComment"
            ></v-textarea>
          </v-col>
          <v-col class="send-btn col-2 text-center">
            <v-btn
              icon large @click="sendComment"
              :disabled="!newPost || postLoading"
            >
              <v-icon small>fas fa-paper-plane</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <BottomSheet
      :sheet="bottomSheet" :tiles="selectedSheetTiles" :ids="selectedIds"
      :action="userAction"
    ></BottomSheet>

    <v-gallery :images="selectedImages"
      :index="imageIndex"
      :options="{ continuous: false }"
      @close="imageIndex = null" />
  </div>
</template>

<style lang="scss">
.open-community-post {
  margin-bottom: calc(56px + 100px);

  @media (max-width: 370px) {
    .parent-box {
      zoom: 0.85;
    }
  }
  @media (min-width: 640px) {
    .parent-box .avatar-col {
      max-width: 10% !important;
    }
  }
  .parent-box {
    .avatar-col {
      max-width: 17%;
    }
    .name-id-box {
      line-height: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 50%;
    }
    .v-card__text {
      /* text-align: justify; */
      white-space: pre-wrap;
      /* 選択 */
      -ms-user-select: auto;
      -moz-user-select: auto;
      -khtml-user-select: auto;
      -webkit-user-select: auto;
      user-select: auto;
    }
    .v-ripple__container {
      display: none;
    }
  }

  .comment-input {
    position: fixed;
    width: 100%;

    .send-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 60px;
    }
  }
}
</style>

<script>
import axios from '../axios/index';

export default {
  name: 'open-community-post',
  data: () => ({
    rules: {
      max150: (v) => !v || v.length <= 150 || '150文字以内で入力してください',
    },
    notPost: false,
    post: {},
    comments: [],
    minScrollerHeight: 0
  }),
  watch: {
    $route() {
      this.notPost = false;
      this.comments = [];
      this.setPost();
    }
  },
  methods: {
    sendComment() {
      this.postLoading = true;
      axios
        .post('/group/comment', {
          message: this.newPost.replace(/\n{2,}/g, '\n\n'),
          post_id: this.post.post_id,
        })
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          this.newPost = '';
          this.postLoading = false;
          this.setPost();
        })
        .catch(e => {
          console.log(e);
          this.postLoading = false;
          this.snackbarColor = 'error'; this.snackbarText = 'エラーが発生しました。'; this.snackbar = true;
        })
    },
    setPost() {
      axios
        .get('/group/post', {
          params: {
            post_id: this.$route.params.post_id
          },
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          console.log(res);
          if (res.status !== 200) {
            this.notPost = true;
            throw new Error();
          }
          this.post = res.data;
          this.post.post_id = this.$route.params.post_id;
          this.comments = res.data.child
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  beforeMount() {
    this.setPost();
  },
  mounted() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
}
</script>
