<template>
  <div class="messages">
    <v-app-bar app elevation="1">
      <v-avatar size="36" color="grey lighten-4" @click="openProfileDrawer">
        <v-img v-if="userData.icon_link" :src="userData.icon_link"></v-img>
        <v-icon v-else small>fa-user</v-icon>
      </v-avatar>
      <v-spacer></v-spacer>
      <v-toolbar-title>DM</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon :to="{ name: 'settings' }">
        <v-icon>fa-cog</v-icon>
      </v-btn>
    </v-app-bar>

    <VirtualScroll
      key-field="post_id"
      :items="messages"
      :min-item-height="92"
      :minScrollerHeight="minScrollerHeight"
      :buffer="minScrollerHeight*2"
      :paddingBottom="$_domHeights.bottomNavigation"
      :onRefresh="setMessages"
      :loadMore="setMessages"
      :noMoreItems="noMoreMessages"
    >
      <template v-slot:top>
        <v-card
          v-if="noMoreMessages && (messages.length === 0 || !$_isMobile)"
          max-width="750" flat class="mx-auto py-5"
        >
          <v-row
            v-if="!$_isMobile"
            align="center" justify="center" class="wrapper ma-0"
          >
            <v-col style="max-width: fit-content;">
              <v-btn
                text color="secondary" class="px-5"
                @click="setMessages"
              >
                更新
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-else
            align="center" justify="center" class="wrapper ma-0"
          >
            <v-col style="max-width: fit-content;">
              下げて更新
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template v-slot="{ item }">
        <v-card
          class="message-box mx-auto"
          max-width="750"
          flat
          @click="openMessage(item.user_id)"
        >
          <v-row class="ma-0">
            <v-spacer></v-spacer>
            <v-col class="avatar-col pr-0 text-center col-2">
              <v-avatar color="grey lighten-4" @click.stop="openProfile(item.user_id)">
                <v-img v-if="item.icon_link" :src="item.icon_link"></v-img>
                <v-icon v-else>fa-user</v-icon>
              </v-avatar>
            </v-col>
            <v-col class="px-0 py-2 col-10">
              <v-card-title class="py-0">
                <div class="name-id-box">
                  <span class="subtitle-2 font-weight-bold">{{ item.name }}</span>
                  <span v-if="item.is_official" class="name-id-box__badge d-inline-block ml-1">
                    <v-img
                      src="/img/official_logo_256.png"
                      width="16px" height="16px"
                    ></v-img>
                  </span>
                  <span class="subtitle-2 grey--text ml-1">@{{ item.user_id }}</span>
                </div>
                <v-spacer></v-spacer>
                <span :class="(item.is_new ? 'primary--text' : '') + ' subtitle-2'">{{ getFromNow(item.time) }}</span>
              </v-card-title>
              <v-card-text class="pb-0">{{ !item.is_call ? item.message : !item.is_finish_call ? '通話を開始しました。' : '通話を終了しました。' }}</v-card-text>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </template>
      <template v-slot:bottom>
        <div style="padding-bottom: 88px;"></div>
      </template>
    </VirtualScroll>
  </div>
</template>

<style lang="scss">
.messages {
  @media (max-width: 370px) {
    .message-box {
      zoom: 0.85;
    }
  }
  @media (min-width: 640px) {
    .message-box .avatar-col {
      max-width: 10% !important;
    }
  }

  .message-box {
    .avatar-col {
      max-width: 17%;
    }
    .name-id-box {
      line-height: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 70%;

      .name-id-box__badge {
        position: relative;
        width: 16px;

        div {
          position: absolute;
          bottom: -1.5px;
        }
      }
    }
    .v-card__text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      /* text-align: justify; */
      white-space: pre-wrap;
    }
    .v-ripple__container {
      display: none;
    }
  }
  .message-box::before, .message-box::after {
    content: '';
    display: block;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
import axios from '../axios/index';

export default {
  name: 'messages',
  data: () => ({
    messages: [],
    minScrollerHeight: 0,
    noMoreMessages: false
  }),
  mounted() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  },
  methods: {
    async setMessages() {
      await axios
        .get('/dm/dm')
        .then(res => {
          console.log(res);
          if (res.status !== 200) {
            throw new Error();
          }
          this.messages = [];
          this.messages.push(...res.data.list);
          this.noMoreMessages = true;
        })
        .catch(e => {
          console.log(e);
        })
    }
  }
}
</script>
