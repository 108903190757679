<template>
  <div
    ref="virtualScroll"
    class="virtual-scroll"
  >
    <div
      ref="wrapper"
      class="virtual-scroll__wrapper"
      :style="{ height: `${totalHeight || minScrollerHeight}px` }"
    >
      <v-row
        ref="onRefresh"
        v-if="onRefresh && startIndex === 0"
        align="center" justify="center"
        class="virtual-scroll__on-refresh ma-0"
      >
        <v-col>
          <v-progress-circular
            indeterminate color="grey darken-1" size="28" width="2"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <div
        class="virtual-scroll__view"
        :style="{
          transform: `translateY(${topSpacerHeight}px)`,
          paddingBottom: `${paddingBottom}px`
        }"
      >
        <div
          ref="slotTop"
          v-if="startIndex === 0"
          class="virtual-scroll__slot-top"
        >
          <v-row
            v-if="loadTopMode && loadMore && !noMoreItems"
            align="center" justify="center"
            class="virtual-scroll__load-more ma-0"
          >
            <v-col
              v-if="canRefresh"
              v-intersect="loadMoreTop"
              style="position: absolute;top: 0px;"
            ></v-col>
            <v-col style="max-width: fit-content;">
              <v-progress-circular
                indeterminate color="grey darken-1" size="28" width="2"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <slot name="top"></slot>
        </div>
        <div
          ref="virtualItems"
          v-for="item in visibleItems"
          :key="item[keyField]"
          :data-id="item[keyField]"
        >
          <slot :item="item"></slot>
        </div>
        <template v-if="endIndex === items.length">
          <v-row
            v-if="!loadTopMode && loadMore && !noMoreItems"
            align="center" justify="center"
            class="virtual-scroll__load-more ma-0"
          >
            <v-col
              v-if="canLoadMore"
              v-intersect="_loadMore"
              style="position: absolute;top: 0px;"
            ></v-col>
            <v-col style="max-width: fit-content;">
              <v-progress-circular
                indeterminate color="grey darken-1" size="28" width="2"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <div
            ref="slotBottom"
            v-else class="virtual-scroll__slot-bottom"
          >
            <slot name="bottom"></slot>
          </div>
        </template>
      </div>
    </div>
    <div v-if="$_isEnvDevelopment" style="position:fixed;top:48px;padding:0.2rem;background:white;z-index:100;">
      <br>
      items: {{ items.length }}<br>
      totalHeight: {{ totalHeight }}<br>
      topSpacerHeight: {{ topSpacerHeight }}<br>
      visibleIndex: {{ startIndex }} ~ {{ endIndex }}
    </div>
  </div>
</template>

<style lang="scss">
.virtual-scroll {
  .virtual-scroll__on-refresh {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 0px;
    opacity: 0;
    z-index: 100;

    > div {
      max-width: fit-content;
      background: white;
      border: solid 1px grey;
      border-radius: 50%;
    }
  }
  .virtual-scroll__load-more {
    position: relative;
  }
}
</style>

<script>
export default {
  name: 'virtual-scroll',
  props: {
    items: {
      type: Array,
      required: true,
    },
    keyField: {
      type: String,
      default: 'id'
    },
    heightField: {
      type: String,
      default: 'height'
    },
    minItemHeight: {
      type: Number,
      required: true,
    },
    heights: {
      type: Object,
      default: () => ({})
    },
    minScrollerHeight: {
      type: Number
    },
    scrollerHeight: {
      type: Number,
      default: () => {
        return window.innerHeight;
      }
    },
    pageMode: {
      type: Boolean,
      default: true
    },
    buffer: {
      type: Number,
      default: 200
    },
    offset: {
      type: Number,
      default: 0
    },
    paddingBottom: {
      type: Number,
      default: 0
    },
    onRefresh: {
      type: Function
    },
    loadMore: {
      type: Function
    },
    noMoreItems: {
      type: Boolean,
      default: false
    },
    loadTopMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    remain: 0,
    visibleItems: [],
    itemHeights: {},
    slotHeights: {
      top: 0,
      bottom: 0
    },
    startIndex: 0,
    endIndex: 0,
    totalHeight: 0,
    topSpacerHeight: 0,
    oldTopItem: {
      id: null,
      index: null
    },
    canRefresh: true,
    canLoadMore: true,
    isFirstLoaded: false
  }),
  watch: {
    items(items) {
      if (!this.endIndex || items.length < this.remain) {
        this.endIndex = Math.min(this.remain, items.length);
      }
      this.updateVisibleItems(true);
    }
  },
  created() {
    this._scrollUpdateY = 0;
    this._halfPullHeight = 30;
    this._touchStartY = null;
  },
  mounted() {
    const scrollDom = this.pageMode ? window : this.$refs.virtualScroll;
    scrollDom.addEventListener('scroll', this.handleScroll, { passive: true });
    if (!this.pageMode) {
      this.$refs.virtualScroll.style.height = this.scrollerHeight + 'px';
      this.$refs.virtualScroll.style.overflow = 'scroll';
    }
    if (this.onRefresh) {
      this.$refs.virtualScroll.addEventListener('touchend', this.refreshTouchEnd, { passive: true });
      this.$refs.virtualScroll.addEventListener('touchmove', this.refreshTouchMove, { passive: false });
    }
    this.itemHeights = this.heights;
    this.calcRemain();
    if (this.items.length) {
      this.updateVisibleItems(false);
    }
  },
  beforeDestroy() {
    const scrollDom = this.pageMode ? window : this.$refs.virtualScroll;
    scrollDom.removeEventListener('scroll', this.handleScroll);
    this.$refs.virtualScroll.removeEventListener('touchend', this.refreshTouchEnd);
    this.$refs.virtualScroll.removeEventListener('touchmove', this.refreshTouchMove);
  },
  methods: {
    calcRemain() {
      let visibleHeight = this.scrollerHeight + this.buffer*2 + this.slotHeights.top + this.slotHeights.bottom;
      this.remain = Math.ceil(visibleHeight/this.minItemHeight);
    },
    async updateVisibleItems(changeItems) {
      const scrollDom = this.pageMode ? window : this.$refs.virtualScroll;
      const items = this.items;
      const itemHeights = this.itemHeights;
      const keyField = this.keyField;
      const heightField = this.heightField;
      if (!this.isFirstLoaded) {
        if (this.offset || this.loadTopMode) {
          let _totalHeight = 0;
          items.forEach(item => {
            _totalHeight += itemHeights[item[keyField]] || item[heightField] || this.minItemHeight;
          });
          _totalHeight += this.slotHeights.top + this.slotHeights.bottom;
          this.totalHeight = _totalHeight;
          for (let i = 0; this.$refs.wrapper.clientHeight < this.minItemHeight*items.length && i < 10; i++) {
            await this.sleep(500);
          }
          if (this.loadTopMode) {
            this.setIndex(items.length);
          } else {
            scrollDom.scrollTo(0, this.offset);
            if (Math.abs(window.scrollY - this.offset) > this.minItemHeight*3) {
              await this.sleep(500);
              scrollDom.scrollTo(0, this.offset);
            }
          }
        }
        this.isFirstLoaded = true;
        if (this.offset) {
          return;
        }
      }
      const oldTopItem = this.oldTopItem;
      if (oldTopItem.id) {
        oldTopItem.index = items.map(obj => obj[keyField]).indexOf(oldTopItem.id);
        this.startIndex = Math.max(oldTopItem.index - this.remain, 0);
      } else if (oldTopItem.id === null && !this.canRefresh) {
        oldTopItem.index = items.length;
        this.startIndex = Math.max(oldTopItem.index - this.remain, 0);
      }
      const startIndex = this.startIndex;
      const endIndex = this.endIndex = Math.min(startIndex + this.remain, items.length);
      this.visibleItems = items.slice(startIndex, endIndex);
      await this.$nextTick(() => {
        this.$refs.virtualItems.forEach((item, i) => {
          itemHeights[item.getAttribute('data-id')] = item.clientHeight;
        });
        if (startIndex === 0) {
          this.slotHeights.top = this.$refs.slotTop.clientHeight;
          this.calcRemain();
        }
        if (endIndex === items.length && this.noMoreItems) {
          this.slotHeights.bottom = this.$refs.slotBottom.clientHeight;
          this.calcRemain();
        }
      });
      // items watch
      if (changeItems) {
        if (oldTopItem.id || oldTopItem.id === null && !this.canRefresh) {  // onRefresh
          for (let i = 0; i < startIndex; i++) {
            itemHeights[items[i][keyField]] = undefined;
          }
          let unvisibleHeight = 0;
          for (let i = 0; i < startIndex; i++) {
            unvisibleHeight += itemHeights[items[i][keyField]] || items[i][heightField] || this.minItemHeight;
          }
          this.topSpacerHeight = unvisibleHeight;
          let scrollY = unvisibleHeight;
          for (let i = startIndex; i < oldTopItem.index; i++) {
            scrollY += itemHeights[items[i][keyField]] || items[i][heightField] || this.minItemHeight;
          }
          scrollDom.scrollTo(0, scrollY);
        } else {  // loadMore
          for (let i = 0; i < items.length - endIndex; i++) {
            itemHeights[items[endIndex + i][keyField]] = undefined;
          }
        }
      }
      let _totalHeight = 0;
      items.forEach(item => {
        _totalHeight += itemHeights[item[keyField]] || item[heightField] || this.minItemHeight;
      });
      _totalHeight += this.slotHeights.top + this.slotHeights.bottom;
      this.totalHeight = _totalHeight;
    },
    async handleScroll(e) {
      if (!this.items.length || !this.isFirstLoaded) {
        return;
      }
      const scrollDom = this.pageMode ? e.target.scrollingElement : e.target;
      const scrollTop = scrollDom.scrollTop;

      const isScrollUp = this._scrollUpdateY >= scrollTop;
      const checkIndex = isScrollUp ? this.startIndex : this.endIndex;
      const checkScrollY = isScrollUp ? (scrollTop - this.buffer/2) : (scrollTop + this.minScrollerHeight + this.buffer/2);
      let count = 0;
      let checkIndexY = 0;
      for (; count < checkIndex; count++) {
        checkIndexY += this.itemHeights[this.items[count][this.keyField]] || this.items[count][this.heightField] || this.minItemHeight;
      }
      if (count > 0) {
        checkIndexY += this.slotHeights.top;
      }
      if ((isScrollUp && checkIndexY <= checkScrollY) || (!isScrollUp && checkIndexY >= checkScrollY)) {
        return;
      }
      this._scrollUpdateY = scrollTop;
      count = 0;
      let unvisibleHeight = 0;
      for (; unvisibleHeight < (scrollTop - this.buffer - this.slotHeights.top); count++) {
        unvisibleHeight += this.itemHeights[this.items[count][this.keyField]] || this.items[count][this.heightField] || this.minItemHeight;
      }
      if (count > 0) {
        unvisibleHeight += this.slotHeights.top;
      }
      this.startIndex = Math.max(count, 0);
      this.topSpacerHeight = unvisibleHeight;
      await this.updateVisibleItems(false);
    },
    setIndex(index, beforeScrollY) {
      const scrollDom = this.pageMode ? document.scrollingElement : this.$refs.virtualScroll;
      let scrollY = 0;
      if (beforeScrollY) {
        scrollY = scrollDom.scrollTop + beforeScrollY;
      } else {
        for (let i = 0; i < index ; i++) {
          scrollY += this.itemHeights[this.items[i][this.keyField]] || this.items[i][this.heightField] || this.minItemHeight;
        }
        scrollY += this.slotHeights.top;
      }
      scrollDom.scrollTo(0, scrollY || this.slotHeights.top);
      setTimeout(() => {
        for (let i = 0; i < index ; i++) {
          scrollY -= this.itemHeights[this.items[i][this.keyField]] || this.items[i][this.heightField] || this.minItemHeight;
        }
        scrollY -= this.slotHeights.top;
        if (Math.abs(scrollY) >= this.minItemHeight/2) {
          this.setIndex(index, -scrollY);
        }
      }, 50);
    },
    async _loadMore(entries, observer) {
      if (entries[0].isIntersecting && this.canLoadMore) {
        this.canLoadMore = false;
        await this.loadMore();
        await this.sleep(200);
        this.canLoadMore = true;
      }
    },
    async loadMoreTop(entries, observer) {
      if (entries[0].isIntersecting && this.canRefresh) {
        this.canRefresh = false;
        this.oldTopItem.id = this.items.length !== 0 ? this.items[0][this.keyField] : null;
        await this.loadMore();
        this.oldTopItem = {};
        for (let i = 0; !this.isFirstLoaded && i < 10; i++) {
          await this.sleep(200);
        }
        await this.sleep(200);
        this.canRefresh = true;
      }
    },
    async refreshTouchEnd(e) {
      const refreshDom = this.$refs.onRefresh;
      if (!this.canRefresh) {
        refreshDom.style.top = '30px';
        refreshDom.style.opacity = 1;
        this.oldTopItem.id = this.items.length !== 0 ? this.items[0][this.keyField] : null;
        await this.onRefresh();
        this.oldTopItem = {};
        this.canRefresh = true;
      }
      if (this._touchStartY) {
        this._touchStartY = null;
        if (refreshDom) {
          refreshDom.style.top = '0px';
          refreshDom.style.opacity = 0;
        }
      }
    },
    refreshTouchMove(e) {
      const y = e.touches[0].pageY;
      if (!this._touchStartY) {
        const scrollTop = this.pageMode ? document.scrollingElement.scrollTop : this.$refs.virtualScroll.scrollTop;
        if (scrollTop === 0) {
          this._touchStartY = y;
        }
        return;
      }
      if (y > this._touchStartY) {
        const halfDiff = (y - this._touchStartY)/2;
        if (halfDiff < this._halfPullHeight) {
          this.canRefresh = true;
          const opacity = Math.min(this._halfPullHeight, halfDiff)/this._halfPullHeight;
          this.$refs.onRefresh.style.top = halfDiff + 'px';
          this.$refs.onRefresh.style.opacity = opacity;
        } else {
          this.canRefresh = false;
          this.$refs.onRefresh.style.top = this._halfPullHeight + Math.log(1 + halfDiff - this._halfPullHeight)**2 + 'px';
          this.$refs.onRefresh.style.opacity = 1;
        }
        if (e.cancelable) {
          e.preventDefault();
        }
      }
    }
  }
}
</script>
