import Vue from 'vue';
import Vuex from 'vuex';

import User from './modules/user.js';
import HomeState from './modules/homeState.js';
import ProfileState from './modules/profileState.js';
import CommunityState from './modules/communityState.js';
import websocket from './modules/websocket.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User,
    HomeState,
    ProfileState,
    CommunityState,
    websocket
  },
});
