import Vue from 'vue';

const state = {
  postId: '',
};

const mutations = {
  setProfileState: (state, payload) => {
    state.postId = payload.profileState.postId;
  }
};

const actions = {
  doUpdateProfileState: ({ commit }, profileState) => {
    commit('setProfileState', { profileState });
  }
};

const getters = {
  profileState: state => {
    return {
      postId: state.postId
    };
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
