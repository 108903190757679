<template>
  <div class="register">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-stepper v-model="step" class="mx-auto pa-0">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-card class="mx-auto pa-4">
            <v-card-title class="display-1 primary--text pa-2">
              <span>新規登録</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  label="メールアドレス"
                  type="email"
                  required
                  v-model="mail"
                  single-line
                  prepend-icon="fa-envelope"
                  clear-icon="fa-times"
                  clearable
                  :rules="[rules.required, rules.mail]"
                  :success="Boolean(mail) && Boolean(rules.mail)"
                  :error-messages="mailError"
                  :error="!mail"
                ></v-text-field>

                <v-text-field
                  label="ユーザID"
                  type="text"
                  class="mb-2"
                  single-line
                  counter
                  prepend-icon="fa-id-card"
                  v-model="user_id"
                  :append-icon="'fa-spell-check'"
                  :rules="[rules.required, rules.min3, rules.max15]"
                  clear-icon="fa-times"
                  clearable
                  required
                  @click:append="validUserId"
                  :success-messages="idSuccess"
                  :error-messages="idError"
                  :error="!user_id"
                ></v-text-field>

                <v-text-field
                  class="mb-2"
                  type="text"
                  single-line
                  prepend-icon="fa-user"
                  v-model="username"
                  :rules="[rules.required, rules.max15]"
                  label="ユーザ名"
                  clear-icon="fa-times"
                  clearable
                  required
                  :success="Boolean(username) && Boolean(rules.max50)"
                  :error="!username"
                ></v-text-field>

                <v-select
                  v-model="gender"
                  :rules="[rules.required]"
                  required
                  :items="genders"
                  label="性別"
                  prepend-icon="fa-venus-mars"
                  single-line
                  :success="Boolean(gender)"
                  :error="!gender"
                ></v-select>

                <v-text-field
                  class="mb-2"
                  prepend-icon="fa-lock"
                  v-model="password"
                  :append-icon="showPass ? 'fa-eye-slash' : 'fa-eye'"
                  :rules="[rules.required, rules.min3]"
                  :type="showPass ? 'text' : 'password'"
                  single-line
                  label="パスワード"
                  counter
                  required
                  @click:append="showPass = !showPass"
                  :success="Boolean(password) && Boolean(rules.min3)"
                  :error="!password"
                ></v-text-field>

                <v-text-field
                  prepend-icon="fa-lock"
                  v-model="passConf"
                  :rules="[rules.required, rules.min3]"
                  type="password"
                  single-line
                  label="パスワード確認"
                  counter
                  required
                  :success-messages="passConfSuccess"
                  :error-messages="passConfError"
                  :error="!passConf"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-layout justify-end>
                <v-btn text @click="login">
                  <span style="width: 4rem;">ログイン</span>
                </v-btn>
                <v-btn
                  depressed
                  color="success"
                  @click="register"
                  type="submit"
                  :disabled="!valid || loading"
                  :loading="loading"
                >
                  <span style="width: 4rem;">登録</span>
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2" class="pa-0">
          <v-card class="mx-auto pa-4" flat>
            <v-card-title class="headline primary--text pa-0 mb-3">
              仮登録完了
            </v-card-title>
            <v-card-text class="pa-0">
              <p>
                ご登録いただいたメールアドレスに、アカウント有効化の認証URLを送りました。届いたメールをご確認ください。
              </p>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<style lang="scss">
.register {
  .v-input {
    height: 70px;
  }
}
</style>

<script>
import axios from '../axios/index';
import router from '../router/index.ts';
import _ from 'lodash';

export default {
  name: 'register',
  data: () => ({
    valid: false,
    step: 1,
    mail: '',
    user_id: '',
    username: '',
    gender: '',
    genders: [
      { text: '男性', value: 1 },
      { text: '女性', value: 2 },
      { text: 'その他', value: 3 }
    ],
    password: '',
    passConf: '',
    showPass: false,
    mailError: '',
    idSuccess: '',
    idError: '',
    passSuccess: '',
    passError: '',
    passConfSuccess: '',
    passConfError: '',
    loading: false,
    rules: {
      required: v => !!v || '必須項目です',
      mail: v => /.+@.+\..+/.test(v) || 'メールアドレス形式で入力してください',
      min3: v => (v == null ? '' : v).length >= 3 || '3文字以上で入力してください',
      max15: v => (v == null ? '' : v).length <= 15 || '15文字以内で入力してください',
      max50: v => (v == null ? '' : v).length <= 50 || '50文字以内で入力してください'
    }
  }),
  watch: {
    mail() {
      this.mailError = '';
    },
    user_id(value) {
      this.idSuccess = '';
      value = value == null ? '' : value;
      if (!value.match(/^[A-Za-z0-9_\-]*$/)) {
        this.idError = '半角英数字、「-」と「_」のみ入力してください';
      } else if (this.user_id && (this.user_id == null ? '' : this.user_id).length <= 15) {
        this.validUserId();
      }
    },
    password(value) {
      if (value === this.passConf) {
        this.passConfSuccess = '';
        this.passConfError = '再度入力してください';
      } else {
        this.passConfSuccess = '';
        this.passConfError = 'パスワードが一致しません';
      }
    },
    passConf(value) {
      if (value === this.password) {
        this.passConfError = '';
        this.passConfSuccess = '一致しています';
      } else {
        this.passConfSuccess = '';
        this.passConfError = 'パスワードが一致しません';
      }
    }
  },
  methods: {
    validUserId: _.debounce(async function() {
      if ((this.user_id == null ? '' : this.user_id).length < 3) {
        this.idSuccess = '';
        this.idError = 'そのユーザIDは使用できません';
        return;
      } else if (!this.user_id.match(/^[A-Za-z0-9_\-]*$/)) {
        this.idSuccess = '';
        this.idError = '半角英数字、「-」と「_」のみ入力してください';
        return;
      }

      let self = this;
      await axios
        .get('/accounts/check_user_id', {
          params: {
            id: self.user_id
          }
        })
        .then(res => {
          if (res.data.flag === true) {
            self.idSuccess = '使用可能です';
            self.idError = '';
          } else if (res.data.flag === false) {
            self.idSuccess = '';
            self.idError = 'そのユーザIDは使用できません';
          } else {
            throw new Error();
          }
        })
        .catch(e => {
          console.log(e);
          self.idError = 'エラーが発生しました';
        });
    }, 500),
    register: function() {
      let self = this;
      this.validUserId()
      .then(() => {
        if (self.idSuccess) {
          axios
            .post('/accounts/user', {
              mail: self.mail,
              password: self.password,
              user_id: self.user_id,
              name: self.username,
              gender: self.gender - 1
            }, {
              validateStatus: function(status) {
                return status < 500;
              }
            })
            .then(res => {
              console.log(res);
              if (res.status === 201) {
                self.step = 2;
              } else {
                let error = 0;
                if (typeof res.data.mail !== 'undefined') {
                  self.mailError = 'そのメールアドレスは使用できません。';
                  error++;
                }
                if (typeof res.data.user_id !== 'undefined') {
                  self.idSuccess = '';
                  self.idError = 'そのユーザIDは使用できません。';
                  error++;
                }
                if (!error) {
                  throw new Errow();
                }
              }
            })
            .catch(e => {
              self.snackbarText = 'エラーが発生しました。';
              self.snackbarColor = 'error';
              self.snackbar = true;
            });
        }
      });
    },
    login: function() {
      router.push({ name: 'login' });
    }
  }
}
</script>
