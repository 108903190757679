<template>
  <v-bottom-sheet v-model="innerSheet" inset>
    <v-list>
      <v-list-item
        v-for="tile in tiles"
        :key="tile.id"
        @click="action(tile.id, ids)"
      >
        <v-list-item-avatar>
          <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-title :class="tile.color+'--text'">{{ tile.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'bottom-sheet',
  props: {
    sheet: false,
    tiles: null,
    ids: null,
    action: null
  },
  computed: {
    innerSheet: {
      get() {
        return this.sheet;
      },
      set(val) {
        this.$parent.bottomSheet = val;
      }
    }
  }
}
</script>
