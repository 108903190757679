import Vue from "vue";
import VueRouter from "vue-router";

// @ts-ignore
import store from "../store";

import Help from "../views/Help.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermOfUse from "../views/TermOfUse.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import PasswordReset from "../views/PasswordReset.vue";
import Register from "../views/Register.vue";
import Settings from "../views/Settings.vue";

import Home from "../views/Home.vue";
import OpenPost from "../views/OpenPost.vue";
import Profile from "../views/Profile.vue";
import HomeSearch from "../views/HomeSearch.vue";

import Communities from "../views/Communities.vue";
import CommunitiesSearch from "../views/CommunitiesSearch.vue";
import OpenCommunity from "../views/OpenCommunity.vue";
import OpenCommunityPost from "../views/OpenCommunityPost.vue";

import Notifications from "../views/Notifications.vue";

import Messages from "../views/Messages.vue";
import OpenMessage from "../views/OpenMessage.vue";

import OpenUserList from "../views/OpenUserList.vue";
import { refreshAndSetToken } from "@/mixins/token";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Piperia|ログイン",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "Piperia|ログアウト",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: PasswordReset,
    meta: {
      title: "Piperia|パスワード再発行",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "Piperia|新規登録",
      desc: "",
      isPublic: true,
    },
  },
  /*{
    path: '/help',
    name: 'help',
    component: Help,
    meta: {
      title: 'Piperia|ヘルプ',
      desc: '',
      isPublic: true,
    },
  },*/ {
    path: "/term-of-use",
    name: "term-of-use",
    component: TermOfUse,
    meta: {
      title: "Piperia|利用規約",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "Piperia|プライバシーポリシー",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "Piperia|ホーム",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/home/search",
    name: "home-search",
    component: HomeSearch,
    meta: {
      title: "Piperia|検索結果",
      desc: "",
    },
  },
  {
    path: "/post/:post_id",
    name: "open-post",
    component: OpenPost,
    meta: {
      title: "Piperia|投稿",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/user/:user_id?",
    name: "profile",
    component: Profile,
    meta: {
      title: "Piperia|プロフィール",
      desc: "",
    },
  },
  {
    path: "/communities",
    name: "communities",
    component: Communities,
    meta: {
      title: "Piperia|コミュニティ",
      desc: "",
    },
  },
  {
    path: "/community/:group_id",
    name: "open-community",
    component: OpenCommunity,
    meta: {
      title: "Piperia|コミュニティ",
      desc: "",
    },
  },
  {
    path: "/communities/search",
    name: "communities-search",
    component: CommunitiesSearch,
    meta: {
      title: "Piperia|検索結果",
      desc: "",
    },
  },
  {
    path: "/community/post/:post_id",
    name: "open-community-post",
    component: OpenCommunityPost,
    meta: {
      title: "Piperia|投稿",
      desc: "",
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: {
      title: "Piperia|通知",
      desc: "",
    },
  },
  {
    path: "/messages",
    name: "messages",
    component: Messages,
    meta: {
      title: "Piperia|DM",
      desc: "",
    },
  },
  {
    path: "/messages/:user_id",
    name: "open-message",
    component: OpenMessage,
    meta: {
      title: "Piperia|DM",
      desc: "",
    },
  },
  {
    path: "/account/settings/:id?/:sub_id?",
    name: "settings",
    component: Settings,
    meta: {
      title: "Piperia|設定",
      desc: "",
    },
  },
  {
    path: "/:mode/:id/:type",
    name: "open-user-list",
    component: OpenUserList,
    meta: {
      title: "Piperia|ユーザ一覧",
      desc: "",
    },
  },
  {
    path: "*",
    redirect: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // @ts-ignore
  routes,
  scrollBehavior(to, from) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const user = store.state.User;
  if (user.id || to.matched.some((record) => record.meta.isPublic)) {
    await refreshAndSetToken();
    next();
  } else {
    next({ name: "logout", query: { redirect: to.fullPath } });
  }
});

export default router;
