<template>
  <div class="privacy-policy">
    <v-card
      class="mx-auto pa-4"
      max-width="800"
    >
      <div class="headline primary--text">プライバシーポリシー</div>
      <section class="mt-3">
        <div class="subtitle">1. 個人情報保護方針</div>
        <div class="body-2">
          当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
        </div>
      </section>
      <section class="mt-3">
        <div class="subtitle">2. 個人情報の管理</div>
        <div class="body-2">
          当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
        </div>
      </section>
      <section class="mt-3">
        <div class="subtitle">3. 個人情報の利用目的</div>
        <div class="body-2">
          本ウェブサイトでは、お客様からのお問い合わせ時に、お名前、メールアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。
          お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内、ご質問に対する回答として、電子メールや資料のご送付に利用致します。
        </div>
      </section>
      <section class="mt-3">
        <div class="subtitle">4. 個人情報の第三者への開示・提供の禁止</div>
        <div class="body-2">
          当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
          <ul>
            <li>お客さまの同意がある場合</li>
            <li>お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合</li>
            <li>法令に基づき開示することが必要である場合</li>
          </ul>
        </div>
      </section>
      <section class="mt-3">
        <div class="subtitle">5. 個人情報の安全対策</div>
        <div class="body-2">
          当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
        </div>
      </section>
      <section class="mt-3">
        <div class="subtitle">6. ご本人の照会</div>
        <div class="body-2">
          お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
        </div>
      </section>
      <section class="mt-3">
        <div class="subtitle">7. アクセス解析</div>
        <div class="body-2">
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
          このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
          こちらのトラフィックデータは匿名で収集されており、個人を特定するものではありません。本機能は、Cookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
          この規約に関して、詳しくは「<a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank">Google アナリティクス利用規約</a>」をご覧ください。
        </div>
      </section>
      <section class="mt-3">
        <div class="subtitle">8. 法令、規範の遵守と見直し</div>
        <div class="body-2">
          当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
        </div>
      </section>
      <section class="mt-3 mb-5">
        <div class="subtitle">お問い合せ</div>
        <div class="body-2">
          当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
          <div>
            <a href="mailto:piperia.sns@gmail.com">piperia.sns@gmail.com</a>
          </div>
        </div>
      </section>
    </v-card>
  </div>
</template>

<style>
section > div:not(.subtitle) {
  text-align: justify;
}
</style>

<script>
export default {
  name: 'privacy-policy'
};
</script>
