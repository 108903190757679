import Vue from 'vue';

const state = {
  tab: '',
  postId: '',
};

const mutations = {
  setHomeState: (state, payload) => {
    state.tab = payload.homeState.tab;
    state.postId = payload.homeState.postId;
  }
};

const actions = {
  doUpdateHomeState: ({ commit }, homeState) => {
    commit('setHomeState', { homeState });
  }
};

const getters = {
  homeState: state => {
    return {
      tab: state.tab,
      postId: state.postId
    };
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
