<template>
  <div class="password-reset">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-stepper v-model="step" class="mx-auto pa-0">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-card class="mx-auto pa-4">
            <v-card-title class="display-1 primary--text pa-2">
              <span>パスワード再発行</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-text-field
                type="text"
                single-line
                prepend-icon="fa-envelope"
                v-model="mail"
                label="メールアドレス"
                clear-icon="fa-times"
                clearable
                required
                style="height: 70px;"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-layout justify-end>
                <v-btn text :to="{ name: 'login' }">
                  <span style="width: 4rem;">ログイン</span>
                </v-btn>
                <v-btn
                  :disabled="!mail"
                  :loading="isLoading.sendMail"
                  depressed
                  color="primary"
                  @click="sendResetMail"
                >
                  <span style="width: 4rem;">再発行</span>
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-card class="mx-auto pa-4">
            <v-card-title class="display-1 primary--text pa-2">
              <span>パスワード再設定</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-text-field
                type="text"
                single-line
                prepend-icon="fa-envelope"
                v-model="pinCode"
                label="PIN"
                :success="!!pinCode"
                required
                style="height: 70px;"
              ></v-text-field>

              <v-text-field
                class="mb-2"
                prepend-icon="fa-lock"
                v-model="password.new"
                :append-icon="password.show ? 'fa-eye-slash' : 'fa-eye'"
                :rules="[rules.min3]"
                :type="password.show ? 'text' : 'password'"
                single-line
                label="パスワード"
                counter
                required
                @click:append="password.show = !password.show"
                :success="Boolean(password.new) && Boolean(rules.min3)"
                :error="!password.new"
              ></v-text-field>

              <v-text-field
                prepend-icon="fa-lock"
                v-model="password.confirm"
                :rules="[rules.min3]"
                type="password"
                single-line
                label="パスワード確認"
                counter
                required
                :success-messages="password.confSuccess"
                :error-messages="password.confError"
                :error="!password.confirm"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-layout justify-end>
                <v-btn
                  :disabled="!pinCode || !(password.confSuccess && password.new.length >= 3)"
                  :loading="isLoading.changePass"
                  depressed
                  color="primary"
                  @click="changePassword"
                >
                  <span style="width: 4rem;">再設定</span>
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-stepper>
  </div>
</template>

<style>
</style>

<script>
import axios from '../axios/index';
import router from '../router/index';

export default {
  name: 'password-reset',
  data: function() {
    return {
      step: 1,
      mail: '',
      rules: {
        min3: (v) => !v || v.length >= 3 || '3文字以上で入力してください'
      },
      isLoading: {
        sendMail: false,
        changePass: false
      },
      pinCode: '',
      password: {
        new: '',
        confirm: '',
        show: false,
        confError: '',
        confSuccess: ''
      }
    };
  },
  watch: {
    'password.confirm'(value) {
      if (value === this.password.new) {
        this.password.confError = '';
        this.password.confSuccess = '一致しています';
      } else {
        this.password.confSuccess = '';
        this.password.confError = 'パスワードが一致しません';
      }
    }
  },
  methods: {
    async sendResetMail() {
      this.isLoading.sendMail = true;
      await axios
        .post('/accounts/reset_password', {
          mail: this.mail
        },
        {
          validateStatus(status) {
            return status <= 400;
          }
        })
        .then(res => {
          if (res.status === 400) {
            this.snackbarColor = 'error';
            this.snackbarText = 'メールアドレスを確認してください';
            this.snackbar = true;
            return;
          }
          this.snackbarColor = 'success';
          this.snackbarText = 'メールにPINコードを送信しました';
          this.snackbar = true;
          this.step = 2;
        })
        .catch(() => {
          this.snackbarColor = 'error';
          this.snackbarText = 'エラーが発生しました';
          this.snackbar = true;
        });
      this.isLoading.sendMail = false;
    },
    async changePassword() {
      this.isLoading.changePass = true;
      await axios
        .patch('/accounts/reset_password', {
          id: this.pinCode,
          password: this.password.new
        },
        {
          validateStatus(status) {
            return status <= 400;
          }
        })
        .then(async res => {
          if (res.status === 400) {
            this.snackbarColor = 'error';
            this.snackbarText = 'PINコードが違います';
            this.snackbar = true;
            return;
          }
          this.snackbarColor = 'success';
          this.snackbarText = 'パスワードを再設定しました';
          this.snackbar = true;
          await this.sleep(500);
          router.push({name: 'login'});
        })
        .catch(() => {
          this.snackbarColor = 'error';
          this.snackbarText = 'エラーが発生しました';
          this.snackbar = true;
        });
      this.isLoading.changePass = false;
    }
  },
  beforeMount: function () {
    if (localStorage.getItem('access_token')) {
      router.push({name: 'home'});
    }
  }
};
</script>
