<template>
  <div class="images-box my-3">
    <v-row v-if="images.length === 1" class="mx-0">
      <v-col class="pa-0">
        <v-card outlined>
          <v-img
            :src="images[0]"
            aspect-ratio="1"
            @click.stop="show(images, 0)"
          >
            <template v-slot:placeholder>
              <v-skeleton-loader
                type="image"
                width="100%"
                height="100%"
              ></v-skeleton-loader>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="images.length === 2" class="mx-0">
      <v-col v-for="(img, i) in images" class="pa-0">
        <v-card outlined>
          <v-img :src="img" aspect-ratio="1" @click.stop="show(images, i)">
            <template v-slot:placeholder>
              <v-skeleton-loader
                type="image"
                width="100%"
                height="100%"
              ></v-skeleton-loader>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="images.length === 3" class="mx-0">
      <v-col class="pa-0">
        <v-col class="pa-0 col-12 mb-sm-2">
          <v-card outlined>
            <v-img
              :src="images[0]"
              aspect-ratio="1"
              @click.stop="show(images, 1)"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  width="100%"
                  height="100%"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </v-card>
        </v-col>
        <v-col class="pa-0 col-12">
          <v-card outlined>
            <v-img
              :src="images[1]"
              aspect-ratio="1"
              @click.stop="show(images, 2)"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  width="100%"
                  height="100%"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </v-card>
        </v-col>
      </v-col>
      <v-col class="pa-0">
        <v-card outlined>
          <v-img
            :src="images[2]"
            aspect-ratio="1"
            @click.stop="show(images, 0)"
          >
            <template v-slot:placeholder>
              <v-skeleton-loader
                type="image"
                width="100%"
                height="100%"
              ></v-skeleton-loader>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <template v-else>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-card outlined>
            <v-img
              :src="images[0]"
              aspect-ratio="1"
              @click.stop="show(images, 0)"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  width="100%"
                  height="100%"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </v-card>
        </v-col>
        <v-col class="pa-0">
          <v-card outlined>
            <v-img
              :src="images[1]"
              aspect-ratio="1"
              @click.stop="show(images, 1)"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  width="100%"
                  height="100%"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-card outlined>
            <v-img
              :src="images[2]"
              aspect-ratio="1"
              @click.stop="show(images, 2)"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  width="100%"
                  height="100%"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </v-card>
        </v-col>
        <v-col class="pa-0">
          <v-card outlined>
            <v-img
              :src="images[3]"
              aspect-ratio="1"
              @click.stop="show(images, 3)"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader
                  type="image"
                  width="100%"
                  height="100%"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<style lang="scss">
.v-skeleton-loader__image {
  height: 1000px !important;
}
</style>

<script>
export default {
  name: "images-box",
  props: {
    images: null,
    show: null,
  },
};
</script>
