import Vue from "vue";
import axios from "../axios/index";
import imageAxios from "../axios/image";
import ImageUtil from "../plugins/ImageUtil";
import router from "../router";
import copy from "copy-to-clipboard";

Vue.mixin({
  data: () => ({
    belongs: [
      { text: "その他", value: 1 },
      { text: "中学生", value: 2 },
      { text: "高校生", value: 3 },
      { text: "高専生", value: 4 },
      { text: "大学生", value: 5 },
      { text: "専門学生", value: 6 },
    ],
    grades: [
      { text: "その他", value: 1 },
      { text: "1年", value: 2 },
      { text: "2年", value: 3 },
      { text: "3年", value: 4 },
      { text: "4年", value: 5 },
      { text: "5年", value: 6 },
    ],
    prefectures: [
      { text: "その他", value: 1 },
      { text: "北海道", value: 2 },
      { text: "青森県", value: 3 },
      { text: "岩手県", value: 4 },
      { text: "宮城県", value: 5 },
      { text: "秋田県", value: 6 },
      { text: "山形県", value: 7 },
      { text: "福島県", value: 8 },
      { text: "茨城県", value: 9 },
      { text: "栃木県", value: 10 },
      { text: "群馬県", value: 11 },
      { text: "埼玉県", value: 12 },
      { text: "千葉県", value: 13 },
      { text: "東京都", value: 14 },
      { text: "神奈川県", value: 15 },
      { text: "新潟県", value: 16 },
      { text: "富山県", value: 17 },
      { text: "石川県", value: 18 },
      { text: "福井県", value: 19 },
      { text: "山梨県", value: 20 },
      { text: "長野県", value: 21 },
      { text: "岐阜県", value: 22 },
      { text: "静岡県", value: 23 },
      { text: "愛知県", value: 24 },
      { text: "三重県", value: 25 },
      { text: "滋賀県", value: 26 },
      { text: "京都府", value: 27 },
      { text: "大阪府", value: 28 },
      { text: "兵庫県", value: 29 },
      { text: "奈良県", value: 30 },
      { text: "和歌山県", value: 31 },
      { text: "鳥取県", value: 32 },
      { text: "島根県", value: 33 },
      { text: "岡山県", value: 34 },
      { text: "広島県", value: 35 },
      { text: "山口県", value: 36 },
      { text: "徳島県", value: 37 },
      { text: "香川県", value: 38 },
      { text: "愛媛県", value: 39 },
      { text: "高知県", value: 40 },
      { text: "福岡県", value: 41 },
      { text: "佐賀県", value: 42 },
      { text: "長崎県", value: 43 },
      { text: "熊本県", value: 44 },
      { text: "大分県", value: 45 },
      { text: "宮崎県", value: 46 },
      { text: "鹿児島県", value: 47 },
      { text: "沖縄県", value: 48 },
    ],
    compressing: {
      icon: false,
      top: false,
    },
    selectedImages: [],
    imageIndex: null,
    newPost: "",
    postLoading: false,
    selectedPostImages: {
      base64: [],
      binary: [],
    },
    selectedSettingsImages: {
      icon: {
        originalBlob: null,
        blob: null,
        binary: null,
      },
      top: {
        originalBlob: null,
        blob: null,
        binary: null,
      },
    },
    myData: {
      follow: 0,
      follower: 0,
    },
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    myTiles: [
      {
        id: "delete",
        title: "削除",
        icon: "fa-trash",
        color: "error",
      },
      {
        id: "copy-link",
        title: "リンクをコピー",
        icon: "fa-link",
      },
    ],
    otherTiles: [
      {
        id: "follow",
        title: "フォロー",
        icon: "fa-user-plus",
      },
      {
        id: "messages",
        title: "DM",
        icon: "fa-envelope",
      },
      {
        id: "block",
        title: "ブロック",
        icon: "fa-shield-alt",
      },
      {
        id: "report",
        title: "通報",
        icon: "fa-flag",
      },
      {
        id: "copy-link",
        title: "リンクをコピー",
        icon: "fa-link",
      },
    ],
    shareTiles: [
      {
        id: "share",
        title: "シェア",
        icon: "fa-share",
      },
      {
        id: "quote",
        title: "引用",
        icon: "fa-quote-right",
      },
    ],
    communityTiles: {
      admin: [
        {
          id: "comunity-settings",
          title: "設定",
          icon: "fa-cog",
        },
        {
          id: "member",
          title: "メンバー",
          icon: "fa-users",
        },
        {
          id: "invite",
          title: "招待リスト",
          icon: "fa-envelope",
        },
        {
          id: "request",
          title: "申請リスト",
          icon: "fa-sign-in-alt",
        },
        {
          id: "ban",
          title: "BANリスト",
          icon: "fa-ban",
        },
        {
          id: "copy-link",
          title: "リンクをコピー",
          icon: "fa-link",
        },
      ],
      other: [
        {
          id: "join",
          title: "参加",
          icon: "fa-sign-in-alt",
        },
        {
          id: "admin",
          title: "管理者",
          icon: "fa-user-cog",
        },
        {
          id: "member",
          title: "メンバー",
          icon: "fa-users",
        },
        {
          id: "copy-link",
          title: "リンクをコピー",
          icon: "fa-link",
        },
      ],
    },
    quoteDialog: false,
    selectedIds: {},
    reportMessage: "",
    bottomSheet: false,
    selectedSheetTiles: [],
  }),
  watch: {
    quoteDialog() {
      // @ts-ignore
      this.newPost = "";
      this.postLoading = false;
      // @ts-ignore
      document.querySelector(".v-file-input input").value = "";
      // @ts-ignore
      document.querySelector(".v-file-input__text").textContent = "";
      // @ts-ignore
      this.selectedPostImages = {
        // @ts-ignore
        base64: [],
        binary: [],
      };
    },
    bottomSheet(val) {
      try {
        if (val) {
          setTimeout(() => {
            // @ts-ignore
            document.querySelector(".v-snack").style.bottom =
              document.querySelector(".v-sheet").clientHeight + 8 + "px";
          }, 100);
        } else {
          // @ts-ignore
          document.querySelector(".v-snack").style.bottom =
            document.querySelector(".v-bottom-navigation").clientHeight +
            8 +
            "px";
        }
      } catch (e) {}
    },
  },
  computed: {
    userData() {
      return {
        // @ts-ignore
        id: this.$store.getters.userData.id,
        // @ts-ignore
        name: this.$store.getters.userData.name,
        // @ts-ignore
        icon_link: this.$store.getters.userData.icon_link,
        // @ts-ignore
        top_link: this.$store.getters.userData.top_link,
        // @ts-ignore
        is_key: this.$store.getters.userData.is_key,
        // @ts-ignore
        is_official: this.$store.getters.userData.is_official,
        // @ts-ignore
        is_prereg: this.$store.getters.userData.is_prereg,
        // @ts-ignore
        follow: this.myData.follow,
        follower: this.myData.follower,
      };
    },
    $_domHeights() {
      const heights = {
        appBar: 56,
        bottomNavigation: 56,
      };
      try {
        // @ts-ignore
        heights.appBar = document.querySelector(".v-app-bar").clientHeight;
        // @ts-ignore
        heights.bottomNavigation = document.querySelector(
          ".v-bottom-navigation"
        ).clientHeight;
      } catch (e) {}
      return heights;
    },
    $_bottomFabSpace() {
      // @ts-ignore
      return this.$_domHeights.bottomNavigation + 16;
    },
    $_AppVersion() {
      return process.env.VUE_APP_VERSION;
    },
    $_isEnvDevelopment() {
      return false && process.env.NODE_ENV === "development";
    },
    $_isMobile() {
      return window.innerWidth < 600 ? true : false;
    },
    $_isFullscreen() {
      return window.innerWidth < 1264 ? true : false;
    },
  },
  methods: {
    $_notImage(item, param) {
      item[param] = null;
    },
    sleep(msec) {
      return new Promise((resolve) => setTimeout(resolve, msec));
    },
    setImages(images, index) {
      // @ts-ignore
      this.selectedImages = images;
      this.imageIndex = index;
    },
    urlParse(str) {
      const regexp_url = /((https?:\/\/[^ ]+\.[^ ]+)) ?/g;
      return str.replace(regexp_url, '<a href="$1" target="_blank">$1</a>');
    },
    reload() {
      // @ts-ignore
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    openUserList(mode, id, type) {
      const query = {};
      // @ts-ignore
      if (typeof this.selectedCommunity !== "undefined") {
        // @ts-ignore
        if (this.selectedCommunity.admin.user_id === this.userData.id) {
          // @ts-ignore
          query.is_admin = true;
        }
      }
      router.push({
        name: "open-user-list",
        params: {
          mode,
          id,
          type,
        },
        query,
      });
    },
    openProfileDrawer() {
      // @ts-ignore
      document.querySelector(".open-profile-drawer").click();
    },
    changeNumFormat(num) {
      if (typeof num === "undefined") {
        return "";
      } else if (num <= 9999) {
        return num.toLocaleString();
      }
      let formatNum = String(num);
      formatNum = formatNum.slice(0, -3);
      return formatNum.slice(0, -1) + "." + formatNum.slice(-1) + "万";
    },
    changeDateFormat(date) {
      if (!date) {
        return false;
      }
      const fDate = new Date(date);
      let format = "YYYY/MM/DD";
      format = format.replace(/YYYY/, String(fDate.getFullYear()));
      format = format.replace(
        /MM/,
        ("0" + String(fDate.getMonth() + 1)).slice(-2)
      );
      format = format.replace(/DD/, ("0" + String(fDate.getDate())).slice(-2));
      return format;
    },
    getFromNow(date) {
      const from = new Date(date);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (typeof date === "undefined") {
        return "";
      } else if (
        elapsed.getUTCFullYear() - 1970 ||
        elapsed.getUTCMonth() ||
        elapsed.getUTCDate() - 1 >= 7
      ) {
        // @ts-ignore
        return this.changeDateFormat(from);
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + "日前";
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + "時間前";
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + "分前";
      } else {
        return elapsed.getUTCSeconds() + "秒前";
      }
    },
    openProfile(user_id) {
      // @ts-ignore
      if (this.$route.params.user_id !== user_id) {
        router.push({ name: "profile", params: { user_id } });
      }
    },
    openCommunity(group_id) {
      // @ts-ignore
      this.$store.dispatch("doUpdateCommunityState", { tab: this.selectedTab });
      router.push({ name: "open-community", params: { group_id } });
    },
    openPost(post_id, share_id) {
      if (this.$router.currentRoute.name === "open-community") {
        // @ts-ignore
        this.$store.dispatch("doUpdateCommunityState", {
          postId: share_id ? share_id : post_id,
        });
        router.push({ name: "open-community-post", params: { post_id } });
        return;
      } else if (this.$route.path.split("/")[1] === "community") {
        router.push({ name: "open-community-post", params: { post_id } });
        return;
      }
      if (this.$router.currentRoute.name === "home") {
        // @ts-ignore
        this.$store.dispatch("doUpdateHomeState", {
          tab: this.selectedTab,
          postId: share_id ? share_id : post_id,
        });
      } else if (this.$router.currentRoute.name === "profile") {
        // @ts-ignore
        this.$store.dispatch("doUpdateProfileState", {
          postId: share_id ? share_id : post_id,
        });
      }
      router.push({ name: "open-post", params: { post_id } });
    },
    likePost(post_id) {
      // @ts-ignore
      if (!this.userData.id) {
        return;
      }
      const self = this;
      if (this.$route.path.split("/")[1] === "community") {
        // @ts-ignore
        const getPost = self.postData || self.post;
        const action = getPost.is_favorite ? "del" : "add";
        axios
          .post("/group/favorite/" + action, {
            post_id,
          })
          .then((res) => {
            if (res.status !== 200) {
              throw new Error();
            }
            // @ts-ignore
            if (getPost.is_favorite === false) {
              // @ts-ignore
              getPost.is_favorite = true;
              // @ts-ignore
              getPost.favorite++;
            } else {
              // @ts-ignore
              getPost.is_favorite = false;
              // @ts-ignore
              getPost.favorite--;
            }
          })
          .catch((e) => {
            console.log(e);
          });
        return;
      }
      axios
        .post("/post/favorite", {
          post_id,
        })
        .then((res) => {
          if (res.status !== 201) {
            throw new Error();
          }
          // @ts-ignore
          const getPost = self.postData;
          if (res.data.status) {
            // @ts-ignore
            getPost.favorite_flag = true;
            getPost.favorites++;
          } else {
            // @ts-ignore
            getPost.favorite_flag = false;
            getPost.favorites--;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    sharePost(post_id) {
      // @ts-ignore
      if (!this.userData.id) {
        return;
      }
      const self = this;
      axios
        .get("/post/post", {
          params: {
            post_id,
          },
        })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }
          if (res.data.share_flag) {
            // @ts-ignore
            self.shareTiles[0].title = "シェア解除";
            self.shareTiles[0].color = "error";
          } else {
            // @ts-ignore
            self.shareTiles[0].title = "シェア";
            self.shareTiles[0].color = "";
          }
          // @ts-ignore
          self.selectedSheetTiles = self.shareTiles;
          // @ts-ignore
          self.selectedIds = {
            post_id,
          };
          // @ts-ignore
          self.bottomSheet = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    doShare(post_id, message = "") {
      const self = this;
      // @ts-ignore
      this.postLoading = true;
      // @ts-ignore
      if (this.selectedPostImages.binary.length) {
        const params = new FormData();
        // @ts-ignore
        for (let i = 0; i < self.selectedPostImages.binary.length; i++) {
          // @ts-ignore
          params.append("file" + (i + 1), self.selectedPostImages.binary[i]);
        }
        console.log(params);
        imageAxios
          .post("/post/upload_image", params)
          .then((res) => {
            const ids = res.data.ids;
            if (res.status !== 201) {
              throw new Error();
            }
            axios
              .post("/post/share", {
                post_id,
                message: message.replace(/\n+/, "\n"),
                images: [...ids],
              })
              .then((res) => {
                if (res.status === 201) {
                  // @ts-ignore
                  self.quoteDialog = false;
                } else {
                  throw new Error();
                }
              });
          })
          .catch((e) => {
            console.log(e);
            // @ts-ignore
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          })
          .finally(() => {
            // @ts-ignore
            self.postLoading = false;
          });
      } else {
        axios
          .post("/post/share", {
            post_id,
            message: message.replace(/\n+/, "\n") || "",
          })
          .then((res) => {
            if (res.status === 201) {
              // @ts-ignore
              self.quoteDialog = false;
              // @ts-ignore
              if (message) {
                return;
              }
              // @ts-ignore
              if (typeof self.post !== "undefined") {
                // @ts-ignore
                if (self.post.share_flag === false) {
                  // @ts-ignore
                  self.post.share_flag = true;
                  // @ts-ignore
                  self.post.shares++;
                } else {
                  // @ts-ignore
                  self.post.share_flag = false;
                  // @ts-ignore
                  self.post.shares--;
                }
              } else {
                // @ts-ignore
                const getPosts =
                  typeof self.posts !== "undefined"
                    ? self.posts[self.selectedTab]
                    : typeof self.results.post !== "undefined"
                    ? self.results.post
                    : null;
                const index =
                  getPosts === null
                    ? null
                    : getPosts.map((obj) => obj.post_id).indexOf(post_id);
                if (index !== null) {
                  // @ts-ignore
                  if (getPosts[index].share_flag === false) {
                    // @ts-ignore
                    getPosts[index].share_flag = true;
                    // @ts-ignore
                    getPosts[index].shares++;
                  } else {
                    // @ts-ignore
                    getPosts[index].share_flag = false;
                    // @ts-ignore
                    getPosts[index].shares--;
                  }
                }
              }
            } else {
              throw new Error();
            }
          })
          .catch((e) => {
            console.log(e);
            // @ts-ignore
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          })
          .finally(() => {
            // @ts-ignore
            self.postLoading = false;
          });
      }
    },
    showSheet(post_id, user_id, group_id) {
      // @ts-ignore
      if (
        this.$route.name === "profile" &&
        !post_id &&
        user_id === this.userData.id
      ) {
        // @ts-ignore
        this.settingsDialog = true;
        return;
      }
      // @ts-ignore
      if (user_id) {
        const self = this;
        axios
          .get("/accounts/user", {
            params: {
              id: user_id,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.status !== 200) {
              throw new Error();
            }
            let tiles;
            // @ts-ignore
            if (user_id === self.userData.id) {
              // @ts-ignore
              tiles = self.myTiles;
            } else {
              // @ts-ignore
              tiles = self.otherTiles;
              if (res.data.is_block) {
                // @ts-ignore
                tiles[2].title = "ブロック解除";
                tiles = tiles.slice(2, 4);
              } else {
                // @ts-ignore
                tiles[2].title = "ブロック";
                if (res.data.is_follow) {
                  // @ts-ignore
                  tiles[0].title = "フォロー解除";
                  tiles[0].icon = "fa-user-minus";
                } else {
                  // @ts-ignore
                  tiles[0].title = "フォロー";
                  tiles[0].icon = "fa-user-plus";
                }
              }
            }
            // @ts-ignore
            self.selectedIds = {
              post_id,
              user_id,
            };
            // @ts-ignore
            self.selectedSheetTiles = tiles;
            // @ts-ignore
            self.bottomSheet = true;
          })
          .catch((e) => {
            console.log(e);
            // @ts-ignore
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          });
      } else {
        const self = this;
        axios
          .get("/group/detail", {
            params: {
              group_id,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.status !== 200) {
              throw new Error();
            }
            let tiles;
            // @ts-ignore
            if (res.data.admin.user_id === self.userData.id) {
              // @ts-ignore
              tiles = self.communityTiles.admin;
              // @ts-ignore
              if (self.selectedCommunity.kind !== 1) {
                // @ts-ignore
                tiles = tiles.slice(0, 3).concat(tiles.slice(-2));
              }
            } else {
              // @ts-ignore
              tiles = self.communityTiles.other;
              if (res.data.kind === 0) {
                if (res.data.status === 0) {
                  // @ts-ignore
                  tiles[0].id = "leave";
                  tiles[0].title = "脱退";
                  tiles[0].icon = "fa-sign-out-alt";
                } else {
                  // @ts-ignore
                  tiles[0].id = "join";
                  tiles[0].title = "参加";
                  tiles[0].icon = "fa-sign-in-alt";
                }
              } else if (res.data.kind === 1) {
                if (res.data.status === null) {
                  // @ts-ignore
                  tiles[0].id = "join";
                  tiles[0].title = "参加申請";
                  tiles[0].icon = "fa-sign-in-alt";
                  // @ts-ignore
                  tiles = tiles.slice(0, 2).concat(tiles.slice(-1));
                } else if (res.data.status === 2) {
                  // @ts-ignore
                  tiles[0].id = "leave";
                  tiles[0].title = "申請取消";
                  tiles[0].icon = "fa-ban";
                } else if (res.data.status === 0) {
                  // @ts-ignore
                  tiles[0].id = "leave";
                  tiles[0].title = "脱退";
                  tiles[0].icon = "fa-sign-out-alt";
                }
              } else {
                if (res.data.status === 0) {
                  // @ts-ignore
                  tiles[0].id = "leave";
                  tiles[0].title = "脱退";
                  tiles[0].icon = "fa-sign-out-alt";
                } else if (res.data.status === 1) {
                  // @ts-ignore
                  tiles[0].id = "join";
                  tiles[0].title = "参加";
                  tiles[0].icon = "fa-sign-in-alt";
                  // @ts-ignore
                  tiles = tiles.slice(0, 2).concat(tiles.slice(-1));
                } else if (res.data.status === 2) {
                  // @ts-ignore
                  tiles[0].id = "";
                  tiles[0].title = "招待されていません";
                  tiles[0].icon = "fa-ban";
                  tiles[0].color = "error";
                  // @ts-ignore
                  tiles = tiles.slice(0, 1);
                }
              }
            }
            // @ts-ignore
            self.selectedIds = {
              group_id,
            };
            // @ts-ignore
            self.selectedSheetTiles = tiles;
            // @ts-ignore
            self.bottomSheet = true;
          })
          .catch((e) => {
            console.log(e);
            // @ts-ignore
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          });
      }
    },
    userAction(actionId, ids) {
      const self = this;
      if (actionId === "share") {
        // @ts-ignore
        this.bottomSheet = false;
        // @ts-ignore
        this.doShare(this.selectedIds.post_id);
      } else if (actionId === "quote") {
        // @ts-ignore
        this.bottomSheet = false;
        this.quoteDialog = true;
      } else if (["follow", "block"].indexOf(actionId) !== -1) {
        const action = actionId === "follow" ? "フォロー" : "ブロック";
        axios
          .post("/users/" + actionId, {
            user_id: ids.user_id,
          })
          .then((res) => {
            if (res.status !== 201) {
              throw new Error();
            }
            // @ts-ignore
            self.bottomSheet = false;
            if (res.data.status === true) {
              // @ts-ignore
              self.snackbarColor = "success";
              self.snackbarText =
                "@" + ids.user_id + "さんを" + action + "しました。";
              self.snackbar = true;
            } else {
              // @ts-ignore
              self.snackbarColor = "error";
              self.snackbarText =
                "@" + ids.user_id + "さんの" + action + "を解除しました。";
              self.snackbar = true;
            }
            // @ts-ignore
            if (self.$route.name === "open-user-list") {
              // @ts-ignore
              const index = self.results
                .map((obj) => obj.user_id)
                .indexOf(ids.user_id);
              // @ts-ignore
              self.results[index][`is_${actionId}`] =
                !self.results[index][`is_${actionId}`];
              // @ts-ignore
              if (actionId === "block" && self.results[index].is_follow) {
                // @ts-ignore
                self.results[index].is_follow = false;
              }
              // @ts-ignore
            } else if (typeof self.results !== "undefined") {
              // @ts-ignore
              const index = self.results.user
                .map((obj) => obj.user_id)
                .indexOf(ids.user_id);
              // @ts-ignore
              self.results.user[index][`is_${actionId}`] =
                !self.results.user[index][`is_${actionId}`];
              // @ts-ignore
              if (actionId === "block" && self.results.user[index].is_follow) {
                // @ts-ignore
                self.results.user[index].is_follow = false;
              }
              // @ts-ignore
            } else if (self.$route.name === "profile") {
              // @ts-ignore
              self.selectedUser[`is_${actionId}`] =
                !self.selectedUser[`is_${actionId}`];
              // @ts-ignore
              if (actionId === "block" && self.selectedUser.is_follow) {
                // @ts-ignore
                self.selectedUser.is_follow = false;
              }
            }
          })
          .catch((e) => {
            console.log(e);
            // @ts-ignore
            this.snackbarColor = "error";
            this.snackbarText = "エラーが発生しました。";
            this.snackbar = true;
          });
      } else if (actionId === "delete") {
        const mode =
          this.$route.path.split("/")[1] === "community" ? "group" : "post";
        let uri = "/post";
        if (this.$route.name === "open-community-post") {
          // @ts-ignore
          if (this.comments.map((obj) => obj.post_id).includes(ids.post_id)) {
            uri = "/comment";
          }
        }
        console.log("delete", uri);
        axios
          .delete(mode + uri, {
            data: {
              post_id: ids.post_id,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              // @ts-ignore
              self.bottomSheet = false;
              // @ts-ignore
              self.snackbarColor = "success";
              self.snackbarText = "投稿を削除しました。";
              self.snackbar = true;
              // @ts-ignore
              if (typeof self.notPost !== "undefined") {
                // @ts-ignore
                if (self.post.post_id === ids.post_id) {
                  // @ts-ignore
                  self.notPost = true;
                } else {
                  // @ts-ignore
                  const index = self.comments
                    .map((obj) => obj.post_id)
                    .indexOf(ids.post_id);
                  // @ts-ignore
                  self.comments.splice(index, 1);
                }
              } else {
                let getPosts;
                // @ts-ignore
                if (mode === "group") {
                  // @ts-ignore
                  getPosts = self.posts;
                } else {
                  // @ts-ignore
                  getPosts =
                    typeof self.posts !== "undefined"
                      ? self.posts[self.selectedTab]
                      : typeof self.results.post !== "undefined"
                      ? self.results.post
                      : null;
                }
                const index =
                  getPosts === null
                    ? null
                    : getPosts.map((obj) => obj.post_id).indexOf(ids.post_id);
                getPosts.splice(index, 1);
              }
            } else {
              throw new Error();
            }
          })
          .catch((e) => {
            // @ts-ignore
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          });
      } else if (actionId === "messages") {
        // @ts-ignore
        this.openMessage(ids.user_id);
      } else if (actionId === "report") {
        axios
          .post("/report/user", {
            user_id: ids.user_id,
            message: ids.post_id || "post_idが存在しません。",
          })
          .then((res) => {
            console.log(res);
            if (res.status === 201) {
              // @ts-ignore
              self.snackbarColor = "success";
              self.snackbarText = "通報が完了しました。";
              self.snackbar = true;
            } else {
              throw new Error();
            }
          })
          .catch((e) => {
            // @ts-ignore
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          });
      } else if (["join", "leave"].indexOf(actionId) !== -1) {
        axios
          .post("/group/" + actionId, {
            group_id: ids.group_id,
          })
          .then((res) => {
            if (res.status !== 200) {
              throw new Error();
            }
            // @ts-ignore
            self.bottomSheet = false;
            if (actionId === "join") {
              // @ts-ignore
              self.snackbarColor = "success";
              // @ts-ignore
              self.snackbarText =
                ids.name +
                "に" +
                (res.data.status === 2 ? "参加申請" : "参加") +
                "しました。";
              // @ts-ignore
              self.snackbar = true;
              if (res.data.status === 0) {
                // @ts-ignore
                self.selectedCommunity.member_sum++;
              }
              // @ts-ignore
              self.selectedCommunity.status = res.data.status;
            } else {
              // @ts-ignore
              self.snackbarColor = "error";
              // @ts-ignore
              self.snackbarText =
                ids.name +
                "を" +
                (self.selectedCommunity.status === 2 ? "申請取消" : "脱退") +
                "しました。";
              // @ts-ignore
              self.snackbar = true;
              // @ts-ignore
              if (self.selectedCommunity.status === 0) {
                // @ts-ignore
                self.selectedCommunity.member_sum--;
              }
              // @ts-ignore
              self.selectedCommunity.status = null;
            }
          })
          .catch((e) => {
            console.log(e);
            // @ts-ignore
            this.snackbarColor = "error";
            this.snackbarText = "エラーが発生しました。";
            this.snackbar = true;
          });
      } else if (actionId === "admin") {
        // @ts-ignore
        this.openProfile(this.selectedCommunity.admin.user_id);
      } else if (actionId === "comunity-settings") {
        // @ts-ignore
        this.settingsDialog = true;
        // @ts-ignore
        this.bottomSheet = false;
      } else if (
        ["member", "request", "invite", "ban"].indexOf(actionId) !== -1
      ) {
        // @ts-ignore
        this.openUserList("community", ids.group_id, actionId);
      } else if (actionId === "copy-link") {
        if (!!ids.group_id) {
          if (!!ids.post_id) {
            copy(process.env.VUE_APP_DOMAIN + "/community/post/" + ids.post_id);
          } else {
            copy(process.env.VUE_APP_DOMAIN + "/community/" + ids.group_id);
          }
        } else if (!!ids.post_id) {
          copy(process.env.VUE_APP_DOMAIN + "/post/" + ids.post_id);
        } else {
          copy(process.env.VUE_APP_DOMAIN + "/user/" + ids.user_id);
        }
        // @ts-ignore
        self.snackbarColor = "success";
        self.snackbarText = "リンクをコピーしました。";
        self.snackbar = true;
        // @ts-ignore
        this.bottomSheet = false;
      }
    },
    openMessage(user_id) {
      const self = this;
      axios
        .post(
          "/dm/dm",
          {
            to_user_id: user_id,
          },
          {
            validateStatus: (status) => {
              return status < 500;
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status !== 201) {
            // @ts-ignore
            self.bottomSheet = false;
            // @ts-ignore
            self.snackbarColor = "error";
            self.snackbarText = "このユーザにはDMを送ることができません。";
            self.snackbar = true;
            throw new Error();
          }
          router.push({ name: "open-message", params: { user_id } });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async compressFile(file) {
      if (file.type === "image/gif") {
        if (file.size <= 1 * 1024 * 1024) {
          return file;
        }
        // @ts-ignore
        this.snackbarColor = "error";
        this.snackbarText = "1MBより大きいためPNGに変換されます。";
        this.snackbar = true;
      }
      return await ImageUtil.getCompressImageFileAsync(file);
    },
    async onFileChange(files) {
      // @ts-ignore
      const imageData = {
        base64: [],
        binary: [],
      };
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          // @ts-ignore
          imageData.binary.push(await this.compressFile(files[i]));
          const reader = new FileReader();
          reader.onload = (e) => {
            // @ts-ignore
            imageData.base64.push(e.target.result);
          };
          reader.readAsDataURL(files[i]);
        }
      } else {
        // @ts-ignore
        imageData.binary = await this.compressFile(files);
        const reader = new FileReader();
        reader.onload = (e) => {
          // @ts-ignore
          imageData.base64 = e.target.result;
        };
        reader.readAsDataURL(files);
      }
      // @ts-ignore
      this.selectedPostImages = imageData;
    },
  },
  beforeMount() {
    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          config.headers.Authorization = "JWT " + accessToken;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    imageAxios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          config.headers.Authorization = "JWT " + accessToken;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
});
