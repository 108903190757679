// @ts-ignore
import store from "@/store";
import axios from "@/axios";

type Token = {
  access: string | null;
  refresh: string | null;
};

const fetchToken = async (): Promise<Token> => {
  const oldToken = localStorage.getItem("piperia_token");
  if (!oldToken) {
    return {
      access: localStorage.getItem("access_token"),
      refresh: localStorage.getItem("refresh_token"),
    };
  }

  localStorage.removeItem("piperia_token");
  return axios
    .post("/accounts/token-swap", undefined, {
      headers: {
        Authorization: `JWT ${oldToken}`,
      },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error();
      }
      return {
        access: res.data.access as string,
        refresh: res.data.refresh as string,
      };
    })
    .catch((e) => {
      console.log(e);
      return {
        access: null,
        refresh: null,
      };
    });
};

const validateToken = async (token: Token): Promise<Token> => {
  const accessTokenExpired = JSON.parse(
    atob((token.access || "").split(".")[1] || "") || "{}"
  ).exp;
  const refreshTokenExpired = JSON.parse(
    atob((token.refresh || "").split(".")[1] || "") || "{}"
  ).exp;
  const now = Math.floor(Date.now() / 1000) - 60; // リクエストに時間がかかるため、余裕を持たせる
  const isAccessExpired = !accessTokenExpired || accessTokenExpired < now;
  const isRefreshExpired = !refreshTokenExpired || refreshTokenExpired < now;
  if (!isAccessExpired) {
    localStorage.setItem("access_token", token.access || "");
    localStorage.setItem("refresh_token", token.refresh || "");
    return token;
  }
  if (isRefreshExpired) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    return {
      access: null,
      refresh: null,
    };
  }

  return axios
    .post("/accounts/token-refresh", {
      refresh: token.refresh,
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error();
      }
      const token = {
        access: res.data.access as string,
        refresh: res.data.refresh as string,
      };
      localStorage.setItem("access_token", token.access);
      localStorage.setItem("refresh_token", token.refresh);
      return token;
    })
    .catch((e) => {
      console.log(e);
      return {
        access: null,
        refresh: null,
      };
    });
};

const setUserData = async (token: Token) => {
  if (!token.access) {
    return;
  }

  const userId = JSON.parse(atob((token.access || "").split(".")[1]))
    .user_id as string;
  await axios
    .get("/accounts/user", {
      params: {
        id: userId,
      },
      headers: {
        Authorization: `JWT ${token.access}`,
      },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error();
      }
      // @ts-ignore
      store.dispatch("doUpdateUser", {
        ...res.data,
        // @ts-ignore
        id: userId,
      });
    });
};

export const refreshAndSetUser = async () => {
  const token = await fetchToken();
  const validToken = await validateToken(token);
  await setUserData(validToken);
};

export const refreshAndSetToken = async () => {
  const token = await fetchToken();
  await validateToken(token);
};
