<template>
  <div class="search-results">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar
      app
      hide-on-scroll
      extended
      elevation="1"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-text-field
        v-model="searchWord"
        placeholder="検索"
        append-icon="fa-search"
        hide-details
        class="ml-2"
        @click:append="doSearch"
        @keyup.ctrl.enter.exact="doSearch"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs
          v-model="selectedTab"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :href="`#${tab.id}`"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item
        v-for="(tab, i) in tabs"
        :key="i"
        :value="tab.id"
      >
        <template v-if="selectedTab === tab.id">
          <VirtualScroll
            ref="virtualScroll"
            key-field="post_id"
            :items="results[selectedTab]"
            :min-item-height="92"
            :heights="heights[selectedTab]"
            :minScrollerHeight="minScrollerHeight"
            :buffer="minScrollerHeight*2"
            :offset="beforeScrollY[selectedTab]"
            :paddingBottom="$_domHeights.bottomNavigation"
            :onRefresh="onRefresh"
            :loadMore="loadMore"
            :noMoreItems="noMoreResults[selectedTab]"
          >
            <template v-slot:top>
              <v-card
                v-if="(results[selectedTab].length === 0 && noMoreResults[selectedTab]) || isMobile"
                max-width="750" flat class="mx-auto py-5"
              >
                <v-row
                  v-if="isMobile"
                  align="center" justify="center" class="wrapper ma-0"
                >
                  <v-col style="max-width: fit-content;">
                    <v-btn
                      text color="secondary" class="px-5"
                      @click="onRefresh"
                    >
                      更新
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  align="center" justify="center" class="wrapper ma-0"
                >
                  <v-col style="max-width: fit-content;">
                    検索結果が存在しません。
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <template v-slot="{ item }">
              <PostBox
                v-if="selectedTab === 'post'"
                :key="item.post_id"
                :post="item"
                :_sharePost="sharePost"
                :_showSheet="showSheet"
                :_setImages="setImages"
              ></PostBox>
              <v-card
                v-else
                class="user-box mx-auto"
                max-width="750"
                flat
                @click="openProfile(item.user_id)"
              >
                <v-row class="ma-0">
                  <v-spacer></v-spacer>
                  <v-col class="avatar-col pr-0 text-center col-2">
                    <v-avatar color="grey lighten-4">
                      <v-img v-if="item.icon_link" :src="item.icon_link"></v-img>
                      <v-icon v-else>fa-user</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="px-0 py-2 col-10">
                    <v-card-title class="py-0">
                      <div class="name-id-box">
                        <v-row class="subtitle-2 font-weight-bold ma-0">{{ item.name }}</v-row>
                        <v-row class="subtitle-2 grey--text ma-0">@{{ item.user_id }}</v-row>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="item.user_id !== userData.id"
                        small
                        :outlined="!item.is_follow && !item.is_block" :depressed="item.is_follow || item.is_block"
                        :color="item.is_block ? 'secondary' : 'primary'"
                        @click.stop="userAction((item.is_block ? 'block' : 'follow'), { user_id: item.user_id })"
                      >
                        <span class="follow-button">{{ item.is_block ? 'ブロック中' : item.is_follow ? 'フォロー中' : 'フォロー' }}</span>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="pb-0">{{ item.description }}</v-card-text>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card>
            </template>
            <template v-slot:bottom>
              <div style="padding-bottom: 88px;"></div>
            </template>
          </VirtualScroll>
        </template>
      </v-tab-item>
    </v-tabs-items>

    <BottomSheet
      :sheet="bottomSheet" :tiles="selectedSheetTiles" :ids="selectedIds"
      @action="userAction"
    ></BottomSheet>

    <v-dialog
      v-model="quoteDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="quoteDialog = false">
            <v-icon>{{ fullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :disabled="!newPost"
            :loading="postLoading"
            @click="doShare(selectedIds.post_id, newPost)"
          >
            引用
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5 pb-5">
          <v-textarea
            placeholder="タイムラインで引用しよう！"
            v-model="newPost"
            :rules="[rules.max150]"
            auto-grow
            counter
            @keyup.ctrl.enter.exact="doShare(selectedIds.post_id, newPost)"
          ></v-textarea>

          <v-file-input
            prepend-icon="fa-images"
            label="画像を選択"
            type="file"
            multiple
            :clearable="false"
            accept="image/png, image/jpeg, image/gif"
            @change="onFileChange($event, 'post')"
          ></v-file-input>

          <v-row>
            <v-col v-for="img in selectedPostImages.base64" class="pa-1">
              <v-card flat>
                <v-img
                  :src="img"
                  aspect-ratio="1"
                  class="ml-0 grey lighten-2"
                  max-width="100"
                  max-height="100"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>

          <OriginBox :key="selectedIds.post_id" :origin="selectedIds.post_id"></OriginBox>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-gallery :images="selectedImages"
      :index="imageIndex"
      :options="{ continuous: false }"
      @close="imageIndex = null" />
  </div>
</template>

<style lang="scss">
.search-results {

}
</style>

<script>
import axios from '../axios/index';
import router from '../router';

export default {
  name: 'search-results',
  data: () => ({
    rules: {
      max150: (v) => !v || v.length <= 150 || '150文字以内で入力してください',
    },
    isMobile: window.innerWidth >= 600 ? true : false,
    fullscreen: window.innerWidth >= 1264 ? false : true,
    searchWord: '',
    tabs: [
      {
        id: 'post',
        title: '投稿',
      }, {
        id: 'user',
        title: 'ユーザ',
      },
    ],
    selectedTab: 'post',
    noMoreResults: {
      post: false,
      user: false
    },
    results: {
      post: [],
      user: [],
    },
    minScrollerHeight: 0,
    heights: {
      post: {},
      user: {},
    },
    beforeScrollY: {
      post: 0,
      user: 0,
    }
  }),
  watch: {
    $route() {
      this.results = {
        post: [],
        user: [],
      };
      this.noMoreResults = {
        post: false,
        user: false,
      };
      this.heights = {
        post: {},
        user: {},
      };
      this.beforeScrollY = {
        post: 0,
        user: 0,
      };
    },
    selectedTab(newVal, oldVal) {
      this.heights[oldVal] = this.$refs.virtualScroll[0].itemHeights;
      this.beforeScrollY[oldVal] = window.scrollY;
    },
  },
  methods: {
    async onRefresh() {
      if (this.selectedTab === 'user') {
        return;
      }
      let results = this.results[this.selectedTab];
      let params = {
        word: this.searchWord
      };
      if (results.length) {
        params.top_id = results[0].post_id || results[0].user_id;
      }
      await axios
        .get('/search/search_' + this.selectedTab, {
          params,
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 404) {
              this.noMoreResults[this.selectedTab] = true;
            }
            throw new Error();
          }
          let data = res.data.posts || res.data.ids;
          results.unshift(...data);
        })
        .catch(async e => {
          console.log(e);
          await this.sleep(500);
        })
    },
    async loadMore() {
      this.searchWord = this.$route.query.word;
      let results = this.results[this.selectedTab];
      let params = {
        word: this.searchWord
      };
      if (results.length) {
        params.bottom_id = results.slice(-1)[0].post_id || results.slice(-1)[0].user_id;
      }
      await axios
        .get('/search/search_' + this.selectedTab, {
          params,
          validateStatus: status => {
            return status < 500;
          }
        })
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 404) {
              this.noMoreResults[this.selectedTab] = true;
            }
            throw new Error();
          }
          let data = res.data.posts || res.data.ids;
          results.push(...data);
          if (this.selectedTab === 'user') {
            this.noMoreResults.user = true;
          }
        })
        .catch(async e => {
          console.log(e);
          await this.sleep(500);
        })
    },
    doSearch() {
      if (this.searchWord !== this.$route.query.word) {
        router.push({ name: 'home-search', query: { word: this.searchWord } });
      }
    }
  },
  mounted() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
}
</script>
