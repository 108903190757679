<template>
  <div class="logout">
    <v-row v-if="hasToken" align="center" justify="center" class="wrapper ma-0">
      <v-col style="max-width: fit-content" class="text-center">
        <div class="title mb-3">ログアウト中</div>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.logout {
  .wrapper {
    height: 100vh;
  }
}
</style>

<script>
export default {
  name: "logout",
  data: () => ({}),
  computed: {
    hasToken() {
      return !!localStorage.getItem("access_token");
    },
  },
  mounted: async function () {
    if (this.hasToken) {
      await this.sleep(500);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    }
    this.$store.dispatch("doUpdateUser", {});
    let path = {
      name: "login",
    };
    if (!!this.$route.query.redirect) {
      path.query = {
        redirect: this.$route.query.redirect,
      };
    }
    this.$router.push(path);
  },
  destroyed: function () {
    location.reload();
  },
};
</script>
