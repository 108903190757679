import Vue from 'vue';

const state = {
  id: '',
  name: '',
  icon_link: '',
  top_link: '',
  is_key: false,
  is_official: false,
  is_prereg: false
};

const mutations = {
  setUserData: (state, payload) => {
    state.id = payload.userData.id;
    state.name = payload.userData.name;
    state.icon_link = payload.userData.icon_link;
    state.top_link = payload.userData.top_link;
    state.is_key = payload.userData.is_key;
    state.is_official = payload.userData.is_official;
    state.is_prereg = payload.userData.is_prereg;
  }
};

const actions = {
  doUpdateUser: ({ commit }, userData) => {
    commit('setUserData', { userData });
  }
};

const getters = {
  userData: state => {
    return {
      id: state.id,
      name: state.name,
      icon_link: state.icon_link,
      top_link: state.top_link,
      is_key: state.is_key,
      is_official: state.is_official,
      is_prereg: state.is_prereg
    };
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
