<template>
  <div class="settings">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app elevation="1">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{
          (step === 3 ? subSettings[settingsId - 1][subSettingsId - 1].title : null) ||
          (step === 2 ? settings[settingsId - 1].title : null) ||
          '設定'
         }}
       </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="step === 3"
        outlined
        class="primary--text"
        :loading="saveLoading"
        :disabled="!canSaveSettings"
        @click="subSubAction"
      >
        保存
      </v-btn>
    </v-app-bar>

    <v-stepper v-model="step" class="mx-auto pa-0 elevation-0">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-list class="pa-0">
            <template v-for="setting in settings">
              <v-list-item @click="action(setting.id)" :disabled="!!setting.disabled">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    <span>{{ setting.title }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-list>
        </v-stepper-content>

        <v-stepper-content step="2" class="pa-0">
          <v-list class="pa-0">
            <template v-for="setting in subSettings[settingsId - 1]">
              <v-list-item @click="settingsId !== 3 ? subAction(settingsId, setting.id) : null" :disabled="!!setting.disabled">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    <span v-if="settingsId !== 3" :class="setting.color+'--text'">{{ setting.title }}</span>
                    <template v-else>
                      <v-row class="ma-0">
                        <v-col class="pa-0 col-10">{{ setting.title }}</v-col>
                        <v-col class="pa-0 col-2">
                          <v-switch
                            v-model="privacySwitch[setting.id - 1]"
                            color="primary" hide-details
                            class="pa-0 ma-0"
                            @click.stop="subAction(settingsId, setting.id)"
                            :disabled="!!setting.disabled"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-list>
        </v-stepper-content>

        <v-stepper-content step="3" class="profile-settings-box pa-0">
          <v-card v-if="subSettingsId === 1" flat>
            <div>
              <clipper-upload
                ref="uploadTop"
                v-model="selectedSettingsImages.top.originalBlob"
                style="position: absolute;z-index: 1;width: 100%;height: 120px;"
                accept="image/png, image/jpeg, image/gif, image/heic"
              ></clipper-upload>
              <div style="width: 100%;">
                <v-img
                  :src="compressing.top ? '' : (selectedSettingsImages.top.blob || beforeUserData.top_link)"
                  :aspect-ratio="2.5/1"
                  class="mx-auto grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-icon v-if="!compressing.top" large style="position: absolute; width: 100%;height: 100%;">fa-image</v-icon>
                  </template>
                </v-img>
              </div>
            </div>
            <v-row class="avatar-row ma-0">
              <v-avatar size="80" color="grey lighten-4">
                <clipper-upload
                  ref="uploadIcon"
                  v-model="selectedSettingsImages.icon.originalBlob"
                  style="position: absolute;z-index: 1;width: 100px;height: 100px;"
                  accept="image/png, image/jpeg, image/gif, image/heic"
                ></clipper-upload>
                <v-progress-circular
                  v-if="compressing.icon"
                  indeterminate
                  color="grey darken-1"
                  size="28" width="2"
                  style="position: absolute;"
                ></v-progress-circular>
                <v-img
                  v-else-if="selectedSettingsImages.icon.blob || beforeUserData.icon_link"
                  :src="selectedSettingsImages.icon.blob || beforeUserData.icon_link"
                ></v-img>
                <v-icon v-else large style="position: absolute;">fa-image</v-icon>
              </v-avatar>
              <v-spacer></v-spacer>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0">
                <v-card-text class="pt-0">
                  <v-text-field
                    label="ユーザ名"
                    type="text"
                    :rules="[rules.max15]"
                    prepend-icon="fa-user"
                    v-model="username"
                    required
                  ></v-text-field>
                  <v-textarea
                    prepend-icon="fa-comment-dots"
                    v-model="description"
                    label="自己紹介"
                    :rules="[rules.max150]"
                    auto-grow
                    required
                    counter
                  ></v-textarea>
                  <v-select
                    prepend-icon="fa-school"
                    v-model="belong"
                    :items="belongs"
                    label="所属"
                  ></v-select>
                  <v-select
                    prepend-icon="fa-graduation-cap"
                    v-if="belong - 1"
                    v-model="grade"
                    :items="grades"
                    label="学年"
                  ></v-select>
                  <v-select
                    prepend-icon="fa-map-marker-alt"
                    v-model="prefecture"
                    :items="prefectures"
                    label="都道府県"
                  ></v-select>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-card v-if="subSettingsId === 2" flat>
            <v-row class="ma-0">
              <v-col class="pa-0">
                <v-card-text>
                  <v-text-field
                    class="mb-2"
                    prepend-icon="fa-lock"
                    v-model="password.new"
                    :append-icon="password.show ? 'fa-eye-slash' : 'fa-eye'"
                    :rules="[rules.min3]"
                    :type="password.show ? 'text' : 'password'"
                    single-line
                    label="パスワード"
                    counter
                    required
                    @click:append="password.show = !password.show"
                    :success="Boolean(password.new) && Boolean(rules.min3)"
                    :error="!password.new"
                  ></v-text-field>

                  <v-text-field
                    prepend-icon="fa-lock"
                    v-model="password.confirm"
                    :rules="[rules.min3]"
                    type="password"
                    single-line
                    label="パスワード確認"
                    counter
                    required
                    :success-messages="password.confSuccess"
                    :error-messages="password.confError"
                    :error="!password.confirm"
                  ></v-text-field>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog
      v-model="clipImageDialog"
      :fullscreen="$_isFullscreen"
      :max-width="$_isFullscreen ? '' : 500"
      hide-overlay :transition="$_isFullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="clipImageDialog = false">
            <v-icon>{{ $_isFullscreen ? 'fa-arrow-left' : 'fa-times' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :loading="clipLoading"
            @click="clipImage(clipMode)"
          >
            切り取り
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 pb-5">
          <clipper-fixed
            v-if="clipMode === 'icon'"
            :src="selectedSettingsImages.icon.originalBlob"
            round ref="imageClipper"
            :area="70"
          >
            <div slot="placeholder">画像がありません</div>
          </clipper-fixed>
          <clipper-fixed
            v-if="clipMode === 'top'"
            :src="selectedSettingsImages.top.originalBlob"
            :ratio='2.5/1' ref="imageClipper"
            :area="70"
          >
            <div slot="placeholder">画像がありません</div>
          </clipper-fixed>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss">
.settings {
  .v-list {
    .v-ripple__container, .v-input--selection-controls__ripple {
      display: none;
    }
    .v-input--switch__thumb {
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5) !important;
      -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5) !important;
    }
  }
  .profile-settings-box {
    .v-card {
      .avatar-row {
        min-height: 60px;

        .v-avatar {
          top: -30px;
          left: 12px;

          .v-image {
            border: solid 3px #fff;
          }
        }
      }
      .v-input:not(.v-textarea) {
        height: 70px;
      }
    }
  }
}
</style>

<script>
import imageAxios from '../axios/image';
import axios from '../axios/index';

export default {
  name: 'settings',
  data: () => ({
    rules: {
      min3: (v) => !v || v.length >= 3 || '3文字以上で入力してください',
      max15: (v) => !v || v.length <= 15 || '15文字以内で入力してください',
      max150: (v) => !v || v.length <= 150 || '150文字以内で入力してください',
    },
    clipImageDialog: false,
    clipMode: '',
    clipLoading: false,
    beforeUserData: {},
    saveLoading: false,
    step: 1,
    settingsId: 0,
    settings: [
      { id: 1, title: 'アカウント設定' }, { id: 2, title: '通知設定', disabled: true }, { id: 3, title: 'プライバシー設定' },
      { id: 4, title: 'ユーザ管理' }, { id: 5, title: '利用規約' }, { id: 6, title: 'プライバシーポリシー' }, { id: 7, title: 'お問い合わせ' }
    ],
    subSettingsId: 0,
    subSettings: [
      [
        { id: 1, title: 'プロフィール' }, { id: 2, title: 'パスワード変更', disabled: true },　{ id: 3, title: 'ログアウト', color: 'error' }
      ],
      [],
      [
        { id: 1, title: '非公開アカウントにする', disabled: true }, { id: 2, title: 'フォローしている人のみDM受取可能' }
      ],
      [
        { id: 1, title: 'ブロックしたユーザ' }, { id: 2, title: 'フォローリスト' }, { id: 3, title: 'フォロワーリスト' }
      ],
      [],[],[]
    ],
    username: '',
    description: '',
    belong: null,
    grade: null,
    prefecture: null,
    privacySwitch: [
      false, false
    ],
    password: {
      new: '',
      confirm: '',
      show: false,
      confError: '',
      confSuccess: ''
    }
  }),
  computed: {
    canSaveSettings: function() {
      if (this.username !== this.beforeUserData.name ||
          this.description !== this.beforeUserData.description ||
          this.belong !== this.beforeUserData.belong + 1 ||
          this.grade !== this.beforeUserData.grade + 1 ||
          this.prefecture !== this.beforeUserData.prefecture + 1 ||
          this.selectedSettingsImages.icon.binary ||
          this.selectedSettingsImages.top.binary ||
          this.password.confSuccess) {
        return true;
      }
      return false;
    }
  },
  watch: {
    $route: function() {
      this.settingsId = Number(this.$route.params.id);
      this.subSettingsId = Number(this.$route.params.sub_id);
      if (this.subSettingsId) {
        this.step = 3;
      } else if (this.settingsId) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    },
    step: function(val) {
      this.selectedSettingsImages = {
        icon: {
          originalBlob: null,
          blob: null,
          binary: null
        },
        top: {
          originalBlob: null,
          blob: null,
          binary: null
        }
      };
      this.saveLoading = false;
    },
    async 'selectedSettingsImages.icon.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.icon = true;
      let icon = this.selectedSettingsImages.icon;
      let file = await this.compressFile(this.$refs.uploadIcon.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'icon';
        this.clipImageDialog = true;
      }
      icon.blob = val;
      icon.binary = file;
      this.compressing.icon = false;
    },
    async 'selectedSettingsImages.top.originalBlob'(val) {
      if (!val) {
        return;
      }
      this.compressing.top = true;
      let top = this.selectedSettingsImages.top;
      let file = await this.compressFile(this.$refs.uploadTop.file);
      console.log(file);
      if (file.type !== 'image/gif') {
        this.clipMode = 'top';
        this.clipImageDialog = true;
      }
      top.blob = val;
      top.binary = file;
      this.compressing.top = false;
    },
    'password.confirm'(value) {
      if (value === this.password.new) {
        this.password.confError = '';
        this.password.confSuccess = '一致しています';
      } else {
        this.password.confSuccess = '';
        this.password.confError = 'パスワードが一致しません';
      }
    }
  },
  methods: {
    action: function(id) {
      if (id === 5) {
        let routeData = this.$router.resolve({ name: 'term-of-use' });
        window.open(routeData.href, '_blank');
        return;
      } else if (id === 6) {
        let routeData = this.$router.resolve({ name: 'privacy-policy' });
        window.open(routeData.href, '_blank');
        return;
      } else if (id === 7) {
        if (confirm('メールを開きますか？')) {
          window.open('mailto:piperia.sns@gmail.com');
        }
        return;
      }
      this.$router.push({
        name: 'settings',
        params: { id }
      });
    },
    subAction: function(parentId, id) {
      if (parentId === 1) {
        if (id === 1) {
          let self = this;
          axios
            .get('/accounts/user', {
              params: {
                id: this.userData.id
              }
            })
            .then(res => {
              if (res.status !== 200) {
                throw new Error();
              }
              self.beforeUserData = res.data;
              self.username = res.data.name;
              self.description = res.data.description;
              self.belong = res.data.belong + 1;
              self.grade = res.data.grade + 1;
              self.prefecture = res.data.prefecture + 1;
              console.log(self.belong, self.grade, res.data);
            })
            .then(() => {
              this.$router.push({
                name: 'settings',
                params: {
                  id: self.settingsId,
                  sub_id: id
                }
              });
            })
            .catch(e => {
              console.log(e);
              self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
            })
        } else if (id === 2) {
          this.$router.push({
            name: 'settings',
            params: {
              id: this.settingsId,
              sub_id: id
            }
          });
        } else if (id === 3) {
          this.$router.push({ name: 'logout' });
        }
      } else if (parentId === 4) {
        let type;
        switch (id) {
          case 1:
            type = 'block';
            break;
          case 2:
            type = 'follow';
            break;
          case 3:
            type = 'follower';
            break;
        }
        this.openUserList('user', this.userData.id , type);
      } else if (parentId === 3) {
        let self = this;
        if (id === 1) {
          axios
            .post('/undefined', {
            })
            .catch(e => {
              console.log(e);
              self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
            });
          return;
        }
        axios
          .post('/dm/change_status', {
            dm_status: !this.privacySwitch[1] ? 0 : 1
          })
          .catch(e => {
            console.log(e);
            self.snackbarColor = 'error'; self.snackbarText = 'エラーが発生しました。'; self.snackbar = true;
          });
      }
    },
    subSubAction: async function() {
      if (this.settingsId === 1) {
        if (this.subSettingsId === 1) {
          this.saveLoading = true;
          let params, binary;
          let result = 0;
          try {
            if (binary = this.selectedSettingsImages.icon.binary) {
              params = new FormData();
              params.append('file', binary);
              await imageAxios
                .post('/users/upload_icon', params)
                .then(res => {
                  console.log(res);
                  if (res.status !== 201) {
                    throw new Error();
                  }
                  this.beforeUserData.icon_link = res.data.link;
                  result++;
                })
            }
            if (binary = this.selectedSettingsImages.top.binary) {
              params = new FormData();
              params.append('file', binary);
              await imageAxios
                .post('/users/upload_top', params)
                .then(res => {
                  if (res.status !== 201) {
                    throw new Error();
                  }
                  this.beforeUserData.top_link = res.data.link;
                  result++;
                })
            }
            params = {
              name: this.username,
              description: this.description,
              belong: this.belong - 1 || 0,
              grade: (this.belong - 1) ? this.grade - 1 : 0,
              prefecture: this.prefecture - 1 || 0
            };
            await axios
              .patch('/users/summary', params)
              .then(res => {
                if (res.status !== 201) {
                  throw new Error();
                }
                this.beforeUserData.name = this.username;
                this.beforeUserData.description = this.description;
                this.beforeUserData.belong = this.belong - 1;
                this.beforeUserData.grade = this.grade - 1;
                this.beforeUserData.prefecture = this.prefecture - 1;
                this.snackbarColor = 'success'; this.snackbarText = '設定を保存しました。'; this.snackbar = true;
                result++;
              });
          } catch (e) {
            console.log(e);
            this.snackbarColor = 'error'; this.snackbarText = 'エラーが発生しました。'; this.snackbar = true;
          } finally {
            this.saveLoading = false;
            if (result) {
              this.selectedSettingsImages = {
                icon: {
                  originalBlob: null,
                  blob: null,
                  binary: null
                },
                top: {
                  originalBlob: null,
                  blob: null,
                  binary: null
                }
              };
            }
          }
        } else if (this.subSettingsId === 2) {
          console.log('pass');
        }
      }
    },
    clipImage(mode) {
      this.clipLoading = true;
      let self = this;
      let image = mode === 'icon' ? this.selectedSettingsImages.icon : this.selectedSettingsImages.top;
      let canvas = this.$refs.imageClipper.clip();
      canvas.toBlob(async blob => {
        image.blob = URL.createObjectURL(blob);
        let file = new File([blob], new Date() + '.png', { type: 'image/png' });
        image.binary = await self.compressFile(file);
        console.log(image.binary);
        this.clipLoading = false;
        self.clipImageDialog = false;
      });
    },
  },
  beforeMount: async function() {
    let self = this;
    await axios
      .get('/dm/change_status')
      .then(res => {
        self.privacySwitch[1] = res.data.dm_status ? 0 : 1;
      })
      .catch(e => {
        console.log(e);
      });
    this.settingsId = Number(this.$route.params.id);
    this.subSettingsId = Number(this.$route.params.sub_id);
    if (this.subSettingsId) {
      this.step = 3;
    } else if (this.settingsId) {
      this.step = 2;
    }
    await this.sleep(500);
    await axios
      .get('/accounts/user', {
        params: {
          id: this.$store.getters.userData.id
        }
      })
      .then(res => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error();
        }
        self.beforeUserData = res.data;
        self.username = res.data.name;
        self.description = res.data.description;
        self.belong = res.data.belong + 1;
        self.grade = res.data.grade + 1;
        self.prefecture = res.data.prefecture + 1;
      })
      .catch(e => {
        console.log(e);
      })
  }
}
</script>
