<template>
  <div class="communities-search">
    <v-snackbar :color="snackbarColor" v-model="snackbar" bottom :timeout="3000">
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar
      app
      hide-on-scroll
      extended
      elevation="1"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <v-text-field
        v-model="searchWord"
        placeholder="検索"
        append-icon="fa-search"
        hide-details
        class="ml-2"
        @click:append="doSearch()"
        @keyup.ctrl.enter.exact="doSearch()"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs
          v-model="selectedTab"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :href="`#${tab.id}`"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item
        v-for="(tab, i) in tabs"
        :key="i"
        :value="tab.id"
      >
        <template v-if="selectedTab === tab.id">
          <VirtualScroll
            ref="virtualScroll"
            key-field="group_id"
            :items="results[selectedTab]"
            :min-item-height="92"
            :heights="heights[selectedTab]"
            :minScrollerHeight="minScrollerHeight"
            :buffer="minScrollerHeight*2"
            :offset="beforeScrollY[selectedTab]"
            :paddingBottom="$_domHeights.bottomNavigation"
            :onRefresh="onRefresh"
            :loadMore="loadMore"
            :noMoreItems="noMoreResults[selectedTab]"
          >
            <template v-slot:top>
              <v-card
                v-if="(results[selectedTab].length === 0 && noMoreResults[selectedTab]) || !$_isMobile"
                max-width="750" flat class="mx-auto py-5"
              >
                <v-row
                  v-if="!$_isMobile"
                  align="center" justify="center" class="wrapper ma-0"
                >
                  <v-col style="max-width: fit-content;">
                    <v-btn
                      text color="secondary" class="px-5"
                      @click="onRefresh"
                    >
                      更新
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  align="center" justify="center" class="wrapper ma-0"
                >
                  <v-col style="max-width: fit-content;">
                    検索結果が存在しません。
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <template v-slot="{ item }">
              <v-card
                class="community-box mx-auto"
                max-width="750"
                flat
                @click="openCommunity(item.group_id)"
              >
                <v-row class="ma-0">
                  <v-spacer></v-spacer>
                  <v-col class="avatar-col pr-0 text-center col-2">
                    <v-avatar color="grey lighten-4">
                      <v-img v-if="item.icon_url" :src="item.icon_url" v-on:error="$_notImage(item, 'icon_url')"></v-img>
                      <v-icon v-else>fa-users</v-icon>
                      <v-icon v-if="item.is_admin" size="20" class="is-admin-icon">fa-cog</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="px-0 py-2 col-10">
                    <v-card-title class="py-0 pr-1">
                      <span class="subtitle-2 font-weight-bold ma-0">
                        <v-icon
                          v-if="item.kind >= 1"
                          x-small class="mr-1"
                          :color="item.kind === 1 ? '' : 'primary'"
                        >fa-lock</v-icon>{{ item.group }}
                      </span>
                    </v-card-title>
                    <v-card-text class="pb-0">{{ item.description }}</v-card-text>
                    <v-card-actions class="ml-4 pa-0">
                      <v-row class="ma-0">
                        <v-col v-if="item.tag.length" class="pa-0 col-12">
                          <v-chip
                            v-for="tag in item.tag"
                            small outlined class="mr-1 my-1"
                            @click.stop="doSearch(tag.tag)"
                          >
                            <span>{{ tag.tag }}</span>
                          </v-chip>
                        </v-col>
                        <v-col class="pa-0 col-12">
                          <v-chip small label outlined class="my-1">
                            <span class="caption px-1 grey--text">
                              <v-icon x-small class="mr-1">fa-user</v-icon>{{ changeNumFormat(item.member_sum) }}人
                            </span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card>
            </template>
            <template v-slot:bottom>
              <div style="padding-bottom: 88px;"></div>
            </template>
          </VirtualScroll>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss">
.communities-search {
  .v-tabs-items {
    .community-box {
      .v-ripple__container {
        display: none;
      }
      .is-admin-icon {
        position: absolute;
        margin-top: 20px;
        margin-left: 20px;
      }
      .v-chip {
        max-width: 50vw;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .community-box::before, .community-box::after {
      content: '';
      display: block;
      height: 1px;
      background: rgba(0, 0, 0, 0.12);
    }
  }
}
</style>

<script>
import axios from '../axios/index';
import router from '../router';

export default {
  name: 'communities-search',
  data: () => ({
    searchWord: '',
    tabs: [
      {
        id: 'community',
        title: 'コミュニティ',
      }, {
        id: 'tag',
        title: 'タグ',
      },
    ],
    selectedTab: 'community',
    noMoreResults: {
      community: false,
      tag: false
    },
    results: {
      community: [],
      tag: [],
    },
    heights: {
      community: {},
      tag: {},
    },
    beforeScrollY: {
      community: 0,
      tag: 0,
    },
    minScrollerHeight: 0
  }),
  watch: {
    $route() {
      this.results = {
        community: [],
        tag: [],
      };
      this.noMoreResults = {
        community: false,
        tag: false,
      };
      this.heights = {
        community: {},
        tag: {},
      };
      this.beforeScrollY = {
        community: 0,
        tag: 0,
      };
    },
    selectedTab(newVal, oldVal) {
      this.heights[oldVal] = this.$refs.virtualScroll[0].itemHeights;
      this.beforeScrollY[oldVal] = window.scrollY;
    }
  },
  methods: {
    async onRefresh() {
      this.searchWord = this.$route.query.word;
      let params = {};
      if (this.selectedTab === 'community') {
        params.search = this.searchWord;
      } else {
        params.tag = this.searchWord;
      }
      await axios
        .get('/group/', { params })
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          let results = this.results[this.selectedTab];
          results = [];
          results.push(...res.data);
        })
        .catch(async e => {
          console.log(e);
          await this.sleep(500);
        });
    },
    async loadMore() {
      this.searchWord = this.$route.query.word;
      let params = {};
      if (this.selectedTab === 'community') {
        params.search = this.searchWord;
      } else {
        params.tag = this.searchWord;
      }
      await axios
        .get('/group/', { params })
        .then(res => {
          if (res.status !== 200) {
            throw new Error();
          }
          this.results[this.selectedTab].push(...res.data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.noMoreResults[this.selectedTab] = true;
        });
    },
    doSearch(tag) {
      if (tag) {
        router.push({
          name: 'communities-search',
          query: { word: tag, tag: true }
        });
        return;
      }
      if (this.searchWord && this.searchWord !== this.$route.query.word) {
        router.push({ name: 'communities-search', query: { word: this.searchWord } });
      }
    },
  },
  beforeMount() {
    if (this.$route.query.tag) {
      this.selectedTab = 'tag';
    }
  },
  mounted() {
    this.minScrollerHeight = window.innerHeight - this.$_domHeights.appBar - this.$_domHeights.bottomNavigation;
  }
}
</script>
