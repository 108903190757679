<template>
  <v-card
    class="community-post-box mx-auto"
    max-width="750"
    flat
    @click="openPost(postData.post_id)"
  >
    <v-row
      v-if="notPost"
      align="center"
      justify="center"
      class="wrapper ma-0"
    >
      <v-col class="body-2" style="max-width: fit-content;">
        指定した投稿が存在しません。
      </v-col>
    </v-row>
    <template v-else-if="largeMode">
      <v-row class="ma-0">
        <v-spacer></v-spacer>
        <v-col class="avatar-col pr-0 text-center col-2">
          <v-avatar color="grey lighten-4" @click.stop="openProfile(postData.user_id)">
            <v-img v-if="postData.icon_link" :src="postData.icon_link" v-on:error="$_notImage(postData, 'icon_link')"></v-img>
            <v-icon v-else>fa-user</v-icon>
          </v-avatar>
        </v-col>
        <v-col class="pt-2 pb-1 pl-0 pr-1 col-10">
          <v-card-title class="py-0">
            <div class="post-name-box">
              <v-row no-gutters>
                <v-col class="post-name-box__overflow subtitle-2 font-weight-bold">{{ postData.name }}</v-col>
                <v-col class="post-name-box__badge">
                  <v-img
                    v-if="postData.is_official"
                    src="/img/official_logo_256.png"
                    width="1rem" height="1rem"
                    class="ml-1"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="post-name-box__overflow subtitle-2 grey--text">@{{ postData.user_id }}</v-col>
              </v-row>
            </div>
            <v-spacer></v-spacer>
            <span class="subtitle-2">{{ getFromNow(postData.time) }}</span>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="ma-0">
        <v-spacer></v-spacer>
        <v-col :class="(!isMobile ? 'col-12 px-1' : 'col-11 px-0') + ' pt-0 pb-1'">
          <v-card-text class="py-0">{{ postData.message }}
            <ImagesBox v-if="getImages(postData.image).length" :images="getImages(postData.image)" :show="_setImages"></ImagesBox>
          </v-card-text>
          <v-card-actions class="pa-0 subtitle-2">
            <v-btn class="pa-0 ml-2" text icon>
              <v-icon small>far fa-comment-dots</v-icon>
            </v-btn><span>{{ changeNumFormat(postData.comment) }}</span>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-btn
              class="pa-0 ml-2" @click.stop="likePost(postData.post_id)"
              text icon :color="postData.is_favorite ? 'primary': ''"
              :disabled="postData.user_id === userData.id"
            >
              <v-icon small>{{ postData.is_favorite ? 'fas' : 'far' }} fa-heart</v-icon>
            </v-btn><span v-on:click.stop="likePost(postData.post_id)">{{ changeNumFormat(postData.favorite) }}</span>
            <v-spacer></v-spacer>

            <v-btn
              v-if="userData.id"
              class="pa-0 ml-2"
              text icon
              @click.stop="showSheet(postData.post_id, postData.user_id)"
            >
              <v-icon small>fa-ellipsis-v</v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </template>
    <v-row v-else class="ma-0">
      <v-spacer></v-spacer>
      <v-col class="avatar-col pr-0 text-center col-2">
        <v-avatar color="grey lighten-4" @click.stop="openProfile(postData.user_id)">
          <v-img v-if="postData.icon_link" :src="postData.icon_link" v-on:error="$_notImage(postData, 'icon_link')"></v-img>
          <v-icon v-else>fa-user</v-icon>
        </v-avatar>
      </v-col>
      <v-col :class="(postData.message ? 'pt-2' : 'pt-0') + ' pb-1 pl-0 pr-1 col-10'">
        <v-card-title class="py-0">
          <div class="tl-name-box">
            <span class="subtitle-2 font-weight-bold">{{ postData.name }}</span>
            <span v-if="postData.is_official" class="tl-name-box__badge d-inline-block ml-1">
              <v-img
                src="/img/official_logo_256.png"
                width="16px" height="16px"
              ></v-img>
            </span>
            <span class="subtitle-2 grey--text ml-1">@{{ postData.user_id }}</span>
          </div>
          <v-spacer></v-spacer>
          <span class="subtitle-2">{{ getFromNow(postData.time) }}</span>
        </v-card-title>
        <v-card-text class="pb-0">{{ postData.message }}
          <ImagesBox v-if="getImages(postData.image).length" :images="getImages(postData.image)" :show="_setImages"></ImagesBox>
        </v-card-text>
        <v-card-actions class="pa-0 subtitle-2">
          <v-btn class="pa-0 ml-2" text icon>
            <v-icon small>far fa-comment-dots</v-icon>
          </v-btn><span>{{ changeNumFormat(postData.comment) }}</span>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            class="pa-0 ml-2" @click.stop="likePost(postData.post_id)"
            text icon :color="postData.is_favorite ? 'primary': ''"
            :disabled="postData.user_id === userData.id"
          >
            <v-icon small>{{ postData.is_favorite ? 'fas' : 'far' }} fa-heart</v-icon>
          </v-btn><span v-on:click.stop="likePost(postData.post_id)">{{ changeNumFormat(postData.favorite) }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="userData.id"
            class="pa-0 ml-2"
            text icon
            @click.stop="_showSheet(postData.post_id, postData.user_id)"
          >
            <v-icon small>fa-ellipsis-v</v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-card>
</template>

<style lang="scss">
.community-post-box {
  max-width: 750px;

  .avatar-col {
    max-width: 17%;
  }
  .post-name-box {
    line-height: 1rem;
    white-space: nowrap;
    width: 70%;

    .post-name-box__overflow {
      max-width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .post-name-box__badge {
      max-width: 1.5rem;
    }
  }
  .tl-name-box {
    line-height: 1rem;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .tl-name-box__badge {
      position: relative;
      width: 16px;

      div {
        position: absolute;
        bottom: -1.5px;
      }
    }
  }
  .v-card__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 20;
    overflow: hidden;
    /* text-align: justify; */
    white-space: pre-wrap;
  }
  .v-ripple__container {
    display: none;
  }
}
.community-post-box::before, .community-post-box::after {
  content: '';
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}
</style>

<script>
import axios from '../axios/index';

export default {
  name: 'community-post-box',
  props: {
    post: {
      type: Object,
      required: true
    },
    _showSheet: {
      type: Function,
      required: true
    },
    _setImages: {
      type: Function,
      required: true
    },
    largeMode: {
      type: Boolean,
      default: false
    },
    notPost: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    postData: {}
  }),
  watch: {
    post(val) {
      this.postData = val;
    }
  },
  beforeMount() {
    this.postData = this.post;
  },
  methods: {
    getImages(images) {
      return images.map(obj => obj.image_link);
    }
  }
}
</script>
