<template>
  <div class="chat-box">
    <div v-if="!!message.showDate" class="chat__date">
      {{ changeDateFormat(message.time) }}
    </div>
    <div :class="'chat__contents' + (!!message.myself ? ' myself' : '')">
      <div class="chat__message">
        <p v-if="message.message">{{ message.message }}</p>
        <div v-if="message.is_call" class="chat__call">
          <v-icon class="chat__call-icon">fa-phone</v-icon>
          <div class="chat__call-message">
            {{ !!message.is_finish_call ? "終了" : "通話中" }}
          </div>
        </div>
        <ImagesBox
          v-if="!!base64Images.length"
          :images="base64Images"
          :show="_setImages"
        ></ImagesBox>
      </div>
      <div class="chat__time">{{ getTime }}</div>
    </div>
  </div>
</template>

<style lang="scss">
.chat-box {
  max-width: 750px;
  padding: 0px 15px;

  .chat__date {
    text-align: center;
    font-size: 14px;
  }
  .chat__contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .chat__message {
      background: #f2f2f2;
      max-width: 70%;
      width: fit-content;
      margin: 5px 0;
      padding: 10px;
      line-height: 14px;
      border-radius: 15px 15px 15px 0;
      font-size: 14px;
      color: #000;
      text-align: left;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      border-bottom-left-radius: 0;

      p {
        margin: initial;
      }
      .chat__call {
        width: 4.6em;
        height: 4.6em;
        padding: 2px;
        font-size: 10px;
        text-align: center;

        .chat__call-icon {
          margin-bottom: 5px;
          color: unset;
        }
      }
      .images-box {
        margin-block: 1rem !important;
        max-width: 200px;
        width: 70vw;
      }
    }
    .chat__time {
      margin-bottom: 5px;
      font-size: 10px;
      color: grey;
    }
  }
  .myself {
    align-items: flex-end;

    .chat__message {
      background: #ff7177;
      color: #fff;
      border-radius: 15px 15px 0 15px;
    }
  }
}
</style>

<script>
import axios from "../axios/index";

export default {
  name: "chat-box",
  data: () => ({
    base64Images: [],
  }),
  props: {
    message: {
      type: Object,
      requierd: true,
    },
    _setImages: {
      type: Function,
      requierd: true,
    },
  },
  computed: {
    getTime() {
      const time = new Date(this.message.time);
      let format = "hh:mm";
      format = format.replace(/hh/, ("0" + time.getHours()).slice(-2));
      format = format.replace(/mm/, ("0" + time.getMinutes()).slice(-2));
      return format;
    },
  },
  async beforeMount() {
    if (!this.message.images || !this.message.images.length) {
      return;
    }

    if (this.message.images[0].startsWith("data:image")) {
      this.base64Images.push(...this.message.images);
      return;
    }

    const fetchImageFuncs = this.message.images.map((img) => {
      return axios
        .get(img, { responseType: "arraybuffer" })
        .then((res) => {
          return `data:image/png;base64,${Buffer.from(
            res.data,
            "binary"
          ).toString("base64")}`;
        })
        .catch(() => {
          return img;
        });
    });

    this.base64Images.push(...(await Promise.all(fetchImageFuncs)));
  },
};
</script>
