<template>
  <div class="home" v-scroll="onScroll">
    <v-snackbar
      :color="snackbarColor"
      v-model="snackbar"
      bottom
      :timeout="3000"
    >
      {{ snackbarText }}
      <v-btn icon x-small class="ml-0" @click="snackbar = false">
        <v-icon small>fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar
      app
      :hide-on-scroll="hideOnScroll"
      extended
      class="router-contents-bar"
      elevation="1"
    >
      <template v-if="!searchBox">
        <v-avatar size="36" color="grey lighten-4" @click="openProfileDrawer">
          <v-img v-if="userData.icon_link" :src="userData.icon_link"></v-img>
          <v-icon v-else small>fa-user</v-icon>
        </v-avatar>
        <v-spacer></v-spacer>
        <v-toolbar-title class="display-1" @click="scrollToTop"
          >Piperia</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </template>
      <v-btn
        icon
        :disabled="!Boolean(userData.id)"
        @click="searchBox = !searchBox"
      >
        <v-icon>{{ !searchBox ? "fa-search" : "fa-times" }}</v-icon>
      </v-btn>
      <v-text-field
        v-if="searchBox"
        v-model="searchWord"
        placeholder="検索"
        append-icon="fa-search"
        hide-details
        class="ml-2"
        @focus="focus = true"
        @blur="focus = false"
        @click:append="doSearch"
        @keyup.ctrl.enter.exact="doSearch"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs v-model="selectedTab" fixed-tabs>
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="tab in tabs" :href="`#${tab.id}`">
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="tab.id">
        <template v-if="tab.id === selectedTab">
          <v-card
            v-if="!tokenVerify"
            max-width="750"
            flat
            class="post-box mx-auto"
          >
            <v-row align="center" justify="center" class="wrapper ma-0">
              <v-col style="max-width: fit-content">
                <v-progress-circular
                  indeterminate
                  color="grey darken-1"
                  size="28"
                  width="2"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-else-if="selectedTab === 'follow' && !userData.id"
            max-width="750"
            flat
            class="mx-auto"
          >
            <v-card-text>
              ログインしないと全体の投稿の閲覧しかできないよ！
              アカウントを作成して全ての機能を使おう！
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-row class="text-center">
                <v-col class="col-12">
                  <v-btn
                    color="primary"
                    class="px-5"
                    :to="{ name: 'register' }"
                  >
                    新規登録
                  </v-btn>
                </v-col>
                <v-col class="col-12">
                  <v-btn
                    text
                    color="primary"
                    class="px-5"
                    :to="{ name: 'login' }"
                  >
                    ログイン
                  </v-btn>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <template v-else>
            <VirtualScroll
              ref="virtualScroll"
              key-field="post_id"
              :items="posts[selectedTab]"
              :min-item-height="92"
              :heights="heights[selectedTab]"
              :minScrollerHeight="minScrollerHeight"
              :buffer="minScrollerHeight * 2"
              :offset="beforeScrollY[selectedTab]"
              :paddingBottom="$_domHeights.bottomNavigation"
              :onRefresh="onRefresh"
              :loadMore="loadMore"
              :noMoreItems="noMorePosts[selectedTab]"
            >
              <template v-slot:top>
                <v-card
                  v-if="
                    (posts[selectedTab].length === 0 &&
                      noMorePosts[selectedTab]) ||
                    isMobile
                  "
                  max-width="750"
                  flat
                  class="mx-auto py-5"
                >
                  <v-row
                    v-if="isMobile"
                    align="center"
                    justify="center"
                    class="wrapper ma-0"
                  >
                    <v-col style="max-width: fit-content">
                      <v-btn
                        text
                        color="secondary"
                        class="px-5"
                        @click="onRefresh"
                      >
                        更新
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    v-else
                    align="center"
                    justify="center"
                    class="wrapper ma-0"
                  >
                    <v-col style="max-width: fit-content"> 下げて更新 </v-col>
                  </v-row>
                </v-card>
              </template>
              <template v-slot="{ item }">
                <PostBox
                  :key="item.post_id"
                  :post="item"
                  :_sharePost="sharePost"
                  :_showSheet="showSheet"
                  :_setImages="setImages"
                ></PostBox>
              </template>
              <template v-slot:bottom>
                <div style="padding-bottom: 88px"></div>
              </template>
            </VirtualScroll>
          </template>
        </template>
      </v-tab-item>
    </v-tabs-items>

    <v-btn
      v-if="userData.id"
      fab
      fixed
      right
      dark
      color="primary"
      class="bottom-fab-button"
      :style="{ bottom: `${$_bottomFabSpace}px` }"
      @click="dialog = true"
    >
      <v-icon>fas fa-plus</v-icon>
    </v-btn>

    <BottomSheet
      :sheet="bottomSheet"
      :tiles="selectedSheetTiles"
      :ids="selectedIds"
      :action="userAction"
    ></BottomSheet>

    <v-dialog
      v-model="dialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay
      :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="dialog = false">
            <v-icon>{{ fullscreen ? "fa-arrow-left" : "fa-times" }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :disabled="!canSendPost"
            :loading="postLoading"
            @click="sendPost"
          >
            投稿
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5 pb-5">
          <v-textarea
            placeholder="タイムラインに投稿しよう！"
            v-model="newPost"
            :rules="[rules.max150]"
            auto-grow
            counter
            @keyup.ctrl.enter.exact="sendPost"
          ></v-textarea>

          <v-file-input
            prepend-icon="fa-images"
            label="画像を選択"
            type="file"
            multiple
            :clearable="false"
            accept="image/png, image/jpeg, image/gif, image/heic"
            @change="onFileChange($event, 'post')"
          ></v-file-input>

          <v-row>
            <v-col v-for="img in selectedPostImages.base64" class="pa-1">
              <v-card flat>
                <v-img
                  :src="img"
                  aspect-ratio="1"
                  class="ml-0 grey lighten-2"
                  max-width="100"
                  max-height="100"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="quoteDialog"
      :fullscreen="fullscreen"
      :max-width="fullscreen ? '' : 500"
      hide-overlay
      :transition="fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    >
      <v-card>
        <v-card-title class="px-5">
          <v-btn icon @click="quoteDialog = false">
            <v-icon>{{ fullscreen ? "fa-arrow-left" : "fa-times" }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="primary--text"
            :disabled="!newPost"
            :loading="postLoading"
            @click="doShare(selectedIds.post_id, newPost)"
          >
            引用
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5 pb-5">
          <v-textarea
            placeholder="タイムラインで引用しよう！"
            v-model="newPost"
            :rules="[rules.max150]"
            auto-grow
            counter
            @keyup.ctrl.enter.exact="doShare(selectedIds.post_id, newPost)"
          ></v-textarea>

          <v-file-input
            prepend-icon="fa-images"
            label="画像を選択"
            type="file"
            multiple
            :clearable="false"
            accept="image/png, image/jpeg, image/gif"
            @change="onFileChange($event, 'post')"
          ></v-file-input>

          <v-row>
            <v-col v-for="img in selectedPostImages.base64" class="pa-1">
              <v-card flat>
                <v-img
                  :src="img"
                  aspect-ratio="1"
                  class="ml-0 grey lighten-2"
                  max-width="100"
                  max-height="100"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>

          <OriginBox
            :key="selectedIds.post_id"
            :origin="selectedIds.post_id"
          ></OriginBox>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-gallery
      :images="selectedImages"
      :index="imageIndex"
      :options="{ continuous: false }"
      @close="imageIndex = null"
    />
  </div>
</template>

<style lang="scss">
.home {
  .v-app-bar {
    .v-expansion-panels {
      position: absolute;
      top: 200%;
      left: 0;
      width: -webkit-fill-available;
      margin: 0 8px;

      .v-expansion-panel-header {
        min-height: 3rem !important;
      }
      .v-expansion-panel-content__wrap {
        padding: 0 0 16px 0;
      }
      .er-classics-header-content-text {
        white-space: nowrap;
      }
      .er-classics-footer-content-text {
        white-space: nowrap;
      }
    }
  }
}
</style>

<script>
import imageAxios from "../axios/image";
import axios from "../axios/index";
import router from "../router";

export default {
  name: "home",
  data: () => ({
    focus: false,
    rules: {
      max150: (v) => !v || v.length <= 150 || "150文字以内で入力してください",
    },
    isMobile: window.innerWidth >= 600 ? true : false,
    fullscreen: window.innerWidth >= 1264 ? false : true,
    tokenVerify: false,
    minScrollerHeight: 0,
    searchBox: false,
    searchWord: "",
    tabs: [
      {
        id: "follow",
        title: "フォロー",
      },
      {
        id: "all",
        title: "すべて",
      },
    ],
    selectedTab: "all",
    posts: {
      follow: [],
      all: [],
    },
    heights: {
      follow: {},
      all: {},
    },
    beforeScrollY: {
      follow: 0,
      all: 0,
    },
    hideOnScroll: false,
    noMorePosts: {
      follow: false,
      all: false,
    },
    dialog: false,
  }),
  computed: {
    canSendPost: function () {
      if (this.newPost && this.newPost.length <= 150) {
        return true;
      }
      return false;
    },
  },
  watch: {
    selectedTab(newVal, oldVal) {
      this.searchBox = false;
      this.searchWord = "";
      this.heights[oldVal] = this.$refs.virtualScroll[0].itemHeights;
      this.beforeScrollY[oldVal] = window.scrollY;
    },
    dialog() {
      this.newPost = "";
      this.postLoading = false;
      document.querySelector(".v-file-input input").value = "";
      document.querySelector(".v-file-input__text").textContent = "";
      this.selectedPostImages = {
        base64: [],
        binary: [],
      };
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async onRefresh() {
      let posts = this.posts[this.selectedTab];
      let mode = this.selectedTab === "all" ? "tl" : "follow_tl";
      let params = {};
      if (posts.length) {
        params.top_id = posts[0].post_id;
      }
      await axios
        .get("/post/" + mode, { params })
        .then((res) => {
          posts.unshift(...res.data.posts);
        })
        .catch(async (e) => {
          console.log(e);
          await this.sleep(500);
        });
    },
    async loadMore() {
      let beforeCount = this.posts[this.selectedTab].length;
      await this.minLoadMore();
      let addCount = this.posts[this.selectedTab].length - beforeCount;
      for (
        let i = 0;
        i < 4 && addCount <= 100 && !this.noMorePosts[this.selectedTab];
        i++
      ) {
        await this.sleep(500);
        await this.minLoadMore();
        addCount = this.posts[this.selectedTab].length - beforeCount;
      }
    },
    async minLoadMore() {
      let posts = this.posts[this.selectedTab];
      let mode = this.selectedTab === "all" ? "tl" : "follow_tl";
      let params = {};
      if (posts.length) {
        params.bottom_id = posts.slice(-1)[0].post_id;
      }
      await axios
        .get("/post/" + mode, {
          params,
          validateStatus: (status) => {
            return status < 500;
          },
        })
        .then((res) => {
          if (res.status === 404) {
            this.noMorePosts[this.selectedTab] = true;
            throw new Error();
          }
          posts.push(...res.data.posts);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onScroll(e) {
      let scrollTop = e.target.scrollingElement.scrollTop;
      if (scrollTop <= 500) {
        this.hideOnScroll = false;
      } else {
        this.hideOnScroll = true;
      }
    },
    doSearch() {
      this.$store.dispatch("doUpdateHomeState", { tab: this.selectedTab });
      router.push({ name: "home-search", query: { word: this.searchWord } });
    },
    sendPost() {
      this.postLoading = true;
      let self = this;
      if (this.selectedPostImages.binary.length) {
        let params = new FormData();
        for (let i = 0; i < self.selectedPostImages.binary.length; i++) {
          params.append("file" + (i + 1), self.selectedPostImages.binary[i]);
        }
        console.log(params);
        imageAxios
          .post("/post/upload_image", params)
          .then((res) => {
            let ids = res.data.ids;
            if (res.status === 201) {
              axios
                .post("/post/post", {
                  message: self.newPost.replace(/\n{2,}/g, "\n\n"),
                  parent: "",
                  images: [...ids],
                })
                .then((res) => {
                  if (res.status === 201) {
                    self.dialog = false;
                  } else {
                    throw new Error();
                  }
                });
            } else {
              throw new Error();
            }
          })
          .catch((e) => {
            console.log(e);
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          })
          .finally(() => {
            self.postLoading = false;
          });
      } else {
        axios
          .post("/post/post", {
            message: self.newPost.replace(/\n{2,}/g, "\n\n"),
            parent: "",
          })
          .then((res) => {
            if (res.status === 201) {
              self.dialog = false;
            } else {
              throw new Error();
            }
          })
          .catch((e) => {
            console.log(e);
            self.snackbarColor = "error";
            self.snackbarText = "エラーが発生しました。";
            self.snackbar = true;
          })
          .finally(() => {
            self.postLoading = false;
          });
      }
    },
  },
  beforeMount: function () {
    console.log(this.userData);
    if (!localStorage.getItem("access_token")) {
      this.tokenVerify = true;
      return;
    }
    let self = this;
    axios
      .post("/accounts/token-verify", {
        // @ts-ignore
        token: localStorage.getItem("access_token"),
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }
      })
      .then(() => {
        let userData = JSON.parse(
          decodeURIComponent(
            escape(atob(localStorage.getItem("access_token").split(".")[1]))
          )
        );
        axios
          .get("/accounts/user", {
            params: {
              id: userData.user_id,
            },
          })
          .then(function (res) {
            if (res.status === 200) {
              // @ts-ignore
              self.$store.dispatch("doUpdateUser", {
                ...res.data,
                // @ts-ignore
                id: userData.user_id,
              });
            } else {
              throw new Error();
            }
          });
      })
      .catch((e) => {
        console.log(e);
        // @ts-ignore
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        // @ts-ignore
        self.$store.dispatch("doUpdateUser", {});
      })
      .finally(() => {
        self.tokenVerify = true;
      });
    this.selectedTab = this.$store.getters.homeState.tab || "all";
  },
  mounted: function () {
    this.minScrollerHeight =
      window.innerHeight -
      this.$_domHeights.appBar -
      this.$_domHeights.bottomNavigation;
  },
};
</script>
